

import React, { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { TbPhoneCalling } from "react-icons/tb";
import { MdEmail } from "react-icons/md";
import emailjs from "emailjs-com";
import Chat from "../file/Chat";
import { CONTACT } from "../strings/CONTACT";

const Contact = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();

    // Get form data (you may need to add state variables for these inputs)
    const name = e.target.elements.name.value;
    const email = e.target.elements.email.value;
    const phone = e.target.elements.phone.value;
    const message = e.target.elements.message.value;
    // const clas = e.target.elements.clas.value;
    // const message = e.target.elements.message.value;

    // Use emailjs to send the email
    emailjs
      .send(
        "service_7y7ciac",
        "template_49acjtl",
        {
          from_name: name,
          message: `Name: ${name}, Email: ${email}, Phone: ${phone}, Message: ${message}`,
          reply_to: email,
        },
        "1UqiM2auABkAWZ_fw"
      )
      .then(() => {
        setSuccessMessage("Email sent successfully!");
        // Clear success message after 3 seconds
        setTimeout(() => setSuccessMessage(""), 3000);
      })
      .catch((error) => {
        setErrorMessage("Email failed to send. Error: " + error.message);
        // Clear error message after 3 seconds
        setTimeout(() => setErrorMessage(""), 3000);
      });
  };

  return (
    <div>
      <Header />

      <div className="contact-inner">
        <div className="w-auto xl:mx-40 lg:mx-20 md:mx-10 mx-6">
          <div className="flex flex-col py-10">
            <div className="col-sm-12">
              <div className="hd-txt">
                <h2>Available 24/7 For Your Business Needs</h2>
                <p>
                  Our professional experts are always available 24/7 to answer
                  all your queries. You can use the form below to send us your
                  valuable <br />
                  feedback or call us on our Toll Free Number at any time of the
                  day - even on Sundays!
                </p>
              </div>
            </div>
            <div className="col-sm-12 p0 md:flex justify-center items-center">
              <div className="col-sm-4">
                <div className="contact-box flex flex-col justify-center items-center">
                  <div className="img-cntct">
                    <img
                      src={require("../assets/images/images-contact-icon-1.png")}
                    />
                  </div>
                  <div className="cntct-txt">
                    <h3>
                      Office <br />
                      Address
                    </h3>
                    <p>{CONTACT.ADDRESS}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="contact-box flex flex-col justify-center items-center">
                  <div className="img-cntct">
                    <img
                      src={require("../assets/images/images-contact-icon-2.png")}
                    />
                  </div>
                  <div className="cntct-txt">
                    <h3>
                      Make a <br />
                      Call New
                    </h3>
                    <p>
                      <a href={`tel:${CONTACT.PH_NO}`}>{CONTACT.PH_NO}</a>
                    </p>
                    <a href={`tel:${CONTACT.PH_NO}`}>
                      <p>(We Are 24x7 Available To Help You When Needed.)</p>
                    </a>
                  </div>
                  <a href={`tel:${CONTACT.PH_NO}`}></a>
                </div>
                <a href={`tel:${CONTACT.PH_NO}`}></a>
              </div>
              <a href={`tel:${CONTACT.PH_NO}`}></a>
              <div className="col-sm-4">
                <a href={`tel:${CONTACT.PH_NO}`}></a>
                <div className="contact-box flex flex-col justify-center items-center">
                  <a href={`tel:${CONTACT.PH_NO}`}>
                    <div className="img-cntct">
                      <img
                        src={require("../assets/images/images-contact-icon-3.png")}
                      />
                    </div>
                  </a>
                  <div className="cntct-txt">
                    <a href={`tel:${CONTACT.PH_NO}`}>
                      <h3>
                        Email
                        <br /> Address
                      </h3>
                    </a>
                    <p>
                      <a href={`tel:${CONTACT.PH_NO}`}></a>
                      <a
                        href="cdn-cgi/l/email-protection.html#afcddadcc6c1cadcdcefd8cacdc2cedcdbcaddcbcadcc6c8c1dc81ccc0"
                        className="mail-hd"
                      >
                        {" "}
                        <i
                          className="fa fa-envelope"
                          aria-hidden="true"
                        ></i>{" "}
                        Email us:{" "}
                        <span
                          className={CONTACT.EMAIL}
                          data-cfemail="d7b5a2a4beb9b2a4a497a0b2b5bab6a4a3b2a5b3b2a4beb0b9a4f9b4b8"
                        >
                          [{CONTACT.EMAIL}]
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cntct-form-sec bg-primary">
        <div className="container mx-auto w-auto xl:px-40 lg-px-20 md:px-10 px-6">
          <div className="md:flex">
            <div className="col-md-6 md:w-[50%]  w-auto">
              <div
                className="cntct-div md:p-12 px-2 py-6 mb-6 bounceInUp"
                data-wow-delay="300ms"
              >
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col">
                    <div className="form-text">
                      <h3>Ready to get started?</h3>
                    </div>
                    <div className="col-md-12 col-xs-12 margin-bottom-20 field-mergedright">
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control w-full shadow-inner border"
                        placeholder=" Name"
                        name="name"
                        required
                      />
                    </div>
                    <input type="hidden" name="" />
                    <div className="col-md-12 col-xs-12 margin-bottom-20 field-mergedleft">
                      <input
                        type="email"
                        autoComplete="off"
                        className="form-control san-bor w-full shadow-inner border"
                        placeholder="Email"
                        name="email"
                        required
                      />
                    </div>
                    <div className="col-md-12 col-xs-12 margin-bottom-20 field-mergedright">
                      <input
                        type="text"
                        className="form-control san-bor w-full shadow-inner border"
                        placeholder="Phone "
                        name="phone"
                        required
                      />
                    </div>
                    <div className="col-md-12 col-xs-12 margin-bottom-20">
                      <textarea
                        className="form-control san-bor w-full shadow-inner border"
                        name="message"
                        placeholder="Enter message here"
                        required
                      />
                    </div>
                    {successMessage && (
                      <div className="success-message py-3 text-green-600">
                        {successMessage}
                      </div>
                    )}
                    {errorMessage && (
                      <div className="error-message py-3 text-red-600">
                        {errorMessage}
                      </div>
                    )}
                    <div className="col-sm-12 col-xs-12 form-btn-div-st">
                      <div className="button pt-3">
                        <button
                          className="ftr-btn-st w-full shadow-inner border text-white bg-logo p-3 rounded-md"
                          type="submit"
                          id="quoteSubmit"
                          name="quote"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                  <input
                    type="hidden"
                    id="lead_area"
                    name="lead_area"
                    value="for $247"
                  />
                  <input
                    type="hidden"
                    id="lead_org_price"
                    name="lead_org_price"
                    value=""
                  />
                  <input type="hidden" name="send" value="1" />

                  <input type="hidden" name="lb_source" value="" />
                  <input type="hidden" name="lb_source_cat" value="" />
                  <input type="hidden" name="lb_source_nam" value="" />
                  <input type="hidden" name="lb_source_ema" value="" />
                  <input type="hidden" name="lb_source_con" value="" />
                  <input type="hidden" name="lb_source_pho" value="" />
                  <input type="hidden" name="lb_source_off" value="" />

                  <input type="hidden" name="fullpageurl" value="" />
                  <input
                    type="hidden"
                    name="pageurl"
                    value="www.candigitals.com/contact-us.php"
                  />

                  <input type="hidden" name="ip2loc_ip" value="172.69.54.16" />
                  <input
                    type="hidden"
                    name="ip2loc_isp"
                    value="Cloudflare, Inc."
                  />
                  <input
                    type="hidden"
                    name="ip2loc_org"
                    value="Cloudflare WARP"
                  />
                  <input
                    type="hidden"
                    name="ip2loc_country"
                    value="French Polynesia"
                  />
                  <input
                    type="hidden"
                    name="ip2loc_region"
                    value="Îles du Vent"
                  />
                  <input type="hidden" name="ip2loc_city" value="Tahiti" />
                  <input
                    type="hidden"
                    name="service_id"
                    value="5640"
                    className="service_id"
                  />
                  {/* <div className="customalert" style={{background: "rgb(255, 255, 255)", color: "red", text-align: "center", font: "16px", width: "100%", margin: "10px 0px 0px", padding: "10px", float: "left", display: "none"}}></div> */}
                </form>
              </div>
            </div>
            <div
              className="w-auto md:w-[50%] col-sm-6 bounceInUp"
              data-wow-delay="300ms"
            >
              <div className="contact-box">
                <div className="contact-text">
                  <h2>Looking to take your business forward?</h2>
                  <p>
                    Contact us now and avail of our services to give your brand
                    the lift that it deserves.{" "}
                  </p>
                </div>
                <div className="contact-btn">
                  <a className=" foot-btn popup-btn">Get a Free Quote</a>
                </div>
              </div>
              <div className="contact-dress-box">
                <div className="contact-img-box text-white">
                  <FaEnvelopeOpenText size={20} />
                </div>
                <div className="adrss-text">
                  <h4>Email Address</h4>
                  <a href={CONTACT.EMAIL} className="mail-hd flex">
                    <MdEmail size={20} /> Email us:{" "}
                    <span
                      className={CONTACT.EMAIL}
                      data-cfemail={CONTACT.EMAIL}
                    >
                      [{CONTACT.EMAIL}]
                    </span>
                  </a>
                </div>
              </div>
              <div className="contact-dress-box">
                <div className="contact-img-box text-white">
                  <TbPhoneCalling size={20} />
                </div>
                <div className="adrss-text">
                  <h4>Call Now</h4>
                  <a href={`tel:${CONTACT.PH_NO}`}>{CONTACT.PH_NO}</a>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Chat />

      <Footer />
    </div>
  );
};

export default Contact;
