import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { IoIosStar } from "react-icons/io";
import { testimonial } from "../strings/Array";
import Chat from "../file/Chat";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Header />

      <div className="inner-banner about-banner">
        <div className="banner-line flex justify-center items-center">
          <div className="container position-rel-container">
            <div className="exta-div"></div>
          </div>
        </div>
        <div className="animate-box banner-bottom-left-1 onload-animate fadeIn animation-delay-5ms"></div>
        <div className="animate-box banner-bottom-left-2 onload-animate fadeIn animation-delay-10ms"></div>
        <div className="animate-box banner-bottom-left-3 onload-animate fadeIn animation-delay-15ms"></div>
        <div className="animate-box banner-bottom-left-4 onload-animate fadeIn animation-delay-20ms"></div>
        <div className="animate-box banner-bottom-left-5 onload-animate fadeIn animation-delay-25ms"></div>
        <div className="animate-box banner-bottom-right-1 onload-animate fadeIn animation-delay-5ms"></div>
        <div className="animate-box banner-bottom-right-2 onload-animate fadeIn animation-delay-10ms"></div>
        <div className="animate-box banner-bottom-right-3 onload-animate fadeIn animation-delay-15ms"></div>
        <div className="animate-box banner-bottom-right-4 onload-animate fadeIn animation-delay-20ms"></div>
        <div className="black-overlay"></div>
        <div className="container absolute-center">
          <div className="row">
            <div className="col-lg-7">
              <div className="text-white xl:mx-40 lg:mx-20 md:mx-10 px-6 flex flex-col md:justify-start justify-center md:items-start items-center">
                <h1 className="xl:text-6xl md:text-3xl text-xl font-bold">
                  We Fuel Dreams. <br />
                  You Witness Success!
                </h1>
                <h6 className="xl:text-2xl md:text-xl text-lg pt-3 md:text-start text-center">
                  At METANADO, we aim to bring our clients' visions to life by
                  creating unique digital experiences that help them stand out
                  in the market!
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="white-sec pb-0" id="what-we-do-linker">
        {/* <div className="animate-box section-top-right-3 wow fadeIn animation-delay-5ms" style={visibility: visible; animation-name: fadeIn;}></div> */}
        {/* <div className="animate-box section-top-right-4 wow fadeIn animation-delay-10ms" style={visibility: visible; animation-name: fadeIn;}></div> */}
        <div className="container mx-auto w-auto xl:px-40 lg:px-20 md:px-10 px-6 pb-10">
          <div className="md:flex gap-3">
            <div className="col-lg-6">
              <div className="section-heading left-heading flex flex-col justify-center md:justify-start md:items-start items-center md:text-start text-center">
                <h5>WHAT WE DO</h5>
                <h2>Pioneers in Design and Development</h2>
                <h6>
                  We Enhance Your Vision with <br />
                  Creative Brilliance!
                </h6>
                <p className="simple-p md:text-start text-center">
                  At METANADO, we strive to go above and beyond our clients'
                  expectations. Our team of innovative experts is prepared to
                  breathe life into your ideas, be it through website design and
                  development, logo design, branding, or marketing services.
                </p>
                <div className="link-area">
                  {" "}
                  <a
                    className="hover-effect"
                    onClick={() => navigate("/contact-us")}
                  >
                    Contact Us<span className="flash-glow-effect"></span>
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <div className="about-second-sec-img second-sec-hover ipadmargin30">
                {" "}
                <img
                  src={require("../assets/images/about-second-sec.jpg")}
                  alt=""
                  className="about-second-img"
                />
                <div className="work-video-popup">
                  {" "}
                  <img
                    src={require("../assets/images/video-play-btn-black.png")}
                    alt=""
                  />{" "}
                  <span className="before"></span>{" "}
                  <span className="after"></span>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="white-sec" id="mission-vision-linker">
        <div className="container mx-auto w-auto xl:px-40 lg:px-20 md:px-10 px-6">
          <div className="flex flex-col">
            <div className="col-12">
              <div className="section-heading pb-3">
                <h5>SERVICES WE OFFER</h5>
                <h2>
                  Fulfilling our clients' digital needs <br />
                  for years on end!
                </h2>
                <h6 className="text-xl text-center flex justify-center items-center mx-auto">
                  We provide top-notch digital solutions to help our clients
                  succeed in the online world at METANADO.
                  <br />
                  Our goal is to empower them with innovative designs that set
                  them apart from the competition.
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="dm-service bg-gray-100">
          {/* <div className="animate-box section-back-top-left-3 wow fadeIn animation-delay-5ms hide-on-mob" style={{visibility: 'visible' , animation-name: 'fadeIn'}}></div> */}
          {/* <div className="animate-box section-back-top-left-4 wow fadeIn animation-delay-10ms hide-on-mob" style="visibility: visible; animation-name: fadeIn;"></div> */}
          {/* <div className="animate-box section-back-top-left-5 wow fadeIn animation-delay-15ms hide-on-mob" style="visibility: visible; animation-name: fadeIn;"></div> */}
          <div className="about-service-1 displaynoneonipad"> </div>
          <div className="container w-auto mx-auto pt-10 xl:px-40 lg:px-20 md:px-10 px-6">
            <div className="md:flex gap-3">
              <div className="lg:w-[50%] md:w-[70%] w-auto">
                <div className=" left-heading flex flex-col justify-center md:justify-start md:items-start items-center md:text-start text-center">
                  <h5>Our Mission &amp; Vision</h5>
                  <h2 className="md:text-4xl text-xl font-bold text-black">
                    Our Mission &amp; Vision
                  </h2>
                  <p className="simple-p text-lg pb-6">
                    At METANADO, we are dedicated to creating exceptional
                    designs and experiences as a leading digital agency. <br />
                    <br /> Our goal is to offer unparalleled value through our
                    innovative and creative services, all at competitive prices.
                    With our expertise in branding, marketing, and design, we
                    help businesses establish a powerful online presence that
                    truly connects with their target audience. <br />
                    <br /> We are firm believers that every brand, no matter its
                    size, should have the chance to stand out in the digital
                    realm. METANADO is here to assist those who are eager to
                    succeed and leave a lasting impression in an ever-changing
                    environment.{" "}
                  </p>
                  <div className="link-area ">
                    {" "}
                    <a href="javascript:void(0)" className="simple-a">
                      <i className="far fa-comments"></i>Live Chat
                    </a>{" "}
                    <a
                      onClick={() => navigate("/pricing")}
                      className="hover-effect live-cht"
                    >
                      Start Your Project
                      <span className="flash-glow-effect"></span>
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="w-auto md:pb-0 pb-3">
                <img
                  src={require("../assets/images/about-1.jpg")}
                  alt="About-Image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="white-sec pb-0" id="why-choose-us-linker">
        {/* <div className="animate-box section-top-right-3 wow fadeIn animation-delay-5ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
        {/* <div className="animate-box section-top-right-4 wow fadeIn animation-delay-10ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
        <div className="container mx-auto w-auto pb-10 xl:px-40 lg:px-20 md:px-10 px-6">
          <div className="row">
            <div className="col-12">
              <div className="section-heading pb-3">
                <h5>Service We Provide</h5>
                <h2>Why Choose Us?</h2>
                <h6 className="text-xl md:w-[50%] text-center flex justify-center items-center mx-auto">
                  METANADO shines in a sea of digital competition. <br />
                  We excel at not just claiming your brand's spot online, but
                  owning it , <br />
                  Trust our skills to leave your competitors behind!
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="container w-auto mx-auto container-fluid complete-about-service">
          <div className="grid md:grid-cols-3 grid-cols-1">
            <div className="col-md-4 ">
              <div className="single-about-service flex flex-col justify-center items-center text-center">
                {" "}
                <img src={require("../assets/images/settings.png")} alt="" />
                <h4>Years Of Experience</h4>
                <p>
                  At METANADO, our team of creative geniuses has been leading
                  the charge in transforming brands into digital powerhouses for
                  many years. Our commitment to innovation fuels success and
                  fosters growth.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="single-about-service flex flex-col justify-center items-center text-center">
                {" "}
                <img src={require("../assets/images/support.png")} alt="" />
                <h4>Full-Service Studio</h4>
                <p>
                  Look no further than METANADO for all your digital needs. From
                  designing websites and creating logos to developing brands and
                  marketing strategies, we offer a comprehensive range of
                  services to enhance your brand.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="single-about-service flex flex-col justify-center items-center text-center">
                {" "}
                <img src={require("../assets/images/briefcase.png")} />
                <h4>Quality Work</h4>
                <p>
                  We take great pride in delivering top-notch, unparalleled
                  services that distinguish us within the digital landscape. Our
                  dedication to perfection ensures that our clients receive only
                  the finest quality.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="single-about-service flex flex-col justify-center items-center text-center">
                {" "}
                <img src={require("../assets/images/low-price.png")} alt="" />
                <h4>Affordable</h4>
                <p>
                  Our cost-effective packages make digital achievements
                  achievable for everyone. Our goal is to assist brands in
                  reaching their objectives without exceeding their financial
                  limits, delivering exceptional value every step of the way.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="single-about-service flex flex-col justify-center items-center text-center">
                {" "}
                <img
                  src={require("../assets/images/fast-delivery.png")}
                  alt=""
                />
                <h4>Quick Turnaround Time</h4>
                <p>
                  Our proficient team guarantees rapid project turnaround times.
                  By merging efficiency with creativity, we deliver exceptional
                  results promptly.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="single-about-service flex flex-col justify-center items-center text-center">
                {" "}
                <img src={require("../assets/images/technology.png")} alt="" />
                <h4>Latest Technology</h4>
                <p>
                  METANADO is fully equipped with advanced technology and tools.
                  Our advanced software and robust machinery allow us to bring
                  your digital dreams to reality with accuracy and excellence.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#F1F1F1] mt-10">
        <div className="container mx-auto flex flex-col justify-center items-center py-10 w-[100%] px-6 overflow-hidden">
          <div className="flex justify-center items-center">
            <img
              src={require("../assets/images/arrow_1.png")}
              alt="Arrow"
              className="w-[5%]"
            />
            <h1 className="mx-1">TESTIMONIAL</h1>
            <img
              src={require("../assets/images/arrow_1.png")}
              alt="Arrow"
              className="w-[5%] transform rotate-180"
            />
          </div>
          <h2 className="font-bold text-3xl text-center my-2 mx-3">
            Our customers love our work!
          </h2>
          <p className="md:text-2xl text-lg text-start md:text-center w-auto text-zinc-600">
            Many companies have partnered with us, here's their feedback on
            working with METANADO:
          </p>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 xl:mx-40 lg:mx-20 md:mx-10 mx-3 py-10">
            {testimonial.map((e, index) => (
              <div className="gap-3">
                <div className="bg-white transform hover:translate-y-[-10px] duration-300 hover:bg-logo hover:text-white p-6 w-[100%] h-[100%] flex flex-col justify-start items-start gap-6">
                  <h1>{e.para}</h1>
                  <div className="px-3 flex justify-center items-center gap-3">
                    <img
                      src={e.image}
                      alt="Image"
                      style={{
                        borderRadius: "50%", // This makes the image circular
                        width: "50px", // Set the width of the image
                        height: "50px", // Set the height of the image
                      }}
                    />
                    <div className="flex flex-col">
                      <div className="flex text-yellow-500">
                        <IoIosStar size={25} />
                        <IoIosStar size={25} />
                        <IoIosStar size={25} />
                        <IoIosStar size={25} />
                        <IoIosStar size={25} />
                      </div>
                      <h1 className="font-bold">{e.name}</h1>
                      <h2>{e.post}</h2>
                    </div>
                    <img
                      src={require("../assets/images/testi-quote.png")}
                      alt="Testi"
                      className="w-[30%] h-[30%]"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="link-area mt-6">
            <a
              className="hover-effect cursor-pointer"
              onClick={() => navigate("our-portfolio")}
            >
              VISIT OUR PORTFOLIO<span className="flash-glow-effect"></span>
            </a>{" "}
          </div>

          {/* <div className='md:flex justify-center items-center'>
            <div className='flex flex-col justify-center items-center'>
              <div className='flex justify-center items-center pt-10'>
                <img src={arrow} alt="Arrow" className='w-[10%]' />
                <h1 className='mx-1'>AWARDS</h1>
                <img src={arrow} alt="Arrow" className='w-[10%] transform rotate-180' />
              </div>
              <h2 className='font-bold text-3xl text-center my-2 mx-3'>Achievements & Recognition</h2>
              <p className='md:text-2xl text-lg text-start md:text-center w-auto md:w-[70%] text-zinc-600'>During our amazing digital journey, we have earned massive respect from the fellow digital fraternity!
              </p>
            </div>
            <img src={testi1} alt="Image" className='w-auto mx-auto' />
          </div> */}
        </div>
      </div>
      <Chat />

      <Footer />
    </div>
  );
};

export default About;
