import typography from "../assets/images/svg/typography.svg";
import frontend from "../assets/images/svg/front-end-programming.svg";
import video from "../assets/images/svg/video-animation.svg";
import logo1 from "../assets/Brands/cropped-logo-01.webp";
import logo2 from "../assets/Brands/gi .png";
import logo3 from "../assets/Brands/icreativez 1.png";
import logo4 from "../assets/Brands/icreativez logo.png";
import logo5 from "../assets/Brands/l.jpg";
import logo6 from "../assets/Brands/pac-image.png";
import logo7 from "../assets/Brands/pac-logo.png";
import logo8 from "../assets/Brands/wedecoratekake.png";
import logo9 from "../assets/Brands/wonderhealing .png";
import logo10 from "../assets/Brands/wonderhealing.png";

import web1 from "../assets/websiteImages/web-image-1.jpg";
import web2 from "../assets/websiteImages/web-image-2.jpg";
import web3 from "../assets/websiteImages/web-image-3.jpg";
import web4 from "../assets/websiteImages/web-image-4.jpg";
import web5 from "../assets/websiteImages/web-image-5.png";
import web6 from "../assets/websiteImages/web-image-6.jpg";
// import web7 from "../assets/websiteImages/web7.png";
// import web8 from "../assets/websiteImages/web8.png";
// import web9 from "../assets/websiteImages/web9.png";
// import digitalMarketing from "../assets/images/svg/"

import brand1 from "../assets/brandingImages/1637935836-firefox-800x600.jpg";
import brand2 from "../assets/brandingImages/1666353321-stillcoveroppo.jpg";
import brand3 from "../assets/brandingImages/1703792970-descript-800x600.webp";
import brand4 from "../assets/brandingImages/1587119225-wyrestyleguidewebkit.jpg";
import brand5 from "../assets/brandingImages/1637935563-bitmoji-800x600.jpg";
import brand6 from "../assets/brandingImages/1587548593-volusion-800-600.png";

export const BrandServices = [
  {
    service: "Brand Design",
    serviceDetails:
      "As a leading company in brand design, we masterfully craft visual elements that resonate with your audience.",
  },
  {
    service: "Brand Strategy",
    serviceDetails:
      "Our brand strategy service is tailored to align with your business goals, propelling you towards unprecedented success.",
  },
  {
    service: "Brand Identity",
    serviceDetails:
      "With an expert understanding of the intrinsic value of brand identity, we ensure your company's core values are accurately represented and appreciated by your target market.",
  },
  {
    service: "Rebranding",
    serviceDetails:
      "Our rebranding service provides a fresh, revitalized perspective that aligns with contemporary demands while maintaining your business's essence.",
  },
  {
    service: "Branding for startups",
    serviceDetails:
      "Such design service helps new startups to establish a distinctive and compelling brand identity to effectively engage their target audience.",
  },
];

export const Plan = [
  {
    title: "Special Logo",
    price: "$49.00",
    para: "2 custom Logo Design Concept",
  },
  {
    title: "Special Logo",
    price: "$49.00",
    para: "2 custom Logo Design Concept",
  },
  {
    title: "Special Logo",
    price: "$49.00",
    para: "2 custom Logo Design Concept",
  },
];

export const menu = [
  {
    id: 1,
    title: "Logo Design & Branding",
    heading: "Logo Design & Branding",
    service: "LOGO DESIGN & BRANDING",
    desc: "We Design With a Purpose!",
    para: "We design logos that last for a lifetime! Our creative approach to branding has made us an industry leader. The logo design services we offer will elevate your brand equity. However, only a logo design is not enough; you need to brand your entire business to create an impact on digital media. Luckily, we have the answer to that as well! Prismedium provides different branding packages at pocket-friendly rates!",
    image: require("../assets/images/service-img-logo-design.png"),
    image1: typography,
    // image2: require("../assets/images/services-top.png"),
  },
  {
    id: 2,
    title: "STRIKING WEB DESIGN & DEVELOPMENT",
    heading: "Web Design & Development",
    service: "WEBSITE DESIGN",
    desc: "Our Designed Websites Are As Smooth As Silk",
    para: "Your website design is the front store in digital media; you cannot take any risk with it. Our websites are functional inside out; with a secured back-end and attractive front-end, your website will be the lead generating machine that you always wanted it to be! <br/> Hire us now, and our digital architects will give your web page the attention it deserves! The best and affordable website design service in town.",
    image: require("../assets/images/website.png"),
    image1: frontend,
  },
  {
    id: 3,
    title: "STUNNING VIDEO ANIMATION",
    heading: "Video Animation",
    service: "VIDEO ANIMATION",
    desc: "There Are Some Emotions That Only An Animated Video Can Express!",
    para: "Video is the new king, and it is time we accept it as one! Netizens are crazy over video content and consume hours of it every day. Do you know what that means? Yes, you are right! An ROI value beyond our calculation.<br/> Then what are you waiting for? Hire us because we are the most affordable 2d and 3d animation service out there.",
    image: require("../assets/images/video.png"),
    image1: video,
  },
  // {
  //   id: 4,
  //   title: "UNMATCHED DIGITAL MARKETING",
  //   heading: "Digital Marketing",
  //   service: "DIGITAL MARKETING",
  //   desc: "Announce Your Dominance In The Digital World!",
  //   para: "Marketing wasn't this effective before the rise of digital media. Our digital marketing campaigns will spread like wildfire! Our team of digital marketers are well-versed in digital and exactly know how the complex algorithm works. We are a complete digital marketing agency that promises a high ROI. No matter what your digital media budget is, we assure you that your brand will do wonders!",
  //   image: require("../assets/images/service-img-digital-marketing.png"),
  //   image1: require("../assets/images/megaphone.png")
  // }
];
export default menu;

export const testimonial = [
  {
    para: "“We teamed up with METANADO to craft a fresh visual identity for our recent product launch. Their knowledge and imagination were truly priceless.”",
    name: "Jane Smith ",
    post: "Head of Product Design at InnovateTech",
    image: require("../assets/Testimonials/testimonail-1.jpg"),
  },
  {
    para: "“METANADO has really helped take our brand to the next level. Their team played a crucial role in boosting our company's image and getting us more exposure.”",
    name: "Alex Johnson ",
    post: "Founder & CEO at TechFlow",
    image: require("../assets/Testimonials/testimonail-3.jpg"),
  },
  {
    para: "“Our project has been highlighted on top design platforms numerous times. Our in-house designer, who holds very high standards, lauded METANADO’s exceptional branding work.”",
    name: "Emily Taylor ",
    post: "CEO at BrightWave",
    image: require("../assets/Testimonials/testimonail-2.jpg"),
  },
  {
    para: "“METANADO's rebranding efforts have established us as a top player in our field, making it easier for our customers to grasp the full range of services we offer.”",
    name: "Chris Brown ",
    post: "CEO at FastNet",
    image: require("../assets/Testimonials/testimonail-4.jpg"),
  },
];

export const testimonialWeb = [
  {
    para: "“METANADO really impressed us with how quickly and carefully they handled our web design project. They not only grasped our vision but also improved upon it, giving us outstanding results. We're excited to keep working with them in the future.”",
    name: "Jessica Lee",
    post: "Marketing Director at InnovateX",
    image: require("../assets/Testimonials/personweb1.jpg"),
  },
  {
    para: "“METANADO's team really impressed us with their quick response and dedication to delivering top-notch quality. Their input was crucial in making our website a success, and we couldn't be happier with the results and their continued assistance.”",
    name: "Michael Nguyen",
    post: "CEO at SwiftScale",
    image: require("../assets/Testimonials/personweb2.jpg"),
  },
  {
    para: "“Their contribution was essential in changing our online presence, leading to a notable rise in customer interaction. Their commitment to adapting to our changing needs makes them stand out.”",
    name: "Sarah Johnson ",
    post: "Founder at Peak Dynamics",
    image: require("../assets/Testimonials/personweb3.jpg"),
  },
  {
    para: "“Thanks to METANADO, we were able to build a waitlist of over 100,000 enthusiastic users when we introduced our product. Their involvement in our web design and development was crucial for our fast growth and satisfied customers.”",
    name: "Sarah Thompson",
    post: "Head of Product at LaunchPad",
    image: require("../assets/Testimonials/personweb4.jpg"),
  },
];

export const testimonialBrand = [
  {
    para: "Partnering with METANADO for our digital marketing requirements has truly been a game-changer. Their strategic approach and precise execution have greatly enhanced our online presence and engagement.",
    name: "Emma Robinson",
    post: "Marketing Director at TechWave ",
    image: require("../assets/Testimonials/personbrand-1.jpg"),
  },
  {
    para: "METANADO team's knowledge in both paid and organic marketing has led to impressive growth for our business. Their customized tactics and data-focused analysis have continuously produced exceptional outcomes.",
    name: "James Carter",
    post: "CEO at FinTech Solutions",
    image: require("../assets/Testimonials/personbrand-2.jpg"),
  },
  {
    para: "METANADO's creative social media campaigns have significantly boosted our brand visibility and engagement with customers. Their unique approach and meticulous attention to detail truly make them stand out.",
    name: "Sophia Lee",
    post: "Founder at EcoTrend",
    image: require("../assets/Testimonials//personbrand-3.jpg"),
  },
  {
    para: "The new branding could help Mozilla position Firefox as more than just the browser everyone is already familiar with—and help users get familiar with Firefox’s entire system of services.",
    name: "Fast Company",
    post: "CEO",
    image: require("../assets/Testimonials/personbrand-4.jpg"),
  },
];

export const logoData = [
  {
    title: "2D Animated Logo",
    subTitle: "2D Animated Logo",
    description: "Our 2D animated logos will...",
    images: [
      require("../assets/images/logo-inner-img.gif"),
      require("../assets/images/logo-inner-img.gif"),
      require("../assets/images/logo-inner-img.gif"),
      require("../assets/images/logo-inner-img.gif"),
    ],
    link: "javascript:;.html",
  },
  {
    title: "3D Animated Logo",
    subTitle: "3D Animated Logo",
    description: "Our 3D logo design service will...",
    images: [
      require("../assets/images/logo-inner-img.gif"),
      require("../assets/images/logo-inner-img.gif"),
      require("../assets/images/logo-inner-img.gif"),
      require("../assets/images/logo-inner-img.gif"),
    ],
    link: "javascript:;.html",
  },
  // Add more logo data objects as needed
];

export const plans = [
  {
    title: "Special Logo",
    price: "$49.00",
    features: [
      "2 Custom Logo Design Concepts",
      "1 Dedicated Designer",
      "3 Revisions",
      "24 - 48 Hours Turn Around Time",
      "FREE Icon Design (JPEG Formats)",
      "100% Unique Design Guarantee",
      "100% Satisfaction Guarantee",
      "100% Money Back Guarantee *",
    ],
  },
  {
    title: "Basic Logo",
    price: "$99.99",
    features: [
      "4 Custom Logo Design Concepts",
      "By 2 Designers",
      "Unlimited Revisions",
      "24 to 48 Hours Turn Around Time",
      "File Formats: Ai, Jpeg",
      "100% Satisfaction Guarantee",
      "100% Unique Design Guarantee",
      "100% Money Back Guarantee*",
    ],
  },
  {
    title: "Elite Logo",
    price: "$399.99",
    features: [
      "3 Logo Design Concepts",
      "By 2 Award Winning Logo Designers",
      "3 Revisions",
      "File Formats: Ai, Eps, Png, Psd, Jpeg, Tiff",
      "3 Page Static Website",
      "1 Year Domain Name",
      "1 Year Web Hosting",
      "Search Engine Friendly HTML",
      "Unlimited Revisions on Complete Package",
      "48 to 72 Hours TurnAround Time",
      "Dedicated Account Manager",
      "100% Satisfaction Guarantee",
      "100% Unique Design Guarantee",
      "100% Money Back Guarantee",
    ],
  },
  {
    title: "Corporate Logo",
    price: "$249.99",
    features: [
      "Unlimited Logo Design Concepts",
      "By 6 Designers",
      "Unlimited Revisions",
      "FREE Icon Design",
      "24 - 48 Hours TurnAround Time",
      "FREE 1 Stationery Design Set (Business Cards, Letterhead, Envelope & Email Signature)",
      "File Formats: Ai, Eps, Png, Psd, Jpeg, Tiff",
      "100% Satisfaction With Unlimited Revisions",
      "100% Money Back Guarantee*",
    ],
  },
  {
    title: "Professional Logo",
    price: "$169.99",
    features: [
      "8 Logo Design Concepts",
      "By 4 Industry Based Designers",
      "UNLIMITED Revisions",
      "Stationary Design (Business Card, Letterhead, Envelope Design )",
      "24 to 48 Hours Turn Around Time",
      "File Formats (EPS, Ai, GIF, JPEG, PSD)",
      "Dedicated Account Manager",
      "100% Satisfaction Guarantee",
      "100% Unique Design Guarantee",
      "100% Money Back Guarantee",
    ],
  },
  {
    title: "Mascot Logo",
    price: "$499.99",
    features: [
      "(1) Mascot Logo",
      "Hand Drawn Sketch",
      "UNLIMITED Revisions",
      "2 Stationary Design Sets",
      "Presentation On Mockup",
      "Stationery Design (Business Card, Letterhead, Envelope, Email Signature)",
      "(2) Days' Time Delivery (Initial 72 hours)",
      "Revision Time Delivery (24 hours)",
      "All Final Files Format (AI, PSD, EPS, PNG, GIF, JPG, PDF)",
      "100% Satisfaction Guarantee",
      "100% Unique Design Guarantee",
      "100% Money Back Guarantee *",
    ],
  },
];

export const WebPlans = [
  {
    title: "Basic Web",
    price: "$219",
    features: [
      "2 Stock Images",
      "3 Page Website",
      "1 jQuery Slider Banner",
      "Contact/Query Form",
      "Complete W3C Certified php",
      "48 to 72 hours TAT",
      "Complete Deployment",
      "100% Satisfaction Guarantee",
      "100% Unique Design Guarantee",
      "100% Money Back Guarantee *",
    ],
  },
  {
    title: "Startup Web",
    price: "$419",
    features: [
      "5 Stock Photos",
      "5 Page Website",
      "3 Banner Design",
      "1 jQuery Slider Banner",
      "FREE Google Friendly Sitemap",
      "Complete W3C Certified php",
      "48 to 72 hours TAT",
      "100% Satisfaction Guarantee",
      "100% Unique Design Guarantee",
      "100% Money Back Guarantee *",
      "Mobile Responsive will be Additional $200*",
      "CMS will be Additional",
    ],
  },
  {
    title: "Professional Web",
    price: "$819",
    features: [
      "10 Unique Pages Website",
      "CMS / Admin Panel Support",
      "8 Stock images",
      "5 Banner Designs",
      "1 jQuery Slider Banner",
      "FREE Google Friendly Sitemap",
      "Complete W3C Certified php",
      "48 to 72 hours TAT",
      "Complete Deployment",
      "100% Satisfaction Guarantee",
      "100% Unique Design Guarantee",
      "100% Money Back Guarantee *",
      "Mobile Responsive will be Additional",
    ],
  },
  {
    title: "Identity Web",
    price: "$1399",
    features: [
      "Upto 15 Unique Pages Website",
      "Conceptual and Dynamic Website",
      "Mobile Responsive",
      "Online Reservation/Appointment Tool (Optional)",
      "Online Payment Integration (Optional)",
      "Custom Forms",
      "Lead Capturing Forms (Optional)",
      "Striking Hover Effects",
      "Newsletter Subscription (Optional)",
      "Newsfeed Integration",
      "Social Media Integration",
      "Search Engine Submission",
      "5 Stock Photos",
      "3 Unique Banner Design",
      "1 jQuery Slider Banner",
      "Complete W3C Certified php",
      "48 to 72 hours TAT",
      "Complete Deployment",
      "100% Satisfaction Guarantee",
      "100% Unique Design Guarantee",
      "100% Money Back Guarantee *",
    ],
  },
  {
    title: "Elite Web",
    price: "$1799",
    features: [
      "15 to 20 Pages Website",
      "Custom Made, Interactive, Dynamic & High End Design",
      "Custom WP (or) Custom PHP Development",
      "1 jQuery Slider Banner",
      "Up to 10 Custom Made Banner Designs",
      "10 Stock Images",
      "Unlimited Revisions",
      "Special Hoover Effects",
      "Content Management System (CMS)",
      "Online Appointment/Scheduling/Online Ordering Integration (Optional)",
      "Online Payment Integration (Optional)",
      "Multi Lingual (Optional)",
      "Custom Dynamic Forms (Optional)",
      "Signup Area (For Newsletters, Offers etc.)",
      "Search Bar",
      "Live Feeds of Social Networks integration (Optional)",
      "Mobile Responsive",
      "FREE 5 Years Domain Name",
      "Free Google Friendly Sitemap",
      "Search Engine Submission",
      "Complete W3C Certified php",
      "Industry Specified Team of Expert Designers and Developers",
      "Complete Deployment",
      "Dedicated Accounts Manager",
      "100% Ownership Rights",
      "100% Satisfaction Guarantee",
      "100% Unique Design Guarantee",
      "100% Money Back Guarantee *",
    ],
  },
  {
    title: "Business Web",
    price: "$2999",
    features: [
      "15 Seconds 2D Explainer Video",
      "Voice - Over & Sound Effects",
      "Professional Script Writing",
      "Storyboard",
      "SEO Meta Tags",
      "15 to 20 Pages Website",
      "Custom Made, Interactive, Dynamic & High End Design",
      "Custom WP (or) Custom PHP Development",
      "1 jQuery Slider Banner",
      "Up to 10 Custom Made Banner Designs",
      "10 Stock Images",
      "Unlimited Revisions",
      "Special Hoover Effects",
      "Content Management System (CMS)",
      "Online Appointment/Scheduling/Online Ordering Integration (Optional)",
      "Online Payment Integration (Optional)",
      "Multi Lingual (Optional)",
      "Custom Dynamic Forms (Optional)",
      "Signup Area (For Newsletters, Offers etc.)",
      "Search Bar",
      "Live Feeds of Social Networks integration (Optional)",
      "Mobile Responsive",
      "FREE 5 Years Domain Name",
      "Free Google Friendly Sitemap",
      "Search Engine Submission",
      "Complete W3C Certified php",
      "Industry Specified Team of Expert Designers and Developers",
      "Complete Deployment",
      "Dedicated Accounts Manager",
      "100% Ownership Rights",
      "100% Satisfaction Guarantee",
      "100% Unique Design Guarantee",
      "100% Money Back Guarantee *",
    ],
  },
];

export const videoPlans = [
  {
    title: "The Executive Video",
    price: "$599",
    features: [
      "30 Second Video (Yes, You Can Extend Your Duration!)",
      "Whiteboard Video / Motion Graphics",
      "Professional Script",
      "Custom Artwork; No Stolen Images",
      "Storyboard",
      "Unlimited Storyboard Revisions",
      "Exotic Animations",
      "HD Video Production",
      "Professional Voice – Over & Sound Effects",
      "4 weeks Delivery",
      "Dedicated Account Executive",
      "100% Satisfaction Guarantee",
      "100% Money Back Guarantee",
      "100% Unique Design Guarantee",
    ],
    buttonText: "Get Started",
    buttonLink: "javascript:;.html",
    detailsLink: "view-detail-video-330.html",
  },
  {
    title: "2D Advance Video",
    price: "$1099",
    features: [
      "30 Second Video (Yes, You Can Extend Your Duration!)",
      "2D Animation/Cut-out Video/Screen Cast",
      "Custom Artwork; No Stolen Images",
      "Initial Script Writing",
      "Custom Design",
      "Storyboard",
      "Unlimited Storyboard Revisions",
      "Exotic Animations",
      "Professional Voice – Over & Sound Effects",
      "5 weeks Delivery",
      "Dedicated Account Manager",
      "100% Satisfaction Guarantee",
      "100% Money Back Guarantee",
      "100% Unique Design Guarantee",
    ],
    buttonText: "Get Started",
    buttonLink: "javascript:;.html",
    detailsLink: "view-detail-video-830.html",
  },
  {
    title: "2D Elite Video",
    price: "$1499",
    features: [
      "30 Second Video (Yes, You Can Extend Your Duration!)",
      "2 Character Illustration / Music Videos",
      "Custom Artwork; No Stolen Images",
      "Initial Script Writing",
      "Custom Design",
      "Storyboard",
      "Unlimited Storyboard Revisions",
      "Exotic Animations",
      "Professional Voice – Over & Sound Effects",
      "5 weeks Delivery",
      "Dedicated Account Manager",
      "100% Satisfaction Guarantee",
      "100% Money Back Guarantee",
      "100% Unique Design Guarantee",
    ],
    buttonText: "Get Started",
    buttonLink: "javascript:;.html",
    detailsLink: "view-detail-video-1330.html",
  },
  {
    title: "3D Boss Video",
    price: "$3399",
    features: [
      "30 Second Video (Yes, You Can Extend Your Duration!)",
      "Custom Artwork; No Stolen Images",
      "Initial Script Writing",
      "Custom Design",
      "Storyboard",
      "Unlimited Storyboard Revisions",
      "Exotic Animations",
      "Professional Voice – Over & Sound Effects",
      "5 weeks Delivery",
      "Dedicated Account Manager",
      "100% Satisfaction Guarantee",
      "100% Money Back Guarantee",
      "100% Unique Design Guarantee",
    ],
    buttonText: "Get Started",
    buttonLink: "javascript:;.html",
    detailsLink: "view-detail-video-3230.html",
  },
];

export const logosData = [
  {
    title: "2D Animated Logo",
    description:
      "Our 2D animated logos will instantaneously be recognizable and memorable to your audience. With its classic, simple, and trendy features, your 2d logo designs will skyrocket your business in just a matter of time. So, what are you waiting for?",
    images: [
      require("../assets/images/logo-inner-img.gif"),
      require("../assets/images/logo-inner-img2.gif"),
      require("../assets/images/logo-inner-img3_1.gif"),
      require("../assets/images/logo-inner-img4.gif"),
    ],
  },
  {
    title: "3D Animated Logo",
    description:
      "Our 3D logo design service will instantly hypnotize your new customers in such a way that they will keep on getting back to you for your product or services. A 3d logo design is a strong symbolic representation of your business.",
    images: [
      require("../assets/images/logo-inner-img_1.gif"),
      require("../assets/images/logo-inner-img2_1.gif"),
      require("../assets/images/logo-inner-img3.gif"),
      require("../assets/images/logo-inner-img4_1.gif"),
    ],
  },
  {
    title: "Iconic Logo Design",
    description:
      "Our iconic logo design will surely blow your audience's mind, with its compelling imagery that is highly indicative and presents your particular product or brand. We use a different yet unique type of abstract representation for your organization.",
    images: [
      require("../assets/images/logo-inner-img.png"),
      require("../assets/images/logo-inner-img2.png"),
      require("../assets/images/logo-inner-img3.png"),
      require("../assets/images/logo-inner-img4.png"),
    ],
  },
  {
    title: "Illustrative Logo",
    description:
      "Illustrative logos are quite unique and they have been in trend for a well amount of time. They are simply just pictures, but they deliver and strong and steady meaning. Illustrative logos are a perfect choice if you're selling products or services.",
    images: [
      require("../assets/images/logo-inner-img_1.png"),
      require("../assets/images/logo-inner-img2_2.png"),
      require("../assets/images/logo-inner-img3_2.png"),
      require("../assets/images/logo-inner-img4_2.png"),
    ],
  },
  {
    title: "Symbolic Logo",
    description:
      "A symbolic logo design is a perfect choice for you if you’re looking for an abstract representation of your well-established or a startup business. Symbolic logo can be eye-catching, and highly captivating, that makes your brand stand out.",
    images: [
      require("../assets/images/logo-inner-img_3.png"),
      require("../assets/images/logo-inner-img2_1.png"),
      require("../assets/images/logo-inner-img3_3.png"),
      require("../assets/images/logo-inner-img4_1.png"),
    ],
  },
  {
    title: "Typographic Logo",
    description:
      "Typographic logo designs completely rely on text, they are powerful enough to print themselves into the mind of your customers. Typographic logo designs help you share your brand's exciting, admirable and successful story in-depth.",
    images: [
      require("../assets/images/logo-inner-img_2.png"),
      require("../assets/images/logo-inner-img2_3.png"),
      require("../assets/images/logo-inner-img3_1.png"),
      require("../assets/images/logo-inner-img4_3.png"),
    ],
  },
];

export const websiteData = [
  {
    title: "Corporate Websites",
    description:
      "Each corporate website built by Candigitals is tailored to your company's specific demands and objectives. Our goal is to create a professional, branded website for your company that will set it out from the competition.",
    images: [
      require("../assets/images/corporate-web-inner-img1.png"),
      require("../assets/images/corporate-web-inner-img2.png"),
      require("../assets/images/corporate-web-inner-img3.png"),
      require("../assets/images/corporate-web-inner-img4.png"),
    ],
  },
  {
    title: "E-Commerce Websites",
    description:
      "The cornerstone of any online retail business is a strong E-commerce website, and we understand the value of leveraging cutting-edge technology and extensive knowledge to boost sales.",
    images: [
      require("../assets/images/ecomemrce-web-inner-img1.png"),
      require("../assets/images/ecomemrce-web-inner-img2.png"),
      require("../assets/images/ecomemrce-web-inner-img3.png"),
      require("../assets/images/ecomemrce-web-inner-img4.png"),
    ],
  },
  {
    title: "Flat Design Website",
    description: (
      <>
        For responsive design, where a website's content adapts smoothly
        depending on the device's screen size, flat design websites are highly
        recommended.
        <br />
      </>
    ),
    images: [
      require("../assets/images/flat-web-inner-img1.png"),
      require("../assets/images/flat-web-inner-img2.png"),
      require("../assets/images/flat-web-inner-img3.png"),
      require("../assets/images/flat-web-inner-img4.png"),
    ],
  },
  {
    title: "Landing Pages",
    description:
      "We provide customized services to create high-quality landing pages for a variety of internet marketing initiatives. Our team of highly qualified and experienced writers and designers collaborate to create high-quality, personalized landing pages for businesses of all sizes in the United States.",
    images: [
      require("../assets/images/landing-page-web-inner-img1.png"),
      require("../assets/images/landing-page-web-inner-img2.png"),
      require("../assets/images/landing-page-web-inner-img3.png"),
      require("../assets/images/landing-page-web-inner-img4.png"),
    ],
  },
  {
    title: "Responsive Websites",
    description:
      "We offer fully responsive websites that are compatible with all electronic devices and screen sizes. These days, almost every new client wants a mobile version of their website.",
    images: [
      require("../assets/images/respomsive-web-inner-img1.png"),
      require("../assets/images/respomsive-web-inner-img2.png"),
      require("../assets/images/respomsive-web-inner-img3.png"),
      require("../assets/images/respomsive-web-inner-img4.png"),
    ],
  },
  {
    title: "WordPress websites",
    description:
      "We are a reputable and result-oriented WordPress website design firm. We cater to all the needs to develop a beautiful E-commerce website on the CMS of WordPress. Our professionals will get you set up on the most user-friendly web platform available.",
    images: [
      require("../assets/images/wordpress-web-inner-img1.png"),
      require("../assets/images/wordpress-web-inner-img2.png"),
      require("../assets/images/wordpress-web-inner-img3.png"),
      require("../assets/images/wordpress-web-inner-img4.png"),
    ],
  },
];

export const videoData = [
  {
    title: "2D Animated Videos",
    description:
      "One of the most common types of animation is 2D animation. It's commonly used to make animated films, cartoons, marketing videos, advertising, business presentations, educational materials, video games, and more.",
    images: [
      require("../assets/images/2d-2d-inner-img1.png"),
      require("../assets/images/2d-2d-inner-img2.png"),
      require("../assets/images/2d-2d-inner-img3.png"),
      require("../assets/images/2d-2d-inner-img4.png"),
    ],
  },
  {
    title: "3D Animated Videos",
    description:
      "3D animation can give your company a whole new level. It adds incredible texture, depth, and illumination to even the most mundane items. As a result, during the 1990s, the use of 3D animation has steadily increased.",
    images: [
      require("../assets/images/3d-3d-inner-img1.png"),
      require("../assets/images/3d-3d-inner-img2.png"),
      require("../assets/images/3d-3d-inner-img3.png"),
      require("../assets/images/3d-3d-inner-img4.png"),
    ],
  },
  {
    title: "Motion Graphics",
    description:
      "We make motion graphics videos that use highly engaging images to simplify complex subjects. Motion graphics animation films are an excellent method to make normally static, dull content more engaging and exciting for your viewers.",
    images: [
      require("../assets/images/motion-motion-inner-img1.png"),
      require("../assets/images/motion-motion-inner-img2.png"),
      require("../assets/images/motion-motion-inner-img3.png"),
      require("../assets/images/motion-motion-inner-img4.png"),
    ],
  },
  {
    title: "Screencast Videos",
    description:
      "A screencast is a digital recording of a computer's output, also known as video screen capture, that includes audio narrations. Create a screencast Videos are made to achieve the best results in a short amount of time.",
    images: [
      require("../assets/images/screen-cast-screen-inner-img1.png"),
      require("../assets/images/screen-cast-screen-inner-img2.png"),
      require("../assets/images/screen-cast-screen-inner-img3.png"),
      require("../assets/images/screen-cast-screen-inner-img4.png"),
    ],
  },
  {
    title: "Stock Animated Videos",
    description:
      "Use eye-catching graphics and videos to make your information more appealing. As a result, use authentic stock videos to entice your viewers. Stock animation follows the same strategy as stock photography or stock cinema.",
    images: [
      require("../assets/images/stock-animation-stock-inner-img1.png"),
      require("../assets/images/stock-animation-stock-inner-img2.png"),
      require("../assets/images/stock-animation-stock-inner-img3.png"),
      require("../assets/images/stock-animation-stock-inner-img4.png"),
    ],
  },
  {
    title: "Whiteboard Animation",
    description:
      "Do you want to add some video to your website's content to make it more interesting? An explainer film, such as a whiteboard animation video, is often used to market a product or describe a system. The use of whiteboard animation videos to communicate complicated ideas is quite effective.",
    images: [
      require("../assets/images/whiteboard-white-inner-img1.png"),
      require("../assets/images/whiteboard-white-inner-img2.png"),
      require("../assets/images/whiteboard-white-inner-img3.png"),
      require("../assets/images/whiteboard-white-inner-img4.png"),
    ],
  },
];

export const marketingData = [
  {
    title: "On-Page SEO",
    description:
      "Our SEO services include complete on-site SEO including content optimization, keyword integration, optimized titles, meta description, and images. Our goal is to rank your site on the first page of Search Engines.",
    images: [
      require("../assets/images/content-content-inner-img1.png"),
      require("../assets/images/content-content-inner-img2.png"),
      require("../assets/images/content-content-inner-img3.png"),
      require("../assets/images/content-content-inner-img4.png"),
    ],
  },
  {
    title: "Content Optimization",
    description:
      "We create optimized content pieces for sites that are strategically optimized using relevant keywords and are well-versed. We ensure with our content marketing services that the content reaches a larger audience.",
    images: [
      require("../assets/images/earn-media-earn-inner-img1.png"),
      require("../assets/images/earn-media-earn-inner-img2.png"),
      require("../assets/images/earn-media-earn-inner-img3.png"),
      require("../assets/images/earn-media-earn-inner-img4.png"),
    ],
  },
  {
    title: "Off-Page SEO",
    description:
      "Our team of SEO experts, using white-hat SEO techniques, creates unique backlinks that result in higher traffic turnover and aid search engines in considering your site as a credible and authentic source.",
    images: [
      require("../assets/images/keyword-key-inner-img1.png"),
      require("../assets/images/keyword-key-inner-img2.png"),
      require("../assets/images/keyword-key-inner-img3.png"),
      require("../assets/images/keyword-key-inner-img4.png"),
    ],
  },
  {
    title: "Keyword Research",
    description:
      "Using modern keyword research tools and techniques, we optimize the content using these keywords strategically and combine them with off-page SEO techniques to rank your site against the keywords used in the content.",
    images: [
      require("../assets/images/optimization-Opti-inner-img1.png"),
      require("../assets/images/optimization-Opti-inner-img2.png"),
      require("../assets/images/optimization-Opti-inner-img3.png"),
      require("../assets/images/optimization-Opti-inner-img4.png"),
    ],
  },
  {
    title: "Website Optimization",
    description:
      "Our SEO team analyzes the website thoroughly. We perform modifications to your site’s coding and structure to make your site SEO-friendly. This makes your site stand out and helps Google consider your site better than the competitors.",
    images: [
      require("../assets/images/research-research-inner-img1.png"),
      require("../assets/images/research-research-inner-img2.png"),
      require("../assets/images/research-research-inner-img3.png"),
      require("../assets/images/research-research-inner-img4.png"),
    ],
  },
  {
    title: "Testing Phase",
    description:
      "Our SEO optimization services don't stop at bringing relevant, qualified search traffic to your website. We work with you to ensure that visitors to your website are guided through the sales funnel simply and straightforwardly.",
    images: [
      require("../assets/images/testing-earn-inner-img1.png"),
      require("../assets/images/testing-earn-inner-img2.png"),
      require("../assets/images/testing-earn-inner-img3.png"),
      require("../assets/images/testing-earn-inner-img4.png"),
    ],
  },
];

export const LogoDetails = [
  {
    id: 1,
    route: "leftover-toy",
    title: "Leftover Toys",
    heading: "LEFTOVER TOYS",
    para: "Leftover Toys is a growing designer toy brand that designs and produces limited edition collectibles based on the imaginative original characters of artist Johnny Dean. From vinyl toys to resin figures, Leftover Toys dedicates itself to creating unique toys that are both thought-provoking and fun. Our work included brand development, logo design, packaging design, print design, and apparel design.",
    video1: require("../assets/images/logo/video/toy.mp4"),
    img1: require("../assets/images/logo/toy-1.png"),
    img2: require("../assets/images/logo/toy-2.png"),
    img3: require("../assets/images/logo/toy-3.png"),
    img4: require("../assets/images/logo/toy-4.png"),
    img5: require("../assets/images/logo/toy-5.png"),
    img6: require("../assets/images/logo/toy-6.png"),
    img7: require("../assets/images/logo/toy-7.png"),
    img8: require("../assets/images/logo/toy-8.jpg"),
    img9: require("../assets/images/logo/toy-9.jpg"),
    img10: require("../assets/images/logo/toy-10.jpg"),
    img11: require("../assets/images/logo/toy-11.jpg"),
    img12: require("../assets/images/logo/toy-12.jpg"),
    img13: require("../assets/images/logo/toy-13.png"),
  },
  {
    id: 2,
    route: "hoopfest-2022",
    title: "Hoopfest 2022",
    heading: "",
    para: "",
    img1: require("../assets/images/logo/fest-1.jpg"),
    img2: require("../assets/images/logo/fest-2.jpg"),
    img3: require("../assets/images/logo/fest-3.jpg"),
    img4: require("../assets/images/logo/fest-4.jpg"),
    img5: require("../assets/images/logo/fest-5.jpg"),
    img6: require("../assets/images/logo/fest-6.jpg"),
    img7: require("../assets/images/logo/fest-7.jpg"),
    img8: require("../assets/images/logo/fest-8.jpg"),
    img9: require("../assets/images/logo/fest-9.jpg"),
    img10: require("../assets/images/logo/fest-10.jpg"),
    img11: require("../assets/images/logo/fest-11.jpg"),
    img12: require("../assets/images/logo/fest-12.jpg"),
    img13: require("../assets/images/logo/fest-13.jpg"),
  },
  {
    id: 3,
    route: "peak-health",
    title: "Peak Health",
    heading: "PEAK HEALTH",
    para: "Peak Health Genomics Medicine develops personalized, genomics-based, health plans for their patients. Propaganda worked with them to craft a new logo and professional branding that could be used across all their marketing applications.",
    img1: require("../assets/images/logo/peak-1.png"),
    img2: require("../assets/images/logo/peak-2.png"),
    img3: require("../assets/images/logo/peak-3.png"),
    img4: require("../assets/images/logo/peak-4.png"),
    img5: require("../assets/images/logo/peak-5.png"),
    img6: require("../assets/images/logo/peak-6.png"),
    img7: require("../assets/images/logo/peak-7.png"),
    img8: require("../assets/images/logo/peak-8.png"),
    img9: require("../assets/images/logo/peak-9.png"),
  },
  {
    id: 4,
    route: "the-accompaniment-company",
    title: "The Accompaniment Company",
    heading: "ACCOMPANIMENT COMPANY",
    para: "Logo and identity design, web development, and messaging for The Accompaniment Company—A Spokane-based business that focuses on “empowering the home chef” by providing the “accompaniments.” This can be anything from pickled onions to schmears or breads. YUMMMM.",
    img1: require("../assets/images/logo/company-1.png"),
    img2: require("../assets/images/logo/company-2.png"),
    img3: require("../assets/images/logo/company-3.png"),
    img4: require("../assets/images/logo/company-4.png"),
    img5: require("../assets/images/logo/company-5.png"),
    img6: require("../assets/images/logo/company-6.png"),
    img7: require("../assets/images/logo/company-7.png"),
    img8: require("../assets/images/logo/company-8.png"),
    img9: require("../assets/images/logo/company-9.png"),
    img10: require("../assets/images/logo/company-10.png"),
    img11: require("../assets/images/logo/company-11.png"),
    img12: require("../assets/images/logo/company-12.png"),
    img13: require("../assets/images/logo/company-13.png"),
    img14: require("../assets/images/logo/company-14.png"),
    img15: require("../assets/images/logo/company-15.png"),
    img16: require("../assets/images/logo/company-16.png"),
  },
  {
    id: 5,
    route: "highball-speakeasy",
    title: "Highball Speakeasy",
    heading: "HIGHBALL SPEAKEASY",
    para: "Reminiscent of a prosperous era when jazz flourished and liquor flowed, Highball is complete with swanky ambiance and modern luxury. The venue features Prohibition-inspired cocktails, decadent dining and dessert offerings, and an ever-rotating calendar of nightlife and entertainment. Propaganda developed its brand identity, patterns, and environmental graphics.",
    img1: require("../assets/images/logo/ball-1.png"),
    img2: require("../assets/images/logo/ball-2.png"),
    img3: require("../assets/images/logo/ball-3.png"),
    img4: require("../assets/images/logo/ball-4.png"),
    img5: require("../assets/images/logo/ball-5.png"),
    img6: require("../assets/images/logo/ball-6.png"),
    img7: require("../assets/images/logo/ball-7.png"),
    img8: require("../assets/images/logo/ball-8.png"),
    img9: require("../assets/images/logo/ball-9.png"),
  },

  {
    id: 6,
    route: "high-caliber-whiskey",
    title: "High Caliber Whiskey",
    heading: "HIGH CALIBER WHISKEY",
    para: "Branding, Packaging and 3-d Design for High Caliber Whiskey. Hailing from North Idaho, High Caliber is crafted by AND for the freedom seekers, regulars and community drinkers who love whisky and being together. Sign us up for a bottle or twelve.",
    img1: require("../assets/images/logo/high-1.png"),
    img2: require("../assets/images/logo/high-2.png"),
    img3: require("../assets/images/logo/high-3.png"),
    img4: require("../assets/images/logo/high-4.png"),
    img5: require("../assets/images/logo/high-5.png"),
    img6: require("../assets/images/logo/high-6.png"),
    img7: require("../assets/images/logo/high-7.png"),
    img8: require("../assets/images/logo/high-8.png"),
    img9: require("../assets/images/logo/high-9.png"),
    img10: require("../assets/images/logo/high-10.png"),
    img11: require("../assets/images/logo/high-11.png"),
    img12: require("../assets/images/logo/high-12.png"),
  },
  {
    id: 7,
    route: "swanky-sweets-branding-packaging-design",
    title: "Swanky Sweets",
    heading: "SWANKY SWEETS",
    para: "Libby makes the most delectably delicious sweets with a decorative touch. Her previous brand didn’t do her (amazing!) handiwork justice. We spruced her image up a bit with a fun and light, retro-yet-modern branding package.",
    img1: require("../assets/images/logo/swanky-1.jpg"),
    img2: require("../assets/images/logo/swanky-2.jpg"),
    img3: require("../assets/images/logo/swanky-3.jpg"),
    img4: require("../assets/images/logo/swanky-4.jpg"),
    img5: require("../assets/images/logo/swanky-5.jpg"),
    img6: require("../assets/images/logo/swanky-6.jpg"),
    img7: require("../assets/images/logo/swanky-7.jpg"),
  },
  {
    id: 8,
    route: "3d-pandaz",
    title: "3D Pandaz",
    heading: "3D PANDAZ NFT",
    para: "Propaganda worked with Grow-Op Farms to develop their new line of 3D Pandaz NFTs. This extensive line of over 175 unique features included clothing, glasses, accessories, and animations. Enjoy!",
    img1: require("../assets/images/logo/panda-1.png"),
    img2: require("../assets/images/logo/panda-2.png"),
    img3: require("../assets/images/logo/panda-3.png"),
    img4: require("../assets/images/logo/panda-4.png"),
    img5: require("../assets/images/logo/panda-5.png"),
    img6: require("../assets/images/logo/panda-6.png"),
    img7: require("../assets/images/logo/panda-7.png"),
    img8: require("../assets/images/logo/panda-8.png"),
    img9: require("../assets/images/logo/panda-9.png"),
    img10: require("../assets/images/logo/panda-10.png"),
    img11: require("../assets/images/logo/panda-11.png"),
    img12: require("../assets/images/logo/panda-12.png"),
    img13: require("../assets/images/logo/panda-13.png"),
  },
  {
    id: 9,
    route: "cyrus-olearys-pies-branding-packaging-web-design",
    title: "Cyrus O’Leary’s Pies",
    heading: "CYRUS O’LEARY’S PIES",
    para: `Cyrus O’Leary’s is a national pie company that produces a variety of cream pies, fruit pies, and other delicious confections. They take a lot of pride in their heritage, which dates back to 1981—the opening of the Cyrus O’Leary’s Restaurant in downtown Spokane, Washington. 

    Cyrus came to Propaganda in need of a fresh visual identity as well as a new web presence.  We evolved the well known Cyrus brand to more fully reflect its position as a premium pie company while maintaining its vintage roots.`,
    img1: require("../assets/images/logo/cyrus-1.jpg"),
    img2: require("../assets/images/logo/cyrus-2.jpg"),
    img3: require("../assets/images/logo/cyrus-3.jpg"),
    img4: require("../assets/images/logo/cyrus-4.jpg"),
    img5: require("../assets/images/logo/cyrus-5.jpg"),
    img6: require("../assets/images/logo/cyrus-6.jpg"),
    img7: require("../assets/images/logo/cyrus-7.jpg"),
    img8: require("../assets/images/logo/cyrus-8.jpg"),
  },
  {
    id: 10,
    route: "lloyd-industries-packaging-print-web-design",
    title: "Lloyd Industries",
    heading: "LLOYD INDUSTRIES",
    para: "Packaging design, print design, and web design for a national brand that produces top-quality commercial kitchen and bakeware products.",
    img1: require("../assets/images/logo/lloyd-1.png"),
    img2: require("../assets/images/logo/lloyd-2.jpg"),
    img3: require("../assets/images/logo/lloyd-3.jpg"),
    img4: require("../assets/images/logo/lloyd-4.jpg"),
    img5: require("../assets/images/logo/lloyd-5.png"),
    img6: require("../assets/images/logo/lloyd-6.png"),
    img7: require("../assets/images/logo/lloyd-7.png"),
    img8: require("../assets/images/logo/lloyd-8.png"),
    img9: require("../assets/images/logo/lloyd-9.png"),
    img10: require("../assets/images/logo/lloyd-10.png"),
    img11: require("../assets/images/logo/lloyd-11.jpg"),
    img12: require("../assets/images/logo/lloyd-12.jpg"),
    img13: require("../assets/images/logo/lloyd-13.jpg"),
    img14: require("../assets/images/logo/lloyd-14.jpg"),
    img15: require("../assets/images/logo/lloyd-15.jpg"),
    img16: require("../assets/images/logo/lloyd-16.jpg"),
  },
  {
    id: 11,
    route: "north-hill-on-garland-branding-print-design",
    title: "North Hill on Garland",
    heading: "NORTH HILL ON GARLAND",
    para: "Brand identity and print design for a neighborhood bar and restaurant.",
    img1: require("../assets/images/logo/north-1.jpg"),
    img2: require("../assets/images/logo/north-2.jpg"),
    img3: require("../assets/images/logo/north-3.jpg"),
    img4: require("../assets/images/logo/north-4.jpg"),
    img5: require("../assets/images/logo/north-5.jpg"),
    img6: require("../assets/images/logo/north-6.jpg"),
    img7: require("../assets/images/logo/north-7.jpg"),
  },
  {
    id: 12,
    route: "elite-construction",
    title: "Elite Construction",
    heading: "Elite Construction",
    para: "",
    img1: require("../assets/images/logo/elite-1.jpg"),
    img2: require("../assets/images/logo/elite-2.jpg"),
    img3: require("../assets/images/logo/elite-3.jpg"),
    img4: require("../assets/images/logo/elite-4.jpg"),
    img5: require("../assets/images/logo/elite-5.jpg"),
    img6: require("../assets/images/logo/elite-6.jpg"),
    img7: require("../assets/images/logo/elite-7.jpg"),
    img8: require("../assets/images/logo/elite-8.jpg"),
    img9: require("../assets/images/logo/elite-9.jpg"),
    img10: require("../assets/images/logo/elite-10.jpg"),
    img11: require("../assets/images/logo/elite-11.jpg"),
  },
  {
    id: 13,
    route: "cult-cannabis",
    title: "Cult. Cannabis",
    heading: "Cult. Cannabis",
    para: "",
    img1: require("../assets/images/logo/cult-1.png"),
    img2: require("../assets/images/logo/cult-2.png"),
    img3: require("../assets/images/logo/cult-3.png"),
    img4: require("../assets/images/logo/cult-4.png"),
    img5: require("../assets/images/logo/cult-5.png"),
    img6: require("../assets/images/logo/cult-6.png"),
    img7: require("../assets/images/logo/cult-7.png"),
    img8: require("../assets/images/logo/cult-8.png"),
    img9: require("../assets/images/logo/cult-9.png"),
    img10: require("../assets/images/logo/cult-10.png"),
    img11: require("../assets/images/logo/cult-11.png"),
    img12: require("../assets/images/logo/cult-12.png"),
    img13: require("../assets/images/logo/cult-13.png"),
    img14: require("../assets/images/logo/cult-14.png"),
    img15: require("../assets/images/logo/cult-15.png"),
    img16: require("../assets/images/logo/cult-16.png"),
  },
  {
    id: 14,
    route: "the-tea-boba-bar-branding-print-design",
    title: "The Tea Boba Bar",
    heading: "The Tea Boba Bar",
    para: "Brand identity and apparel design for Boba Bar.",
    img1: require("../assets/images/logo/tea-1.png"),
    img2: require("../assets/images/logo/tea-2.png"),
    img3: require("../assets/images/logo/tea-3.png"),
    img4: require("../assets/images/logo/tea-4.png"),
    img5: require("../assets/images/logo/tea-5.png"),
    img6: require("../assets/images/logo/tea-6.png"),
    img7: require("../assets/images/logo/tea-7.png"),
    img8: require("../assets/images/logo/tea-8.png"),
    img9: require("../assets/images/logo/tea-9.png"),
    img10: require("../assets/images/logo/tea-10.png"),
    img11: require("../assets/images/logo/tea-11.png"),
    img12: require("../assets/images/logo/tea-12.png"),
    img13: require("../assets/images/logo/tea-13.png"),
    img14: require("../assets/images/logo/tea-14.png"),
    img15: require("../assets/images/logo/tea-15.png"),
    img16: require("../assets/images/logo/tea-16.png"),
  },
  {
    id: 15,
    route: "strohmaier-design-build",
    title: "Strohmaier Design & Build",
    heading: "Strohmaier Design & Build",
    para: "",
    img1: require("../assets/images/logo/build-1.png"),
    img2: require("../assets/images/logo/build-2.png"),
    img3: require("../assets/images/logo/build-3.png"),
    img4: require("../assets/images/logo/build-4.png"),
    img5: require("../assets/images/logo/build-5.png"),
    img6: require("../assets/images/logo/build-6.png"),
    img7: require("../assets/images/logo/build-7.png"),
    img8: require("../assets/images/logo/build-8.png"),
    img9: require("../assets/images/logo/build-9.png"),
    img10: require("../assets/images/logo/build-10.png"),
  },
  {
    id: 16,
    route: "aborder-products-inc",
    title: "Aborder Products, Inc.",
    heading: "Aborder Products, Inc.",
    para: "Aborder Products, Inc., a company that creates high quality home, kitchen, and pet goods, approached us with a need for a new brand identity. They were looking to stand out in a competitive market and wanted to appeal to a modern, design-conscious audience. Through a collaborative process, we helped them refine their brand visuals and created a sleek visual identity that reflects the quality and affordability of their products.",
    img1: require("../assets/images/logo/product-1.png"),
    img2: require("../assets/images/logo/product-1.png"),
    img3: require("../assets/images/logo/product-1.png"),
    img4: require("../assets/images/logo/product-1.png"),
    img5: require("../assets/images/logo/product-1.png"),
    img6: require("../assets/images/logo/product-1.png"),
    img7: require("../assets/images/logo/product-1.png"),
    img8: require("../assets/images/logo/product-1.png"),
    img9: require("../assets/images/logo/product-1.png"),
  },
  {
    id: 17,
    route: "equinox-gardens",
    title: "Equinox Gardens",
    heading: "EQUINOX GARDENS",
    para: "Equinox Gardens approached Propaganda looking for a rebrand and packaging. Using the sun & moon for equinox and solstice imagery, we’ve developed a brand identity and versatile logomark with the sun facing upwards for sativa, down for indica, and in the middle for hybrid strains. Find your balance.",
    img1: require("../assets/images/logo/garden-1.png"),
    img2: require("../assets/images/logo/garden-2.png"),
    img3: require("../assets/images/logo/garden-3.png"),
    img4: require("../assets/images/logo/garden-4.png"),
    img5: require("../assets/images/logo/garden-5.png"),
    img6: require("../assets/images/logo/garden-6.png"),
    img7: require("../assets/images/logo/garden-7.png"),
    img8: require("../assets/images/logo/garden-8.png"),
    img9: require("../assets/images/logo/garden-9.png"),
    img10: require("../assets/images/logo/garden-10.png"),
    img11: require("../assets/images/logo/garden-11.png"),
    img12: require("../assets/images/logo/garden-12.png"),
    img13: require("../assets/images/logo/garden-13.png"),
    img14: require("../assets/images/logo/garden-14.png"),
    img15: require("../assets/images/logo/garden-15.png"),
    img16: require("../assets/images/logo/garden-16.png"),
    img17: require("../assets/images/logo/garden-17.png"),
    img18: require("../assets/images/logo/garden-18.png"),
    img19: require("../assets/images/logo/garden-19.png"),
    img20: require("../assets/images/logo/garden-20.png"),
    img21: require("../assets/images/logo/garden-21.png"),
  },
  {
    id: 18,
    route: "evergreen-estate-elder-law",
    title: "Evergreen Estate & Elder Law",
    heading: "Evergreen Estate & Elder Law",
    para: "Evergreen Estate & Elder Law’s succinct tagline communicates their mission, ‘Protect What Matters Most.’ Propaganda brought that vision to life with an updated brand, including a logo redesign and a new visual system. This was used to establish a fresh corporate identity, compelling advertising, and a user-friendly, responsive website, elevating Evergreen’s overall image and solidifying its place as a top choice for trusted legal advice.",
    img1: require("../assets/images/logo/law-1.png"),
    img2: require("../assets/images/logo/law-2.png"),
    img3: require("../assets/images/logo/law-3.png"),
    img4: require("../assets/images/logo/law-4.png"),
    img5: require("../assets/images/logo/law-5.png"),
    img6: require("../assets/images/logo/law-6.png"),
    img7: require("../assets/images/logo/law-7.png"),
    img8: require("../assets/images/logo/law-8.png"),
    img9: require("../assets/images/logo/law-9.png"),
    img10: require("../assets/images/logo/law-10.png"),
    img11: require("../assets/images/logo/law-11.png"),
    img12: require("../assets/images/logo/law-12.png"),
    img13: require("../assets/images/logo/law-13.png"),
    img14: require("../assets/images/logo/law-14.png"),
    img15: require("../assets/images/logo/law-15.png"),
    img16: require("../assets/images/logo/law-16.png"),
    img17: require("../assets/images/logo/law-17.png"),
    img18: require("../assets/images/logo/law-18.png"),
    img19: require("../assets/images/logo/law-19.png"),
  },
];

export const LogoMain = [
  {
    route: "leftover-toy",
    img: require("../assets/images/logo/toy-main.webp"),
    heading: "Leftover Toys",
  },
  {
    route: "peak-health",
    img: require("../assets/images/logo/peak-main.webp"),
    heading: "Peek Health",
  },
  {
    route: "hoopfest-2022",
    img: require("../assets/images/logo/fest-main.webp"),
    heading: "Hoopfest 2022",
  },
  {
    route: "the-accompaniment-company",
    img: require("../assets/images/logo/company-main.webp"),
    heading: "The Accompaniment Company",
  },
  {
    route: "highball-speakeasy",
    img: require("../assets/images/logo/ball-main.webp"),
    heading: "Highball Speakeasy",
  },
  {
    route: "high-caliber-whiskey",
    img: require("../assets/images/logo/high-main.webp"),
    heading: "High Caliber Whiskey",
  },
  {
    route: "swanky-sweets-branding-packaging-design",
    img: require("../assets/images/logo/swanky-main.webp"),
    heading: "Swanky Sweets",
  },
  {
    route: "3d-pandaz",
    img: require("../assets/images/logo/panda-main.webp"),
    heading: "3d Pandaz NFT",
  },
  {
    route: "cyrus-olearys-pies-branding-packaging-web-design",
    img: require("../assets/images/logo/cyrus-main.jpg"),
    heading: "Cyrus O’Leary’s Pies",
  },
  {
    route: "equinox-gardens",
    img: require("../assets/images/logo/garden.png"),
    heading: "Equinox Gardens",
  },
  {
    route: "north-hill-on-garland-branding-print-design",
    img: require("../assets/images/logo/north-main.webp"),
    heading: "North Hill On Garland",
  },
  {
    route: "elite-construction",
    img: require("../assets/images/logo/elite-main.webp"),
    heading: "Elite Construction",
  },
  {
    route: "cult-cannabis",
    img: require("../assets/images/logo/cult-main.webp"),
    heading: "Cult. Cannabis",
  },
  {
    route: "the-tea-boba-bar-branding-print-design",
    img: require("../assets/images/logo/tea-main.webp"),
    heading: "The Tea Boba Bar",
  },
  {
    route: "strohmaier-design-build",
    img: require("../assets/images/logo/build-main.webp"),
    heading: "Strohmaier Design & Build",
  },
  {
    route: "aborder-products-inc",
    img: require("../assets/images/logo/product-main.webp"),
    heading: "Aborder Products, Inc.",
  },
  {
    route: "lloyd-industries-packaging-print-web-design",
    img: require("../assets/images/logo/lloyd-main1.png"),
    heading: "Lloyd Industries",
  },
  {
    route: "evergreen-estate-elder-law",
    img: require("../assets/images/logo/law-main.webp"),
    heading: "Evergreen Estate & Elder Law",
  },
];

export const designfaqs = [
  {
    title: "What is the purpose of branding?",
    text: "Branding is all about crafting a one-of-a-kind identity for your products or services that sets them apart in the market. It means shaping a unique image and character that speaks to customers, making it clear what your business is all about, how it works, and what makes it special. Good branding leaves a lasting impact and forges a strong bond with your target audience.",
  },
  {
    title: "How much does it cost for a Logo Package?",
    text: "The cost of logo projects can vary depending on the specific requirements of each project. Here at METANADO, we believe in customizing our approach to suit the unique needs of your business. Our process starts with a thorough analysis and evaluation, which includes a comprehensive set of questions to determine your specific needs. Based on this assessment, we will offer you a quote that takes into consideration all the necessary components for your distinct project. As a ballpark figure, our logo packages typically start at $3500.",
  },
  {
    title: "I already have a Logo. Can you help me with Branding?",
    text: "Yes, absolutely! At METANADO, we specialize in creating a strong brand presence that aligns perfectly with your existing logo. Our services include developing brand identity, crafting messaging, and creating visual assets to help your brand stand out and connect with your audience.",
  },
  {
    title: "How to choose a branding agency?",
    text: "Picking the perfect branding agency means checking out their past work to see if it matches your industry and vibe. Make sure they get what your company stands for and what you want to achieve together. Find an agency that has a history of getting things done and really gets what makes your business special.",
  },
];

export const webfaqs = [
  {
    title: "How do I select a web design agency?",
    text: "Select a web design agency that truly understands your brand's vision and has a solid portfolio highlighting creativity and user-focused design. ",
  },
  {
    title: "What is the cost of web design services?",
    text: "The cost of web design is determined by project complexity, desired features, and client requirements. We provide customized solutions that are both cost-effective and high-quality. ",
  },
];

export const brandfaqs = [
  {
    title: "What does a Digital Marketing Agency do?",
    text: "Each agency has its own unique approach; we take pride in being a comprehensive agency. This means we handle everything! From Ads Creative and Search Engine Optimization (SEO) to Social Media and Google Ads Management. We're here to collaborate with your business and achieve top-notch results.",
  },
  {
    title: "What Digital Marketing channels do you work with?",
    text: "We focus on managing Paid Ads through Google Ads, YouTube, Facebook & Instagram (Meta). With these platforms, we can assist you in connecting with your desired audience, boosting conversions, and meeting your marketing objectives using efficient and data-informed tactics.",
  },
  {
    title: "What is the difference between paid and organic marketing?  ",
    text: "Paid marketing uses paid ads like Google Ads and social media ads to quickly reach your target audience. Organic marketing, on the other hand, focuses on creating valuable content and optimizing your website to improve search engine rankings and attract traffic without direct payments",
  },
  {
    title: "How do you measure the success of digital marketing campaigns?",
    text: "We rely on combination of analytics tools and performance metrics to gauge the effectiveness of our campaigns. Key performance indicators (KPIs) like conversion rates, click-through rates, and return on investment (ROI) allow us to monitor our progress and tweak our strategies for the best outcomes.",
  },
  
];
export const DesignData = [
  {
    imageUrl: logo1,
    title: "cropped-logo-01",
    description: "Developing micro-interactions for lightning Design System",
  },
  {
    imageUrl: logo2,
    title: "gi",
    description:
      "Redesign of the support portal and 30% improvement in users’ ability to get the answers they want",
  },
  {
    imageUrl: logo3,
    title: "icreativez",
    description:
      "Designing a new onboarding flow that increased completed sessions to over 60%",
  },
  {
    imageUrl: logo4,
    title: "icreativez logo",
    description:
      "Rebranding a solution that securely routes money abroad acquired by BluLive",
  },
  {
    imageUrl: logo5,
    title: "l",
    description:
      "Brand identity for iOS AppStore's most downloaded app by Snapchat",
  },
  {
    imageUrl: logo6,
    title: "pac-image",
    description:
      "Rebranding an e-commerce solution used by 180,000 entrepreneurs",
  },
  {
    imageUrl: logo7,
    title: "pac-logo",
    description:
      "Rebranding an e-commerce solution used by 180,000 entrepreneurs",
  },
  {
    imageUrl: logo8,
    title: "wedecoratekake",
    description:
      "Rebranding an e-commerce solution used by 180,000 entrepreneurs",
  },
  {
    imageUrl: logo9,
    title: "wonderhealing",
    description:
      "Rebranding an e-commerce solution used by 180,000 entrepreneurs",
  },
  {
    imageUrl: logo10,
    title: "wonderhealing",
    description:
      "Rebranding an e-commerce solution used by 180,000 entrepreneurs",
  },
];

export const WebsiteData = [
  {
    imageUrl: web1,
    title: "Descript",
    description:
      "Website front-end and visuals for the world's first audio word processor",
  },

  {
    imageUrl: web2,
    title: "Coinread",
    description:
      "Branding, web app development for a product that helps to find crypto market information",
  },
  {
    imageUrl: web3,
    title: "Mozilla Labs",
    description: "Designing a website for innovative projects by Mozilla",
  },
  {
    imageUrl: web4,
    title: "Clause",
    description:
      "Brand identity and website for a digital contracting platform acquired by DocuSign",
  },
  {
    imageUrl: web5,
    title: "Transcend",
    description:
      "Branding and website for a data privacy company with 5M+ access & erasure requests fulfilled to date",
  },
  {
    imageUrl: web6,
    title: "Wizeline",
    description:
      "Website design for a technology company that provides software solutions",
  },
];

export const marketing = [
  {
    imageUrl: brand1,
    title: "Firefox",
    description: "Official Firefox rebrand",
  },

  {
    imageUrl: brand2,
    title: "Oppo",
    description:
      "Iconography design system for 500 million users around the world",
  },
  {
    imageUrl: brand3,
    title: "Descript",
    description: "Design for the world's first audio word processor",
  },
  {
    imageUrl: brand4,
    title: "Wyre",
    description:
      "Rebranding a solution that securely routes money abroad acquired by BluLive",
  },
  {
    imageUrl: brand5,
    title: "Bitmoji",
    description:
      "Brand identity for iOS AppStore's most downloaded app by Snapchat",
  },
  {
    imageUrl: brand6,
    title: "Volusion",
    description:
      "Rebranding an e-commerce solution used by 180,000 entrepreneurs",
  },
];

export const brandvideos = [
  {
    videoUrl: "https://youtu.be/3kziPIpUZy8",
    thumbnailUrl: require("../assets/images/thumb_1.jpg"),
  },
  {
    videoUrl: "https://youtu.be/gMuLoKl4qyo",
    thumbnailUrl: require("../assets/images/thumb_2.jpg"),
  },
  {
    videoUrl: "https://youtu.be/qEkVk_I99HI",
    thumbnailUrl: require("../assets/images/thumb_3.jpg"),
  },
  {
    videoUrl: "https://youtu.be/GlybjPXX0Xo",
    thumbnailUrl: require("../assets/images/thumb_4.jpg"),
  },
  {
    videoUrl: "https://youtu.be/GlybjPXX0Xo",
    thumbnailUrl: require("../assets/images/thumb_5.jpg"),
  },
  {
    videoUrl: "https://youtu.be/CnSyth3A-hw",
    thumbnailUrl: require("../assets/images/thumb_6.jpg"),
  },
  {
    videoUrl: "ihttps://youtu.be/IabS2eZuVto",
    thumbnailUrl: require("../assets/images/thumb_7.jpg"),
  },
  {
    videoUrl: "https://youtu.be/aDGZgzVwly4",
    thumbnailUrl: require("../assets/images/thumb_8.jpg"),
  },
  {
    videoUrl: "https://youtu.be/C-WXTPyI7xQ",
    thumbnailUrl: require("../assets/images/thumb_9.jpg"),
  },
];
