import { useState } from "react";

export default function Accordion({ data }) {
  const [curOpen, setCurOpen] = useState(null);

  return (
    <div className="w-full max-w-3xl md:mx-20 md:my-20 my-10 p-4">
      <h2 className="text-4xl font-bold">FAQ</h2>
      {data.map((el, i) => (
        <AccordionItem
          curOpen={curOpen}
          onOpen={setCurOpen}
          title={el.title}
          num={i}
          key={el.title}
        >
          {el.text}
        </AccordionItem>
      ))}
    </div>
  );
}

function AccordionItem({ num, title, curOpen, onOpen, children }) {
  const isOpen = num === curOpen;

  function handleToggle() {
    onOpen(isOpen ? null : num);
  }

  return (
    <div className={` overflow-hidden mb-4  `}>
      <hr className="mt-4" />
      <div
        className="flex items-center pt-8 cursor-pointer "
        onClick={handleToggle}
      >
        <p className="text-xl font-bold flex-grow">{title}</p>
        <p className="text-xl font-bold">{isOpen ? "-" : "+"}</p>
        <hr className="border-black" />
      </div>

      {isOpen && (
        <div className="py-4 hyphens-auto  text-2xl font-base ">{children}</div>
      )}
    </div>
  );
}
