import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Work from '../file/Work'
import ContactForm from '../file/ContactForm'
import Chat from '../file/Chat'

const Portfolio = () => {
    return (
        <div className='w-auto'>
            <Header />
            <div className='container mx-auto flex flex-col justify-center items-center md:py-32 py-28 w-[100%] overflow-hidden px-3'>
                <h2 className='font-bold xl:text-5xl lg:text-3xl text-xl  text-center my-2 mx-3 text-[#5f13f5]'>A snippet of our portfolio from our database.</h2>
                <p className='md:text-2xl text-lg text-center w-auto md:w-[80%] lg:w-[60%] text-zinc-600 md:mb-8 mb-4'>Candigitals have provided value-based services throughout the world. Over the years, we have served more than 5000+ clients throughout the world.</p>
                <Work />
            </div>

            <div className='mb-10'>
            <ContactForm/>
            </div>
            <Chat/>
            <Footer />
        </div>
    )
}

export default Portfolio
