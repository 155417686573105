import { useRef, useEffect } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import DesignCard from "../../component/DesignCard/DesignCard";
import { webfaqs } from "../../strings/Array";
import { WebsiteData } from "../../strings/Array";
import Accordion from "../../component/FAQ";
import { motion, useAnimation, useInView } from "framer-motion";
import WebsiteCard from "../../component/WebsiteCard";
import { testimonialWeb } from "../../strings/Array";
import company1 from "../../assets/company2/1582115760-logodatum.svg";
import company2 from "../../assets/company2/1582115762-logodescript.svg";
import company3 from "../../assets/company2/1582115763-logoelph-landscape.svg";
import company4 from "../../assets/company2/1582115766-logofilecoin.svg";
import company5 from "../../assets/company2/1582115767-logofinal.svg";
import company6 from "../../assets/company2/1582115768-logoflyr.svg";
import company7 from "../../assets/company2/1582115772-logohackerrank.svg";
import company8 from "../../assets/company2/1582115774-logohana-dark.svg";
import company9 from "../../assets/company2/1582115784-logoiterable.svg";
import company10 from "../../assets/company2/1582115795-logomobingi-dark.svg";
import company11 from "../../assets/company2/1582115797-logomozilla.svg";
import company12 from "../../assets/company2/1582115806-logoprotocol-labs.svg";
import company13 from "../../assets/company2/1582115824-logotruebill.svg";
import company14 from "../../assets/company2/1582115829-logowizeline-dark.svg";
import company15 from "../../assets/company2/1582115834-logoxero.svg";
import "./Website.css";

function Website() {
  const ref = useRef<HTMLDivElement>(null); // Initialize ref with null

  // Pass ref.current to useInView
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  const fadeInAnimationVarinats = {
    initial: {
      opacity: 0,
      y: 150,
    },
    animate: (index: any) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.25 * index,
        duration: 1,
      },
    }),
  };

  return (
    <div className="w-full bg-[#141414]">
      <div className="fixed top-0 z-10">
        <Header />
      </div>

      <div className="lg:container lg:mx-auto">
        <motion.div
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="text-white flex  justify-center flex-col  md:justify-start md:pt-56 md:pl-20 pt-28 pl-8"
        >
          <h2 className="font-extrabold text-6xl ">Web design</h2>
          <p className="pt-20 md:w-[75%] w-full text-2xl md:flex flex-col justify-center items-center">
            METANADO's team of web designers specialize in creating visually
            appealing websites and landing pages that not only tell your brand's
            story, but also help increase conversion rates and establish
            credibility with your target audience.
          </p>
        </motion.div>

        <motion.div
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
          className="flex flex-wrap justify-around pt-16 px-16 md:px-16" // Added horizontal padding here
        >
          {WebsiteData.map((card: any, index) => (
            <div key={index} className="w-full flex justify-center lg:w-1/3">
              <DesignCard {...card} />
            </div>
          ))}
        </motion.div>

        {/* sepate compon */}
        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="mt-8  bg-white text-[#141414] justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10 "
        >
          <h2 className="text-4xl font-bold w-full md:w-[70%]">
            Marketing website design approach
          </h2>
          <p className="text-2xl pt-8 w-full md:w-4/6 hyphens-auto">
            We believe that marketing websites play a crucial role in allowing
            brands to share their individual narratives and showcase their
            distinctive qualities. Our strategy guarantees that these websites
            convey a consistent tone that resonates with the brand's fundamental
            characteristics, successfully conveying the intended message to the
            desired audience. When crafting these websites, we integrate key
            components of brand identity to establish a unified experience that
            mirrors both the brand's present status and its future ambitions and
            strategic objectives.
          </p>
        </motion.section>

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="mt-8 bg-white text-[#141414] justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10"
        >
          <h2 className="text-4xl font-bold w-full md:w-[70%]">
            CUSTOMIZED WEB PORTAL PACKAGE
          </h2>
          <p className="text-2xl pt-8 w-full md:w-4/6 hyphens-auto">
            Our web portal development approach creates a seamless digital
            platform that reflects your brand's strategy, visual identity, and
            voice. This customized solution doesn't just boost your market
            presence, but also improves user interaction and operational
            effectiveness.
          </p>
        </motion.section>

        {/* component */}

        {/* separate component */}

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className=" text-[#141414] bg-white flex flex-col md:flex-row md:pt-32 md:pl-20 pt-10 px-10 md:justify-start   "
        >
          <div className="w-full md:w-1/3 ">
            <h3 className="text-black text-2xl mb-4 font-bold ">
              User experience
            </h3>

            <div className="items   text-2xl">- Discovery</div>
            <div className="items   text-2xl">- UI/UX audit</div>
            <div className="items  text-2xl">- Information architecture </div>
            <div className="items  text-2xl">- Copy and CTAs</div>
            <div className="items text-2xl">- Inclusive design</div>
          </div>
          <div className="w-full md:w-1/3 ">
            <h3 className="text-black mb-4 text-2xl font-bold ">
              Design & front-end
            </h3>

            <div className="items  text-2xl">
              - Visual design & interactions
            </div>
            <div className="items  text-2xl">- Front-end development</div>
            <div className="items  text-2xl">- CMS </div>
            <div className="items  text-2xl">- Iconography & illustrations</div>
            <div className="items  text-2xl">- 3rd party integrations </div>
          </div>
          <div className="w-full md:w-1/3  ">
            <h3 className="text-black mb-4 text-2xl font-bold ">
              Technology stack
            </h3>
            ow
            <div className="items  text-2xl">- Astro</div>
            <div className="items  text-2xl">- NEXT.js</div>
            <div className="items  text-2xl">- Gatsby</div>
            <div className="items  text-2xl">- Headless CMS</div>
            <div className="items  text-2xl">- Webflow</div>
          </div>
        </motion.section>

        {/* component */}
        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className=" bg-white text-[#141414]  justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10"
        >
          <h2 className="text-4xl font-bold">Additional services</h2>
          <p className="text-2xl  pt-8 w-full md:w-2/3 text-justify hyphens-auto">
            we offer a wide range of specialized services aimed at providing
            outstanding value, promoting growth, and enhancing user experiences
            in order to build a strong digital presence.
          </p>
        </motion.section>

        {/* componnent */}

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="md:pl-20 px-10 mt-8 flex flex-col md:flex-row justify-start md:justify-center"
        >
          <div className="w-full md:w-1/3 md:mx-4">
            <WebsiteCard
              title="Branding"
              description="Collaborate with our experts to create a unique brand identity that builds trust and enhances visibility."
            />
          </div>
          <div className="w-full md:w-1/3 md:mx-4">
            <WebsiteCard
              title="Website Design & Development  "
              description="Create websites that prioritize the user experience, build brand trust, and deliver meaningful outcomes."
            />
          </div>
          <div className="w-full md:w-1/3 md:mx-4">
            <WebsiteCard
              title="Logo Design"
              description="Design logos that connect with your audience and embody the heart of your brand's narrative and principles."
            />
          </div>
        </motion.section>

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="md:pl-20 pl-12 mt-8 flex flex-col md:flex-row justify-start md:justify-center"
        >
          <div className="w-full md:w-1/3 md:mx-4">
            <WebsiteCard
              title="Marketing Strategies"
              description="Create customized marketing strategies to boost brand awareness, engage with specific customer segments, and drive company expansion."
            />
          </div>
          <div className="w-full md:w-1/3 md:mx-4">
            <WebsiteCard
              title="Consulting Services"
              description="Get expert advice and tailored recommendations to enhance your online strategies and reach sustainable growth."
            />
          </div>
          <div className="w-full md:w-1/3 md:mx-4">
            <WebsiteCard
              title="Creative Campaigns"
              description="Start Exciting Campaigns that enthrall your audience and efficiently get your brand's message across different channels."
            />
          </div>
        </motion.section>

        {/* component */}

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className=" bg-white text-[#141414]  justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10"
        >
          <h2 className="text-4xl font-bold">Why METANADO</h2>
          <p className="text-2xl  pt-8 w-full md:w-4/6 hyphens-auto">
            At METANADO, we blend innovative design with strategic
            functionality, guaranteeing that every project combines creativity
            with practicality. Our dedicated team excels in transforming your
            vision into compelling digital experiences that prioritize both
            brand authenticity and user satisfaction. With a proven history of
            delivering customized, top-tier digital solutions, we customize our
            services to elevate your brand's online presence and achieve your
            business objectives.
          </p>
        </motion.section>

        {/* component */}

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className=" bg-white text-[#141414]  justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10"
        >
          <h2 className="text-4xl font-bold">Website design clients</h2>
          <p className="text-2xl  pt-8 w-full md:w-2/3 text-justify hyphens-auto">
            Recognized brands and emerging startups with the backing of top VC
            firms across the globe.
          </p>
        </motion.section>

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
          className="w-full bg-gray-100 my-7 md:w-[85%] md:mx-20 md:my-12"
        >
          <div className="flex flex-wrap justify-center">
            <div className="w-full p-2 sm:w-1/2 md:w-1/4">
              <img
                src={company1}
                alt="Company Logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full p-2 sm:w-1/2 md:w-1/4">
              <img
                src={company2}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full p-2 sm:w-1/2 md:w-1/4">
              <img
                src={company3}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full p-2 sm:w-1/2 md:w-1/4">
              <img
                src={company4}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full p-2 sm:w-1/2 md:w-1/4">
              <img
                src={company5}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full p-2 sm:w-1/2 md:w-1/4">
              <img
                src={company6}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full p-2 sm:w-1/2 md:w-1/4">
              <img
                src={company7}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full p-2 sm:w-1/2 md:w-1/4">
              <img
                src={company8}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full p-2 sm:w-1/2 md:w-1/4">
              <img
                src={company9}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full p-2 sm:w-1/2 md:w-1/4">
              <img
                src={company10}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full p-2 sm:w-1/2 md:w-1/4">
              <img
                src={company11}
                alt="cafex logo "
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full p-2 sm:w-1/2 md:w-1/4">
              <img src={company12} alt="cafex logo" />
            </div>
            <div className="w-full p-2 sm:w-1/2 md:w-1/4">
              <img src={company13} alt="cafex logo" />
            </div>
            <div className="w-full p-2 sm:w-1/2 md:w-1/4">
              <img src={company14} alt="cafex logo" />
            </div>
            <div className="w-full p-2 sm:w-1/2 md:w-1/4">
              <img src={company15} alt="cafex logo" />
            </div>
          </div>
        </motion.section>

        {/* sections */}

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="text-[#141414]  flex justify-center px-8  md:justify-start md:pl-20"
        >
          <div className=" mt-20 text-lg md:text-3xl font-semibold w-full md:w-2/3">
            &ldquo; {testimonialWeb[0].para} &rdquo;
            <div className="flex flex-col md:flex-row px-2 mt-10">
              <img
                className="w-1/12   rounded-full  md:w-[8%] mb-4 md:mb-0 md:mr-4"
                alt="Chris Chen"
                src={testimonialWeb[0].image}
              />
              <div className="px-2 text-lg font-normal">
                <h3>{testimonialWeb[0].name}</h3>
                <span>{testimonialWeb[0].post}</span>
              </div>
            </div>
          </div>
        </motion.section>

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="text-[#141414]  flex justify-center px-8  md:justify-start md:pl-20"
        >
          <div className="mt-20 text-lg md:text-3xl font-semibold w-full md:w-2/3">
            &ldquo; {testimonialWeb[1].para} &rdquo;
            <div className="flex flex-col md:flex-row px-2 mt-10">
              <img
                className="w-1/12   rounded-full  md:w-[8%] mb-4 md:mb-0 md:mr-4"
                alt="Chris Chen"
                src={testimonialWeb[1].image}
              />
              <div className="px-2 text-lg font-normal">
                <h3>{testimonialWeb[1].name}</h3>
                <span>{testimonialWeb[1].post}</span>
              </div>
            </div>
          </div>
        </motion.section>
        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="text-[#141414]  flex justify-center px-8  md:justify-start md:pl-20"
        >
          <div className="mt-20 text-lg md:text-3xl font-semibold w-full md:w-2/3">
            &ldquo; {testimonialWeb[2].para} &rdquo;
            <div className="flex flex-col md:flex-row px-2 mt-10">
              <img
                className="w-1/12   rounded-full  md:w-[8%] mb-4 md:mb-0 md:mr-4"
                alt="Chris Chen"
                src={testimonialWeb[2].image}
              />
              <div className="px-2 text-lg font-normal">
                <h3>{testimonialWeb[2].name}</h3>
                <span>{testimonialWeb[2].post}</span>
              </div>
            </div>
          </div>
        </motion.section>
        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="text-[#141414]  flex justify-center px-8  md:justify-start md:pl-20"
        >
          <div className="mt-20 text-lg md:text-3xl font-semibold w-full md:w-2/3">
            &ldquo; {testimonialWeb[3].para} &rdquo;
            <div className="flex flex-col md:flex-row px-2 mt-10">
              <img
                className="w-1/12 rounded-full  md:w-[8%]  mb-4 md:mb-0 md:mr-4"
                alt="Chris Chen"
                src={testimonialWeb[3].image}
              />
              <div className="px-2 text-lg font-normal">
                <h3>{testimonialWeb[3].name}</h3>
                <span>{testimonialWeb[3].post}</span>
              </div>
            </div>
          </div>
        </motion.section>

        {/* FAQ */}
        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="flex  bg-white text-[#141414]"
        >
          <Accordion data={webfaqs} />
        </motion.section>
      </div>

      <section>
        <Footer />
      </section>
    </div>
  );
}

export default Website;
