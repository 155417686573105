import React, { useRef, useEffect } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

function WebsiteCard(props: any) {
  const ref = useRef<HTMLDivElement>(null); // Initialize ref with null

  // Pass ref.current to useInView
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  const fadeInAnimationVarinats = {
    initial: {
      opacity: 0,
      y: 150,
    },
    animate: (index: any) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.25 * index,
        duration: 1,
      },
    }),
  };
  return (
    <motion.section
      variants={fadeInAnimationVarinats}
      initial="initial"
      whileInView="animate"
      viewport={{
        once: true,
      }}
    >
      <div className="w-[80%]">
        <h2 className="underline text-black font-bold text-xl">
          {props.title}
        </h2>
        <p className="text-2xl">{props.description}</p>
      </div>
    </motion.section>
  );
}

export default WebsiteCard;
