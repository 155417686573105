import React, { useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { TiTick } from "react-icons/ti";
import { FaArrowRight, FaCheckCircle } from "react-icons/fa";
import Design from "../file/Design";
import Chat from "../file/Chat";
import { useNavigate } from "react-router-dom";
import Contact from "./Contact";
import { CONTACT } from "../strings/CONTACT";

const Pricing = () => {
  const [activeCategory, setActiveCategory] = useState("logo");
  const priceTb1Ref: any = useRef(null);
  const navigate = useNavigate();

  const handleTabClick = (id: any) => {
    setActiveCategory(id);
  };

  const scrollToPriceTb1 = () => {
    if (priceTb1Ref.current) {
      priceTb1Ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div className="w-full">
      <Header />
      <div className="main-banner logo-bnr-sec serv-bnr pacakge-bnr-pg bg-primary">
        <div className="w-auto">
          <div className="xl:mx-40 lg:mx-20 md:mx-10 mx-6 pt-28 md:flex justify-center items-center">
            <div className="w-auto">
              <div className="bnr-text">
                <h5>Affordable Yet Effective Solutions</h5>
                <h1>
                  <span>Witness an Increase</span> in Your <br /> Business’s
                  <span className="bnr-spn-clr"> ROI</span>{" "}
                </h1>
                <h4>
                  We offer comprehensive digital marketing solutions at
                  affordable rates to take your business to great heights of
                  online success.
                </h4>
                <ul>
                  <li>
                    <i className="fa" aria-hidden="true">
                      <FaCheckCircle />
                    </i>{" "}
                    Over 12 Years of Experience
                  </li>
                  <li>
                    <i className="fa" aria-hidden="true">
                      <FaCheckCircle />
                    </i>{" "}
                    Clientele Across 45 Industries
                  </li>
                  <li>
                    <i className="fa" aria-hidden="true">
                      <FaCheckCircle />
                    </i>{" "}
                    100% Satisfaction Guaranteed
                  </li>
                  <li>
                    <i className="fa" aria-hidden="true">
                      <FaCheckCircle />
                    </i>{" "}
                    Money-Back Guarantee
                  </li>
                </ul>
                <div className="bnr-btn">
                  <a
                    onClick={scrollToPriceTb1}
                    className="get-strtd popup-btn cursor-pointer"
                  >
                    Get Started
                    <i className="fa fa-power-off" aria-hidden="true"></i>
                  </a>
                  <a
                    onClick={() => navigate("/contact-us")}
                    className="live-cht chats"
                  >
                    Live Chat{" "}
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </a>
                </div>
                <h6>
                  Implementation process is easy and completly free. Contact us
                  for further info
                </h6>
              </div>
            </div>
            <div className="w-auto">
              <div className="bnr-img-st">
                <img
                  className="packge-bnr-1"
                  src={require("../assets/images/images-package-bnr-img-1.png")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${require("../assets/home/1.jpg")})`,
        }}
        className="w-auto bg-no-repeat bg-cover my-10 text-white"
      >
        <div className="container w-auto mx-auto lg:px-48 md:px-12 px-2md:flex flex-col-reverse py-10">
          <div>
            <h1 className="font-bold text-3xl md:text-start text-center">
              Offering Affordable Website & Logo Designs for Global Audience
            </h1>
            <p className="my-6 text-lg">
              Candigitals has a variety of services to offer to its customers at
              affordable prices. We deliver bespoke design and branding
              solutions at pocket-friendly rates.
            </p>
            <div className="md:flex justify-start items-center w-auto mx-auto gap-3">
              <div className="link-area">
                <a
                  className="hover-effect cursor-pointer"
                  onClick={scrollToPriceTb1}
                >
                  GET STARTED<span className="flash-glow-effect"></span>
                </a>{" "}
              </div>
              <p className="hover:text-[#5f13f5] text-lg md:mt-0 mt-2">
                Call Us: ${CONTACT.PH_NO}
              </p>
            </div>
          </div>
          <div className="flex  justify-center items-center mb-8 ">
            <img
              src={require("../assets/images/main-logo.png")}
              alt="CTA"
              className="w-[40%] large-screen-image"
            />
          </div>
        </div>
      </div>

      <div className="pricing-sec show ">
        <div className="w-auto mx-auto xl:px-30 lg-px-20 md:px-10 px-1">
          <div className="row flex flex-col justify-center items-center">
            <div className="w-full">
              <div className="price-nav-tb flex justify-center items-center w-auto">
                <ul
                  id="nav-tabs-wrapper"
                  className="nav nav-tabs nav-tabs-horizontal md:flex text-center mx-auto w-auto justify-center items-center cursor-pointer"
                >
                  <li className={activeCategory === "logo" ? "active" : ""}>
                    <a
                      onClick={() => handleTabClick("logo")}
                      id="price_logo"
                      style={{ whiteSpace: "nowrap" }}
                      className="md:px-3 px-3 md:text-2xl text-xs"
                    >
                      {" "}
                      Logo Design
                    </a>
                  </li>
                  <li className={activeCategory === "website" ? "active" : ""}>
                    <a
                      onClick={() => handleTabClick("website")}
                      id="price_website"
                      style={{ whiteSpace: "nowrap" }}
                      className="md:px-3 px-3 md:text-2xl text-xs"
                    >
                      Website Design{" "}
                    </a>
                  </li>
                  <li className={activeCategory === "video" ? "active" : ""}>
                    <a
                      onClick={() => handleTabClick("video")}
                      id="price_video"
                      style={{ whiteSpace: "nowrap" }}
                      className="md:px-3 px-3 md:text-2xl text-xs"
                    >
                      Video Animation
                    </a>
                  </li>
                  <li className={activeCategory === "seo" ? "active" : ""}>
                    <a
                      onClick={() => handleTabClick("seo")}
                      id="price_seo"
                      style={{ whiteSpace: "nowrap" }}
                      className="md:px-3 px-3 md:text-2xl text-xs"
                    >
                      SEO Services
                    </a>
                  </li>
                  <li className={activeCategory === "design" ? "active" : ""}>
                    <a
                      onClick={() => handleTabClick("design")}
                      id="price_design"
                      style={{ whiteSpace: "nowrap" }}
                      className="md:px-3 px-3 md:text-2xl text-xs"
                    >
                      Print Design
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-12 p0" ref={priceTb1Ref}>
              <div className="tab-content">
                {activeCategory === "logo" && (
                  <div
                    role="tabpanel"
                    className="tab-pane fade in active"
                    id="price-tb1"
                  >
                    <div className="pri-text xl:px-40 lg:px-20 md:px-6 px-6">
                      <h4>Start Now</h4>
                      <h2>Your Free Plan Suggesion</h2>
                    </div>
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-6 xl:px-40 lg:px-20 md:px-6 px-2">
                      <div className="col-sm-4">
                        <div className="price-main">
                          <div className="price-main-box">
                            <div className="pri-ht-main">
                              <h3>Special Logo</h3>
                            </div>
                            <div className="price-rupee-main">
                              <h3 className="text-small md:text-lg">$49.00</h3>
                            </div>
                          </div>
                          <div className="pric-list">
                            <ul>
                              <li>2 Custom Logo Design Concepts</li>
                              <li>1 Dedicated Designer</li>
                              <li>3 Revisions</li>
                              <li>24 - 48 Hours Turn Around Time</li>
                              <li>FREE Icon Design (JPEG Formats)</li>
                              <li>100% Unique Design Guarantee</li>
                              <li>100% Satisfaction Guarantee</li>
                              <li>100% Money Back Guarantee *</li>
                            </ul>
                          </div>
                          <div className="price-btn">
                            <a
                              className="pri-btn popup-btn"
                              onClick={() => navigate("/contact-us")}
                            >
                              Get Started
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              View Details
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="pri-text price-txt-2"></div>
                        <div className="price-main">
                          <div className="price-main-box">
                            <div className="pri-ht-main">
                              <h3>Basic Logo</h3>
                            </div>
                            <div className="price-rupee-main">
                              <h3>$99.99</h3>
                            </div>
                          </div>
                          <div className="pric-list">
                            <ul>
                              <li>4 Custom Logo Design Concepts</li>
                              <li>By 2 Designers</li>
                              <li>Unlimited Revisions</li>
                              <li>24 to 48 Hours Turn Around Time</li>
                              <li>File Formats: Ai,Jpeg</li>
                              <li>100% Satisfaction Guarantee</li>
                              <li>100% Unique Design Guarantee</li>
                              <li>100% Money Back Guarantee*</li>
                            </ul>
                          </div>
                          <div className="price-btn">
                            <a
                              className="pri-btn popup-btn"
                              onClick={() => navigate("/contact-us")}
                            >
                              Get Started
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              View Details
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="pri-text price-txt-2"></div>
                        <div className="price-main">
                          <div className="price-main-box ">
                            <div className="pri-ht-main">
                              <h3 className="hyphens-auto">
                                Professional Logo
                              </h3>
                            </div>
                            <div className="price-rupee-main">
                              <h3>$169.99</h3>
                            </div>
                          </div>
                          <div className="pric-list">
                            <ul>
                              <li>8 Logo Design Concepts</li>
                              <li>By 4 Industry Based Designers</li>
                              <li>UNLIMITED Revisions</li>
                              <li>
                                Stationary Design (Business Card, Letterhead,
                                Envelope Design )
                              </li>
                              <li>24 to 48 Hours Turn Around Time</li>
                              <li>File Formats (EPS, Ai, GIF, JPEG, PSD)</li>
                              <li>Dedicated Account Manager</li>
                              <li>100% Satisfaction Guarantee</li>
                              <li>100% Unique Design Guarantee</li>
                              <li>100% Money Back Guarantee</li>
                            </ul>
                          </div>
                          <div className="price-btn">
                            <a
                              className="pri-btn popup-btn"
                              onClick={() => navigate("/contact-us")}
                            >
                              Get Started
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              View Details
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                      {/* <div className="col-sm-12 p0"> */}
                      <div className="col-sm-4">
                        <div className="pri-text price-txt-2"></div>
                        <div className="price-main">
                          <div className="price-main-box">
                            <div className="pri-ht-main">
                              <h3>Corporate Logo</h3>
                            </div>
                            <div className="price-rupee-main">
                              <h3>$249.99</h3>
                            </div>
                          </div>
                          <div className="pric-list">
                            <ul>
                              <li>Unlimited Logo Design Concepts</li>
                              <li>By 6 Designers</li>
                              <li>Unlimited Revisions</li>
                              <li>FREE Icon Design</li>
                              <li>24 - 48 Hours TurnAround Time</li>
                              <li>
                                FREE 1 Stationery Design Set (Business Cards,
                                Letterhead, Envelope &amp; Email Signature)
                              </li>
                              <li>File Formats: Ai,Eps,Png,Psd,Jpeg,Tiff</li>
                              <li>
                                100% Satisfaction With Unlimited Revisions
                              </li>
                              <li>100% Money Back Guarantee*</li>
                            </ul>
                          </div>
                          <div className="price-btn">
                            <a
                              className="pri-btn popup-btn"
                              onClick={() => navigate("/contact-us")}
                            >
                              Get Started
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              View Details
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="pri-text price-txt-2"></div>
                        <div className="price-main">
                          <div className="price-main-box">
                            <div className="pri-ht-main">
                              <h3>Elite Logo</h3>
                            </div>
                            <div className="price-rupee-main">
                              <h3>$399.99</h3>
                            </div>
                          </div>
                          <div className="pric-list">
                            <ul>
                              <li>3 Logo Design Concepts</li>
                              <li>By 2 Award Winning Logo Designers</li>
                              <li>3 Revisions</li>
                              <li>File Formats: Ai,Eps,Png,Psd,Jpeg,Tiff</li>
                              <li>3 Page Static Website</li>
                              <li>1 Year Domain Name</li>
                              <li>1 Year Web Hosting</li>
                              <li>Search Engine Friendly HTML</li>
                              <li>Unlimited Revisions on Complete Package</li>
                              <li>48 to 72 Hours TurnAround Time</li>
                              <li>Dedicated Account Manager</li>
                              <li>100% Satisfaction Guarantee</li>
                              <li>100% Unique Design Guarantee</li>
                              <li>100% Money Back Guarantee</li>
                            </ul>
                          </div>
                          <div className="price-btn">
                            <a
                              className="pri-btn popup-btn"
                              onClick={() => navigate("/contact-us")}
                            >
                              Get Started
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              View Details
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="pri-text price-txt-2"></div>
                        <div className="price-main">
                          <div className="price-main-box">
                            <div className="pri-ht-main">
                              <h3>Mascot Logo</h3>
                            </div>
                            <div className="price-rupee-main">
                              <h3>$499.99</h3>
                            </div>
                          </div>
                          <div className="pric-list">
                            <ul>
                              <li>(1) Mascot Logo</li>
                              <li>Hand Drawn Sketch</li>
                              <li>UNLIMITED Revisions</li>
                              <li>2 Stationary Design Sets</li>
                              <li>Presentation On Mockup</li>
                              <li>
                                Stationery Design (Business Card, Letterhead,
                                Envelope, Email Signature)
                              </li>
                              <li>
                                (2) Days' Time Delivery (Initial 72 hours)
                              </li>
                              <li>Revision Time Delivery (24 hours)</li>
                              <li>
                                All Final Files Format (AI, PSD, EPS, PNG, GIF,
                                JPG, PDF)
                              </li>
                              <li>100% Satisfaction Guarantee</li>
                              <li>100% Unique Design Guarantee</li>
                              <li>100% Money Back Guarantee *</li>
                            </ul>
                          </div>
                          <div className="price-btn">
                            <a
                              className="pri-btn popup-btn"
                              onClick={() => navigate("/contact-us")}
                            >
                              Get Started
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              View Details
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                      <div className="col-sm-4">
                        <div className="pri-text price-txt-2"></div>
                        <div className="price-main">
                          <div className="price-main-box">
                            <div className="pri-ht-main">
                              <h3>3D Animated Logo</h3>
                            </div>
                            <div className="price-rupee-main">
                              <h3>$599.99</h3>
                            </div>
                          </div>
                          <div className="pric-list">
                            <ul>
                              <li>4 Unique Logo Concept</li>
                              <li>UNLIMITED Revisions For Logo</li>
                              <li>1 Logo Animation (After Logo Fonalising)</li>
                              <li>Light Effects and VFX</li>
                              <li>Fully Rendered</li>
                              <li>Multiple 3D Angles</li>
                              <li>Presentation On Mockup</li>
                              <li>Dedicated Account Manager</li>
                              <li>
                                (48 - 72) Hours Turn Around Time (Animated
                                Initials)
                              </li>
                              <li>Revision Time Delivery (24 hours)</li>
                              <li>100% Satisfaction Guarantee</li>
                              <li>100% Unique Design Guarantee</li>
                              <li>100% Money Back Guarantee *</li>
                            </ul>
                          </div>
                          <div className="price-btn">
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              Get Started
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              View Details
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Design />
                  </div>
                )}
                {activeCategory === "website" && (
                  <div
                    role="tabpanel"
                    className="tab-pane fade in lg:px-40 md:px-6 flex flex-col"
                    id="price-tb2"
                  >
                    <div className="pri-text">
                      <h4>Start Now</h4>
                      <h2>Your Free Plan Suggesion</h2>
                    </div>
                    <div className="grid md:grid-cols-2 lg:grid-cols-3  grid-cols-1 p0 gap-6">
                      <div className="col-sm-4">
                        <div className="price-main">
                          <div className="price-main-box">
                            <div className="pri-ht-main">
                              <h3>Basic Web</h3>
                            </div>
                            <div className="price-rupee-main">
                              <h3>$219</h3>
                            </div>
                          </div>
                          <div className="pric-list">
                            <ul>
                              <li>2 Stock Images</li>
                              <li>3 Page Website</li>
                              <li>1 jQuery Slider Banner</li>
                              <li>Contact/Query Form</li>
                              <li>Complete W3C Certified HTML</li>
                              <li>48 to 72 hours TAT</li>
                              <li>Complete Deployment</li>
                              <li>100% Satisfaction Guarantee</li>
                              <li>100% Unique Design Guarantee</li>
                              <li>100% Money Back Guarantee *</li>
                            </ul>
                          </div>
                          <div className="price-btn gap-3">
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              Get Started
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              View Details
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="pri-text price-txt-2"></div>
                        <div className="price-main">
                          <div className="price-main-box">
                            <div className="pri-ht-main">
                              <h3>Startup Web</h3>
                            </div>
                            <div className="price-rupee-main">
                              <h3>$419</h3>
                            </div>
                          </div>
                          <div className="pric-list">
                            <ul>
                              <li>5 Stock Photos</li>
                              <li>5 Page Website</li>
                              <li>3 Banner Design</li>
                              <li>1 jQuery Slider Banner</li>
                              <li>FREE Google Friendly Sitemap</li>
                              <li>Complete W3C Certified HTML</li>
                              <li>48 to 72 hours TAT</li>
                              <li>100% Satisfaction Guarantee</li>
                              <li>100% Unique Design Guarantee</li>
                              <li>100% Money Back Guarantee *</li>
                              <li>
                                Mobile Responsive will be Additional $200*
                              </li>
                              <li>CMS will be Additional</li>
                            </ul>
                          </div>
                          <div className="price-btn">
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              Get Started
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              View Details
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="pri-text price-txt-2"></div>
                        <div className="price-main">
                          <div className="price-main-box">
                            <div className="pri-ht-main">
                              <h3>Professional Web</h3>
                            </div>
                            <div className="price-rupee-main">
                              <h3>$819</h3>
                            </div>
                          </div>
                          <div className="pric-list">
                            <ul>
                              <li>10 Unique Pages Website</li>
                              <li>CMS / Admin Panel Support</li>
                              <li>8 Stock images</li>
                              <li>5 Banner Designs</li>
                              <li>1 jQuery Slider Banner</li>
                              <li>FREE Google Friendly Sitemap</li>
                              <li>Complete W3C Certified HTML</li>
                              <li>48 to 72 hours TAT</li>
                              <li>Complete Deployment</li>
                              <li>100% Satisfaction Guarantee</li>
                              <li>100% Unique Design Guarantee</li>
                              <li>100% Money Back Guarantee *</li>
                              <li>Mobile Responsive will be Additional</li>
                            </ul>
                          </div>
                          <div className="price-btn">
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              Get Started
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              View Details
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-3 grid-cols-1 p0">
                      <div className="col-sm-4">
                        <div className="pri-text price-txt-2"></div>
                        <div className="price-main">
                          <div className="price-main-box">
                            <div className="pri-ht-main">
                              <h3>Identity Web</h3>
                            </div>
                            <div className="price-rupee-main">
                              <h3>$1399</h3>
                            </div>
                          </div>
                          <div className="pric-list">
                            <ul>
                              <li>Upto 15 Unique Pages Website</li>
                              <li>Conceptual and Dynamic Website</li>
                              <li>Mobile Responsive</li>
                              <li>
                                Online Reservation/Appointment Tool (Optional)
                              </li>
                              <li>Online Payment Integration (Optional)</li>
                              <li>Custom Forms</li>
                              <li>Lead Capturing Forms (Optional)</li>
                              <li>Striking Hover Effects</li>
                              <li>Newsletter Subscription (Optional)</li>
                              <li>Newsfeed Integration</li>
                              <li>Social Media Integration</li>
                              <li>Search Engine Submission</li>
                              <li>5 Stock Photos</li>
                              <li>3 Unique Banner Design</li>
                              <li>1 jQuery Slider Banner</li>
                              <li>Complete W3C Certified HTML</li>
                              <li>48 to 72 hours TAT</li>
                              <li>Complete Deployment</li>
                              <li>100% Satisfaction Guarantee</li>
                              <li>100% Unique Design Guarantee</li>
                              <li>100% Money Back Guarantee *</li>
                            </ul>
                          </div>
                          <div className="price-btn">
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              Get Started
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              View Details
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="pri-text price-txt-2"></div>
                        <div className="price-main">
                          <div className="price-main-box">
                            <div className="pri-ht-main">
                              <h3>Elite Web</h3>
                            </div>
                            <div className="price-rupee-main">
                              <h3>$1799</h3>
                            </div>
                          </div>
                          <div className="pric-list">
                            <ul>
                              <li>15 to 20 Pages Website</li>
                              <li>
                                Custom Made, Interactive, Dynamic &amp; High End
                                Design
                              </li>
                              <li>Custom WP (or) Custom PHP Development</li>
                              <li>1 jQuery Slider Banner</li>
                              <li>Up to 10 Custom Made Banner Designs</li>
                              <li>10 Stock Images</li>
                              <li>Unlimited Revisions</li>
                              <li>Special Hoover Effects</li>
                              <li>Content Management System (CMS)</li>
                              <li>
                                Online Appointment/Scheduling/Online Ordering
                                Integration (Optional)
                              </li>
                              <li>Online Payment Integration (Optional)</li>
                              <li>Multi Lingual (Optional)</li>
                              <li>Custom Dynamic Forms (Optional)</li>
                              <li>
                                Signup Area (For Newsletters, Offers etc.)
                              </li>
                              <li>Search Bar</li>
                              <li>
                                Live Feeds of Social Networks integration
                                (Optional)
                              </li>
                              <li>Mobile Responsive</li>
                              <li>FREE 5 Years Domain Name</li>
                              <li>Free Google Friendly Sitemap</li>
                              <li>Search Engine Submission</li>
                              <li>Complete W3C Certified HTML</li>
                              <li>
                                Industry Specified Team of Expert Designers and
                                Developers
                              </li>
                              <li>Complete Deployment</li>
                              <li>Dedicated Accounts Manager</li>
                              <li>100% Ownership Rights</li>
                              <li>100% Satisfaction Guarantee</li>
                              <li>100% Unique Design Guarantee</li>
                              <li>100% Money Back Guarantee *</li>
                            </ul>
                          </div>
                          <div className="price-btn">
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              Get Started
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              View Details
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="pri-text price-txt-2"></div>
                        <div className="price-main">
                          <div className="price-main-box">
                            <div className="pri-ht-main">
                              <h3>Business Web</h3>
                            </div>
                            <div className="price-rupee-main">
                              <h3>$2999</h3>
                            </div>
                          </div>
                          <div className="pric-list">
                            <ul>
                              <li>15 Seconds 2D Explainer Video</li>
                              <li>Voice - Over &amp; Sound Effects</li>
                              <li>Professional Script Writing</li>
                              <li>Storyboard</li>
                              <li>SEO Meta Tags</li>
                              <li>15 to 20 Pages Website</li>
                              <li>
                                Custom Made, Interactive, Dynamic &amp; High End
                                Design
                              </li>
                              <li>Custom WP (or) Custom PHP Development</li>
                              <li>1 jQuery Slider Banner</li>
                              <li>Up to 10 Custom Made Banner Designs</li>
                              <li>10 Stock Images</li>
                              <li>Unlimited Revisions</li>
                              <li>Special Hoover Effects</li>
                              <li>Content Management System (CMS)</li>
                              <li>
                                Online Appointment/Scheduling/Online Ordering
                                Integration (Optional)
                              </li>
                              <li>Online Payment Integration (Optional)</li>
                              <li>Multi Lingual (Optional)</li>
                              <li>Custom Dynamic Forms (Optional)</li>
                              <li>
                                Signup Area (For Newsletters, Offers etc.)
                              </li>
                              <li>Search Bar</li>
                              <li>
                                Live Feeds of Social Networks integration
                                (Optional)
                              </li>
                              <li>Mobile Responsive</li>
                              <li>FREE 5 Years Domain Name</li>
                              <li>Free Google Friendly Sitemap</li>
                              <li>Search Engine Submission</li>
                              <li>Complete W3C Certified HTML</li>
                              <li>
                                Industry Specified Team of Expert Designers and
                                Developers
                              </li>
                              <li>Complete Deployment</li>
                              <li>Dedicated Accounts Manager</li>
                              <li>100% Ownership Rights</li>
                              <li>100% Satisfaction Guarantee</li>
                              <li>100% Unique Design Guarantee</li>
                              <li>100% Money Back Guarantee *</li>
                            </ul>
                          </div>
                          <div className="price-btn">
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              Get Started
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                            <a
                              onClick={() => navigate("/contact-us")}
                              className="pri-btn popup-btn"
                            >
                              View Details
                              <img
                                src={require("../assets/images/btn-arrow-1.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container mx-auto w-auto  ">
                      <div className="bg-[#F2F2F2] rounded-md mb-10">
                        <div className="py-10">
                          <div className="md:flex w-[100%]">
                            <div className="flex flex-col md:w-[76%]">
                              <div className="bg-[#5f13f5] w-[100%] text-white p-6">
                                <h2 className="text-3xl font-semibold">
                                  WEB PACKAGE
                                </h2>
                                <p>CUSTOMIZED WEB PORTAL</p>
                              </div>
                              <div className="lg:flex cmb-list-st">
                                <ul className="cmb-ul-st-1">
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Complete Custom Design &amp; Development
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Dating Portal, Job Portal, Professional
                                    Network Portal, Social Network Portal,
                                    Restaurant Portal, Medical Portal,
                                    Enterprise Portal (Any One)
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Unique, User Friendly, Interactive, Dynamic,
                                    High End UI Design
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Unlimited Banner Designs
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Interactive Sliding Banners
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Special Hover Effects
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Unlimited Stock Images
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    User Signup Area
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Client/User Dashboard Area
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Custom Coding and Development
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Content Management System (Custom)
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Online Appointment/Scheduling/Online
                                    Ordering Integration (Optional)
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Online Payment Integration (Optional)
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Multi Lingual (Optional)
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Custom Dynamic Forms (Optional)
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Shopping Cart Integration (Optional)
                                  </li>
                                </ul>
                                <ul className="cmb-ul-st-2">
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Complete Database Creation
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Signup Automated Email Authentication
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Web Traffic Analytics Integration
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    3rd Party API Integrations
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Signup Area (For Newsletters, Offers etc.)
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Search Bar for Easy Search
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Live Feeds of Social Networks integration
                                    (Optional)
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Search Engine Submission
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Module-wise Architecture
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Extensive Admin Pane
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Award Winning Team of Expert Designers and
                                    Developers
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Complete Deployment
                                  </li>
                                  <li className="heading">
                                    – Value Added Services
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Complete Source Files
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    Dedicated Project Manager
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    100% Ownership Rights
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    100% Satisfaction Guarantee
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    >
                                      <TiTick size={25} />
                                    </i>{" "}
                                    100% Money Back Guarantee
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div className="md:w-[20%]">
                              <img
                                src={require("../assets/images/seal-img.png")}
                                alt="Seal"
                                className="w-auto md:ml-auto md:mx-0 mx-auto"
                              />
                              <div className="flex flex-col lg:items-center md:items-start text-center justify-center items-center my-3 ">
                                <h1 className="font-bold text-2xl text-primary">
                                  -$4330
                                </h1>
                                <p>Final Price for Limited Time</p>
                                <h2 className="text-3xl font-bold text-[#5f13f5]">
                                  $1299
                                </h2>
                                <div className="bg-white rounded-3xl flex justify-center items-center my-3">
                                  <button className="text-xl font-bold text-primary py-3 px-2">
                                    Start Project Now
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeCategory === "video" && (
                  <div
                    role="tabpanel"
                    className="tab-pane fade in lg:px-40 md:px-6"
                    id="price-tb3"
                  >
                    <div className="col-sm-12 p0 w-auto">
                      <div className="pri-text">
                        <h4>Start Now</h4>
                        <h2>Your Free Plan Suggesion</h2>
                      </div>
                      <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-6">
                        <div className="col-sm-4">
                          <div className="price-main">
                            <div className="price-main-box">
                              <div className="pri-ht-main">
                                <h3>
                                  The Executive <br />
                                  Video
                                </h3>
                              </div>
                              <div className="price-rupee-main">
                                <h3>$599</h3>
                              </div>
                            </div>
                            <div className="pric-list">
                              <ul>
                                <li>
                                  30 Second Video (Yes, you can extend your
                                  duration!)
                                </li>
                                <li>Whiteboard Video / Motion Graphics</li>
                                <li>Professional Script</li>
                                <li>Custom Artwork; No Stolen Images</li>
                                <li>Storyboard</li>
                                <li>Unlimited Storyboard Revisions</li>
                                <li>Exotic Animations</li>
                                <li>HD Video Production</li>
                                <li>
                                  Professional Voice – Over &amp; Sound Effects
                                </li>
                                <li>4 weeks Delivery</li>
                                <li> Dedicated Account Executive</li>
                                <li>100% Satisfaction Guarantee</li>
                                <li>100% Money Back Guarantee</li>
                                <li>100% Unique Design Guarantee</li>
                              </ul>
                            </div>
                            <div className="price-btn">
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                Get Started
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>{" "}
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                View Details
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="pri-text price-txt-2"></div>
                          <div className="price-main">
                            <div className="price-main-box">
                              <div className="pri-ht-main">
                                <h3>
                                  2D Advance
                                  <br /> Video
                                </h3>
                              </div>
                              <div className="price-rupee-main">
                                <h3>$1099</h3>
                              </div>
                            </div>
                            <div className="pric-list">
                              <ul>
                                <li>
                                  30 Second Video (Yes, You Can Extend Your
                                  Duration!)
                                </li>
                                <li>2D Animation/Cut-out Video/Screen Cast</li>
                                <li>Custom Artwork; No Stolen Images</li>
                                <li>Initial Script Writing</li>
                                <li>Custom Design</li>
                                <li>Storyboard</li>
                                <li>Unlimited Storyboard Revisions</li>
                                <li>Exotic Animations</li>
                                <li>
                                  Professional Voice – Over &amp; Sound Effects
                                </li>
                                <li>5 weeks Delivery</li>
                                <li>Dedicated Account Manager</li>
                                <li>100% Satisfaction Guarantee</li>
                                <li>100% Money Back Guarantee</li>
                                <li>100% Unique Design Guarantee</li>
                              </ul>
                            </div>
                            <div className="price-btn">
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                Get Started
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>{" "}
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                View Details
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="pri-text price-txt-2"></div>
                          <div className="price-main">
                            <div className="price-main-box">
                              <div className="pri-ht-main">
                                <h3>
                                  2D Elite
                                  <br /> Video{" "}
                                </h3>
                              </div>
                              <div className="price-rupee-main">
                                <h3>$1499</h3>
                              </div>
                            </div>
                            <div className="pric-list">
                              <ul>
                                <li>
                                  30 Second Video (Yes, You Can Extend Your
                                  Duration!)
                                </li>
                                <li>2 Character Illustration / Music Videos</li>
                                <li>Custom Artwork; No Stolen Images</li>
                                <li>Initial Script Writing</li>
                                <li>Custom Design</li>
                                <li>Storyboard</li>
                                <li>Unlimited Storyboard Revisions</li>
                                <li>Exotic Animations</li>
                                <li>
                                  Professional Voice – Over &amp; Sound Effects
                                </li>
                                <li>5 weeks Delivery</li>
                                <li>Dedicated Account Manager</li>
                                <li>100% Satisfaction Guarantee</li>
                                <li>100% Money Back Guarantee</li>
                                <li>100% Unique Design Guarantee</li>
                              </ul>
                            </div>
                            <div className="price-btn">
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                Get Started
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>{" "}
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                View Details
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12 p0">
                          <div className="pri-text"></div>
                          <div className="col-sm-4">
                            <div className="price-main">
                              <div className="price-main-box">
                                <div className="pri-ht-main">
                                  <h3>
                                    3D Boss
                                    <br /> Video
                                  </h3>
                                </div>
                                <div className="price-rupee-main">
                                  <h3>$3399</h3>
                                </div>
                              </div>
                              <div className="pric-list">
                                <ul>
                                  <li>
                                    30 Second Video (Yes, You Can Extend Your
                                    Duration!)
                                  </li>
                                  <li>Custom Artwork; No Stolen Images</li>
                                  <li>Initial Script Writing</li>
                                  <li>Custom Design</li>
                                  <li>Storyboard</li>
                                  <li>Unlimited Storyboard Revisions</li>
                                  <li>Exotic Animations</li>
                                  <li>
                                    Professional Voice – Over &amp; Sound
                                    Effects
                                  </li>
                                  <li>5 weeks Delivery</li>
                                  <li>Dedicated Account Manager</li>
                                  <li>100% Satisfaction Guarantee</li>
                                  <li>100% Money Back Guarantee</li>
                                  <li>100% Unique Design Guarantee</li>
                                </ul>
                              </div>
                              <div className="price-btn">
                                <a
                                  onClick={() => navigate("/contact-us")}
                                  className="pri-btn popup-btn"
                                >
                                  Get Started
                                  <img
                                    src={require("../assets/images/btn-arrow-1.png")}
                                  />
                                </a>{" "}
                                <a
                                  onClick={() => navigate("/contact-us")}
                                  className="pri-btn popup-btn"
                                >
                                  View Details
                                  <img
                                    src={require("../assets/images/btn-arrow-1.png")}
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeCategory === "seo" && (
                  <div
                    role="tabpanel"
                    className="tab-pane fade in lg:px-40 md:px-6"
                    id="price-tb4"
                  >
                    <div className="col-sm-12 p0">
                      <div className="pri-text">
                        <h4>Start Now</h4>
                        <h2>Your Free Plan Suggesion</h2>
                      </div>
                      <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-6">
                        <div className="col-sm-4">
                          <div className="price-main">
                            <div className="price-main-box">
                              <div className="pri-ht-main">
                                <h3>StartUP SEO</h3>
                              </div>
                              <div className="price-rupee-main">
                                <h3>$1099</h3>
                              </div>
                            </div>
                            <div className="pric-list">
                              <ul>
                                <li>
                                  <b>Initial Review &amp; Analysis</b>
                                </li>
                                <li>Website Analysis</li>
                                <li>Business Analysis</li>
                                <li>Consumer Analysis</li>
                                <li>Competitor Analysis</li>
                                <li>Google Penalty Check</li>
                                <li>15 Selected Keywords Targeting</li>
                                <li>10 Pages Keyword Targeted</li>
                                <li>
                                  <b>Webpage Optimization</b>
                                </li>
                                <li>Meta Tags Creation</li>
                                <li>Keyword Optimization</li>
                                <li>Image Optimization</li>
                                <li>Inclusion of anchors</li>
                                <li>
                                  <b>Tracking &amp; Analysis</b>
                                </li>
                                <li>Google Analytics Installation</li>
                                <li>Google Webmaster Installation</li>
                                <li>Call To Action Plan</li>
                                <li>Creation of Sitemaps</li>
                                <li>
                                  <b>Reporting</b>
                                </li>
                                <li>Monthly Reporting </li>
                                <li>Email Support </li>
                                <li>Phone Support</li>
                                <li>
                                  <b>Off Page Optimization</b>
                                </li>
                                <li>Social Bookmarking</li>
                                <li>Slide Share Marketing </li>
                                <li>Link Building</li>
                                <li>Directory Submission</li>
                                <li>Forums/FAQ’s </li>
                                <li>Link Building </li>
                                <li>Directory Submission </li>
                                <li>Local Business Listings</li>
                              </ul>
                            </div>
                            <div className="price-btn">
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                Get Started
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                View Details
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="pri-text price-txt-2"></div>
                          <div className="price-main">
                            <div className="price-main-box">
                              <div className="pri-ht-main">
                                <h3>Elite SEO</h3>
                              </div>
                              <div className="price-rupee-main">
                                <h3>$1799</h3>
                              </div>
                            </div>
                            <div className="pric-list">
                              <ul>
                                <li>
                                  <b>Initial Review &amp; Analysis</b>
                                </li>
                                <li>Website Analysis</li>
                                <li>Business Analysis</li>
                                <li>Consumer Analysis</li>
                                <li>Competitor Analysis</li>
                                <li>Google Penalty Check</li>
                                <li>20 Selected Keywords Targeting </li>
                                <li>15 Pages Keyword Targeted</li>
                                <li>
                                  <b>Webpage Optimization </b>
                                </li>
                                <li>Meta Tags Creation</li>
                                <li>Keyword Optimization </li>
                                <li>Image Optimization </li>
                                <li>Inclusion of anchors</li>
                                <li>
                                  <b>Tracking &amp; Analysis </b>
                                </li>
                                <li>Google Places Inclusions</li>
                                <li>Google Analytics Installation</li>
                                <li>Google Webmaster Installation </li>
                                <li>Creation of Sitemaps</li>
                                <li>
                                  <b>Reporting </b>
                                </li>
                                <li>Monthly Reporting </li>
                                <li>Email Support </li>
                                <li>Phone Support</li>
                                <li>
                                  <b>Off Page Optimization</b>
                                </li>
                                <li>Social Bookmarking</li>
                                <li>Slide Share Marketing </li>
                                <li>Forums/FAQ’s </li>
                                <li>Link Building </li>
                                <li>Directory Submission </li>
                                <li>Local Business Listings</li>
                              </ul>
                            </div>
                            <div className="price-btn">
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                Get Started
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                View Details
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="pri-text price-txt-2"></div>
                          <div className="price-main">
                            <div className="price-main-box">
                              <div className="pri-ht-main">
                                <h3>Professional SEO</h3>
                              </div>
                              <div className="price-rupee-main">
                                <h3>$2999</h3>
                              </div>
                            </div>
                            <div className="pric-list">
                              <ul>
                                <li>
                                  <b>Initial Review &amp; Analysis</b>
                                </li>
                                <li>Website Analysis</li>
                                <li>Business Analysis</li>
                                <li>Consumer Analysis</li>
                                <li>Competitor Analysis</li>
                                <li>Google Penalty Check</li>
                                <li>Guaranteed Google Ranking</li>
                                <li>70 Selected Keywords Targeting </li>
                                <li>50 Pages Keyword Targeted</li>
                                <li>
                                  <b>Webpage Optimization </b>
                                </li>
                                <li>Meta Tags Creation</li>
                                <li>Keyword Optimization </li>
                                <li>Image Optimization </li>
                                <li>Inclusion of anchors Tags </li>
                                <li>
                                  Inclusion of anchors Indexing Modifications
                                </li>
                                <li>
                                  <b>Tracking &amp; Analysis </b>
                                </li>
                                <li>Google Places Inclusions</li>
                                <li>Google Analytics Installation</li>
                                <li>Google Webmaster Installation </li>
                                <li>Call To Action Plan </li>
                                <li>Creation of Sitemaps</li>
                                <li>
                                  <b>Reporting </b>
                                </li>
                                <li>Monthly Reporting </li>
                                <li>Recommendation </li>
                                <li>Email Support </li>
                                <li>Phone Support</li>
                                <li>
                                  <b>Off Page Optimization </b>
                                </li>
                                <li>Social Bookmarking</li>
                                <li>Slide Share Marketing </li>
                                <li>Forums/FAQ’s </li>
                                <li>Link Building </li>
                                <li>Directory Submission </li>
                                <li>Local Business Listings</li>
                                <li>Blog Content Creation</li>
                                <li>Local SEO</li>
                              </ul>
                            </div>
                            <div className="price-btn">
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                Get Started
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                View Details
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeCategory === "design" && (
                  <div
                    role="tabpanel"
                    className="tab-pane fade in lg:px-40"
                    id="price-tb5"
                  >
                    <div className="col-sm-12 p0">
                      <div className="pri-text">
                        <h4>Start Now</h4>
                        <h2>Your Free Plan Suggesion</h2>
                      </div>
                      <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-6">
                        <div className="col-sm-4">
                          <div className="price-main">
                            <div className="price-main-box">
                              <div className="pri-ht-main">
                                <h3>
                                  Startup <br />
                                  Collateral
                                </h3>
                              </div>
                              <div className="price-rupee-main">
                                <h3>$99</h3>
                              </div>
                            </div>
                            <div className="pric-list">
                              <ul>
                                <li>2 Stationery Design Set</li>
                                <li>FREE Fax Template</li>
                                <li>Print Ready Formats</li>
                                <li>UNLIMITED Revisions</li>
                                <li>100% Satisfaction Guarantee</li>
                                <li>100% Money Back Guarantee *</li>
                              </ul>
                            </div>
                            <div className="price-btn">
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                Get Started
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>{" "}
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                View Details
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="pri-text price-txt-2"></div>
                          <div className="price-main">
                            <div className="price-main-box">
                              <div className="pri-ht-main">
                                <h3>
                                  Classic <br />
                                  Collateral
                                </h3>
                              </div>
                              <div className="price-rupee-main">
                                <h3>$199</h3>
                              </div>
                            </div>
                            <div className="pric-list">
                              <ul>
                                <li>2 Stationery Design Set</li>
                                <li>UNLIMITED Revisions</li>
                                <li>Flyer Design</li>
                                <li>Brochure Design (Bi-fold/Tri-fold)</li>
                                <li>100% Satisfaction Guarantee</li>
                                <li>100% Money Back Guarantee *</li>
                              </ul>
                            </div>
                            <div className="price-btn">
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                Get Started
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>{" "}
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                View Details
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="pri-text price-txt-2"></div>
                          <div className="price-main">
                            <div className="price-main-box">
                              <div className="pri-ht-main">
                                <h3>
                                  Premium <br />
                                  Collateral
                                </h3>
                              </div>
                              <div className="price-rupee-main">
                                <h3>$399</h3>
                              </div>
                            </div>
                            <div className="pric-list">
                              <ul>
                                <li>2 Stationery Design Set</li>
                                <li>Packaging Design</li>
                                <li>UNLIMITED Revisions</li>
                                <li>T-Shirt Design</li>
                                <li>100% Satisfaction Guarantee</li>
                                <li>100% Money Back Guarantee *</li>
                              </ul>
                            </div>
                            <div className="price-btn">
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                Get Started
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>{" "}
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                View Details
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="pri-text price-txt-2"></div>
                          <div className="price-main">
                            <div className="price-main-box">
                              <div className="pri-ht-main">
                                <h3>
                                  Unlimited <br />
                                  Collateral
                                </h3>
                              </div>
                              <div className="price-rupee-main">
                                <h3>$499</h3>
                              </div>
                            </div>
                            <div className="pric-list">
                              <ul>
                                <li>2 Stationery Design Set</li>
                                <li>Menu Card Design</li>
                                <li>T-Shirt Design</li>
                                <li>1 Banner Design</li>
                                <li>100% Satisfaction Guarantee</li>
                                <li>100% Money Back Guarantee *</li>
                              </ul>
                            </div>
                            <div className="price-btn">
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                Get Started
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>{" "}
                              <a
                                onClick={() => navigate("/contact-us")}
                                className="pri-btn popup-btn"
                              >
                                View Details
                                <img
                                  src={require("../assets/images/btn-arrow-1.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Chat />

      <Footer />
    </div>
  );
};

export default Pricing;
