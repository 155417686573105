import React from "react";
import { TiTick } from "react-icons/ti";
import { FaArrowRight } from "react-icons/fa6";
import seal from "../assets/images/seal-img.png";
import { useNavigate } from "react-router-dom";

const Design = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="container mx-auto w-auto 2xl:px-40 xl:px-12 lg:px-20 md:px-10 px-6 ">
        <div className="bg-[#F2F2F2] rounded-md mb-10">
          <div className="py-10">
            <div className="md:flex w-[100%]">
              <div className="flex flex-col md:w-[76%]">
                <div className="bg-logo w-[100%] text-white p-6">
                  <h1 className="text-3xl font-semibold">
                    ULTIMATE <span className="font-bold">DESIGN</span> PACKAGE
                  </h1>
                  <p>SPECIAL ONLINE LOGO DESIGN OFFER OF THE MONTH!</p>
                </div>
                <div className="lg:flex">
                  <div className="flex flex-col">
                    <div className="flex flex-col px-10 py-3">
                      <div className="flex items-center text-logo gap-1">
                        <FaArrowRight size={20} />
                        <h1 className="text-xl font-semibold text-black">
                          Logo Design
                        </h1>
                      </div>
                      <div className="flex flex-col mx-3 my-3">
                        <div className="flex items-center gap-1 text-logo">
                          <TiTick size={25} />
                          <h1 className="text-black">
                            Unlimited Logo Design Concepts
                          </h1>
                        </div>
                        <div className="flex items-center gap-1 text-logo">
                          <TiTick size={25} />
                          <h1 className="text-black">
                            4 Dedicated Logo Designers
                          </h1>
                        </div>
                        <div className="flex items-center gap-1 text-logo">
                          <TiTick size={25} />
                          <h1 className="text-black">Unlimited Revisions</h1>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col px-10 py-3">
                      <div className="flex items-center text-logo gap-1">
                        <FaArrowRight size={20} />
                        <h1 className="text-xl font-semibold text-black">
                          Website Design
                        </h1>
                      </div>
                      <div className="flex flex-col mx-3 my-3">
                        <div className="flex items-center gap-1 text-logo">
                          <TiTick size={25} />
                          <h1 className="text-black">
                            {" "}
                            10 Unique Page Custom Website
                          </h1>
                        </div>
                        <div className="flex items-center gap-1 text-logo">
                          <TiTick size={25} />
                          <h1 className="text-black"> Unlimited Revisions</h1>
                        </div>
                        <div className="flex items-center gap-1 text-logo">
                          <TiTick size={25} />
                          <h1 className="text-black">
                            Content Management System
                          </h1>
                        </div>
                        <div className="flex items-center gap-1 text-logo">
                          <TiTick size={25} />
                          <h1 className="text-black">
                            Complete W3C Certified HTML
                          </h1>
                        </div>
                        <div className="flex items-center gap-1 text-logo">
                          <TiTick size={25} />
                          <h1 className="text-black">Responsive Design</h1>
                        </div>
                        <div className="flex items-center gap-1 text-logo">
                          <TiTick size={25} />
                          <h1 className="text-black">01 Year Web Hosting</h1>
                        </div>
                        <div className="flex items-center gap-1 text-logo">
                          <TiTick size={25} />
                          <h1 className="text-black">10 Stock Photos</h1>
                        </div>
                        <div className="flex items-center gap-1 text-logo">
                          <TiTick size={25} />
                          <h1 className="text-black">Email Addresses</h1>
                        </div>
                        <div className="flex items-center gap-1 text-logo">
                          <TiTick size={25} />
                          <h1 className="text-black">
                            Google Friendly Sitemap
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <div className="flex flex-col px-10 py-3">
                      <div className="flex items-center text-logo gap-1">
                        <FaArrowRight size={20} />
                        <h1 className="text-xl font-semibold text-black">
                          Landing Page
                        </h1>
                      </div>
                      <div className="flex flex-col mx-3 my-3">
                        <div className="flex items-center gap-1 text-logo">
                          <TiTick size={25} />
                          <h1 className="text-black">
                            1 Customized Landing Page
                          </h1>
                        </div>
                        <div className="flex items-center gap-1 text-logo">
                          <TiTick size={25} />
                          <h1 className="text-black">Design</h1>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col px-10 pt-3">
                      <div className="flex items-center text-logo gap-1">
                        <FaArrowRight size={20} />
                        <h1 className="text-xl font-semibold text-black">
                          {" "}
                          Social Media Pages
                        </h1>
                      </div>
                      <div className="flex flex-col mx-3 my-3">
                        <div className="flex items-center gap-1 text-logo">
                          <TiTick size={25} />
                          <h1 className="text-black">
                            Facebook, Youtube & Twitter
                          </h1>
                        </div>
                        <div className="flex items-center gap-1 text-logo">
                          <TiTick size={25} />
                          <h1 className="text-black">Pages Design</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-[20%]">
                <img
                  src={seal}
                  alt="Seal"
                  className="w-auto md:ml-auto md:mx-0 mx-auto"
                />
                <div className="flex flex-col lg:items-center md:items-start text-center justify-center items-center my-3 ">
                  <h1 className="font-bold text-2xl text-primary">-$4330</h1>
                  <p>Final Price for Limited Time</p>
                  <h2 className="text-3xl font-bold text-logo">$1299</h2>
                  <div className="bg-white rounded-3xl flex justify-center items-center my-3">
                    <button
                      className="text-xl font-bold text-primary py-3 px-6 "
                      onClick={() => navigate("/contact-us")}
                    >
                      Start Project Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Design;
