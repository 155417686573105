import { useRef, useEffect } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import DesignCard from "../../component/DesignCard/DesignCard";
import { marketing, brandfaqs } from "../../strings/Array";
import Accordion from "../../component/FAQ";
import { motion, useAnimation, useInView } from "framer-motion";
import { testimonialBrand } from "../../strings/Array";
import company1 from "../../assets/company3/1582115752-logobitmoji.svg";
import company2 from "../../assets/company3/1582115753-logocafex.svg";
import company3 from "../../assets/company3/1582115754-logocellebrite.svg";
import company4 from "../../assets/company3/1582115762-logodescript.svg";
import company5 from "../../assets/company3/1582115768-logoflyr.svg";
import company6 from "../../assets/company3/1582115790-logokyber-network.svg";
import company7 from "../../assets/company3/1582115792-logomark-logic.svg";
import company8 from "../../assets/company3/1582115797-logomozilla.svg";
import company9 from "../../assets/company3/1582115800-logonetflix.svg";
import company10 from "../../assets/company3/1582115801-logoopera.svg";
import company11 from "../../assets/company3/1582115802-logooppo.svg";
import company12 from "../../assets/company3/1582115805-logoosmo.svg";
import company13 from "../../assets/company3/1628084534-truebill.svg";
import company14 from "../../assets/company3/1582115826-logouniversal-music.svg";
import company15 from "../../assets/company3/1582115827-logovolusion.svg";
import company16 from "../../assets/company3/1582115832-logowyre.svg";
import "./Digital_marketing.css";

export default function Digital_marketing() {
  const ref = useRef<HTMLDivElement>(null); // Initialize ref with null

  // Pass ref.current to useInView
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  const fadeInAnimationVarinats = {
    initial: {
      opacity: 0,
      y: 150,
    },
    animate: (index: any) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.25 * index,
        duration: 1,
      },
    }),
  };

  const fadeInAnimationVarinas = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: (index: any) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.25 * index,
        duration: 1,
      },
    }),
  };

  const fadeInAnimationVarinat = {
    initial: {
      scale: 0,
      rotate: 90,
    },
    animate: (index: any) => ({
      rotate: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
        duration: 0.5,
      },
    }),
  };

  return (
    <div className="w-full bg-[#141414]">
      <div className="fixed top-0 z-10">
        <Header />
      </div>

      <div className="lg:container lg:mx-auto">
        <motion.div
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="text-white flex  justify-center flex-col  md:justify-start md:pt-56 md:pl-20 pt-28 px-8"
        >
          <h2 className="font-extrabold text-4xl md:text-6xl">Paid Marketing</h2>
          <p className="md:pt-20 pt-8 w-full md:w-[75%] text-2xl md:flex flex-col justify-center items-center">
            Boost your growth with METANADO's specialized paid marketing plans. We design captivating ad campaigns on various channels to boost traffic and increase conversions quickly.
          </p>

          <ul className="text-2xl pt-8 w-full md:w-4/6 hyphens-auto list-disc ml-5">
            <li><h2 className="text-4xl font-bold w-full md:w-[70%]">
              Google Ads:
            </h2> 
            <br />
            Attract focused traffic using pay-per-click advertisements on search engines and partner platforms.
            <br /> <br />
            </li>
            <li><h2 className="text-4xl font-bold w-full md:w-[70%]">
              Social Media Ads:
            </h2> 
            <br />
            Connect with your audience using focused advertisements on social media platforms such as Facebook, Instagram, and LinkedIn.
            <br /> <br />
            </li>
            <li><h2 className="text-4xl font-bold w-full md:w-[70%]">
              Retargeting Campaigns:
            </h2> 
            <br />
            Encourage past visitors to interact with your brand once more to improve conversion rates.
            <br /> <br />
            </li>
            <li><h2 className="text-4xl font-bold w-full md:w-[70%]">
              Perf
              <br />
              ormance Tracking:
            </h2> Improve ad performance with detailed analytics to ensure optimal ROI.
            <br /> <br />
            </li>

          </ul>

        </motion.div>

        <motion.div
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="flex flex-wrap justify-center pt-16 pr-10"
        >
          {marketing.map((card: any, index: any) => (
            <div key={index} className="md:w-[70%] w-full flex justify-center lg:w-1/3">
              <DesignCard {...card} />
            </div>
          ))}
        </motion.div>

        {/* com */}

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="mt-8  bg-white text-[#141414] justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10 "
        >
          <h2 className="text-4xl font-bold w-full md:w-[70%]">
            Organic Marketing
          </h2>
          <p className="text-2xl pt-8 w-full md:w-4/6 hyphens-auto">
            Build lasting relationships and credibility with our organic marketing services. We focus on sustainable strategies to enhance your online presence and attract organic traffic. Create strong connections and trust with our organic marketing services. We concentrate on long-term tactics to improve your online visibility and attract organic visitors.
          </p>

          <ul className="py-4 hyphens-auto text-2xl is-in-viewport md:w-[70%] w-full" >
            <li>
              <p className="pt-4 hyphens-auto">
                <strong>
                  <a href="/brand-design/">Search Engine Optimization (SEO): </a>
                </strong>
                Boost your website's presence on search engines by optimizing keywords, enhancing on-page SEO, and implementing technical SEO strategies.
              </p>
            </li>
            <li>
              <p className="pt-4 hyphens-auto">
                <strong>
                  <a href="/brand-strategy/">Content Marketing: </a>
                </strong>
                Create top-notch content that connects with your audience and boosts your website's organic traffic flow.
              </p>
            </li>
            <li>
              <p className="pt-4 hyphens-auto">
                <strong>
                  <a href="/brand-identity/">Blog Management: </a>
                </strong>
                Regularly update your blog with relevant, engaging content to improve search rankings and audience engagement.
              </p>
            </li>
            <li>
              <p className="pt-4 hyphens-auto">
                <strong>
                  <a href="/rebranding/">Link Building: </a>
                </strong>
                Boost your website's credibility through strategic link-building strategies.
              </p>
            </li>

          </ul>
        </motion.section>

        {/* component */}

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="text-black flex md:w-[68%] w-full justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10"
        >
          <h1 className="text-4xl font-bold hyphens-auto ">
            Social Media Marketing
          </h1>
          <p className="text-2xl pt-8 hyphens-auto">
            Let METANADO assist you in captivating and broadening your audience using our lively social media marketing. We manage all aspects of your social media presence to enhance your brand.
          </p>
          <ul className="py-4 hyphens-auto text-2xl is-in-viewport md:w-[70%] w-full" >
            <li>
              <p className="pt-4 hyphens-auto">
                <strong>
                  <a href="/brand-design/">Content Creation:  </a>
                </strong>
                Create engaging, tailored content for each social media platform.
              </p>
            </li>
            <li>
              <p className="pt-4 hyphens-auto">
                <strong>
                  <a href="/brand-strategy/">Social Media Management:   </a>
                </strong>
                Plan and organize posts to ensure a steady and engaged online presence.
              </p>
            </li>
            <li>
              <p className="pt-4 hyphens-auto">
                <strong>
                  <a href="/brand-identity/">Community Engagement: </a>
                </strong>
                Cultivate one-on-one interactions with your audience to foster a sense of community.
              </p>
            </li>
            <li>
              <p className="pt-4 hyphens-auto">
                <strong>Analytics and Reporting:
                  <a href="/rebranding/"></a>
                </strong>
                Track performance metrics and provide insights to refine your social media strategy.
              </p>
            </li>

          </ul>

        </motion.section>

        {/* sepate compon */}
        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="mt-8  bg-white text-[#141414] justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10 "
        >
          <h2 className="text-4xl font-bold md:w-[70%] w-full">
            Why Choose Digital Marketing at METANADO?
          </h2>
          <p className="text-2xl pt-8 w-full md:w-4/6 hyphens-auto">
            Why Choose Digital Marketing at METANADO?When you choose METANADO for digital marketing services, you're teaming up with a dedicated group focused on your brand's growth and success. We blend innovative strategies with data-driven insights to develop personalized marketing campaigns that resonate with your target audience. Whether it's through precise paid ads, sustainable organic growth tactics, or engaging social media content, our comprehensive approach ensures maximum visibility and impact. Our commitment to excellence, creativity, and measurable results makes METANADO the top choice for businesses looking to thrive in the digital landscape.
          </p>
        </motion.section>

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="mt-8  bg-white text-[#141414] justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10 "
        >
          <h2 className="text-4xl font-bold md:w-[70%] w-full">
            Get Results with METANADO
          </h2>
          <ul className="text-2xl pt-8 w-full md:w-4/6 hyphens-auto list-disc ml-5">
            <li>Enhanced Online Presence</li>
            <li>Engaged Audience</li>
            <li>Data-Driven Decisions</li>
            <li>Custom Solutions</li>
            <li>Increased Brand Loyalty</li>
            <li>Agile Adaptation</li>
          </ul>
        </motion.section>

        {/* separate component */}

        {/* <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className=" text-[#141414] bg-white flex flex-col md:flex-row md:justify-start md:pt-32 md:pl-20 pt-10 px-10"
        >
          <div className="w-full md:w-1/3 ">
            <h3 className="text-black text-2xl mb-4 font-bold ">
              Brand strategy
            </h3>

            <div className="items   text-2xl">- Brand equity audit</div>
            <div className="items  text-2xl">- Audience analysis </div>
            <div className="items  text-2xl">- Competitive review</div>
            <div className="items  text-2xl">- Strategic direction</div>
          </div>
          <div className="w-full md:w-1/3 ">
            <h3 className="text-black mb-4 text-2xl font-bold ">
              Brand identity
            </h3>

            <div className="items  text-2xl">- Visual positioning</div>
            <div className="items  text-2xl">- Visual identity</div>
            <div className="items  text-2xl">
              - system Icon & illustration guides
            </div>
            <div className="items  text-2xl">- Brand application</div>
          </div>
          <div className="w-full md:w-1/3 ">
            <h3 className="text-black mb-4 text-2xl font-bold ">
              Communication strategy
            </h3>

            <div className="items  text-2xl">- Verbal identity</div>
            <div className="items  text-2xl">- Tagline exploration</div>
            <div className="items  text-2xl">- Communication style guide</div>
          </div>
        </motion.section> */}

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className=" bg-white text-[#141414] justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10"
        >
          <h2 className="text-4xl font-bold w-full md:w-[70%]">Branding clients</h2>
          <p className="text-2xl pt-8 w-full md:w-4/6 hyphens-auto">
            Established brands and growing businesses backed by Y Combinator,
            Khosla Ventures, Accel Partners, Andreessen Horowitz and others.
          </p>
        </motion.section>

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="  w-full  bg-gray-100 my-7   md:w-[85%] md:mx-20 md:my-12   "
        >
          <div className="flex flex-wrap justify-center">
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company1}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company2}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company3}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company4}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company5}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company6}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company7}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company8}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company9}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company10}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company11}
                alt="cafex logo "
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img src={company12} alt="cafex logo" />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img src={company13} alt="cafex logo" />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img src={company14} alt="cafex logo" />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img src={company15} alt="cafex logo" />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img src={company16} alt="cafex logo" />
            </div>
          </div>
        </motion.section>

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="mt-8  bg-white text-[#141414] justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10 "
        >
          <h2 className="text-4xl font-bold md:w-[70%] w-full">
            Clients
          </h2>
          <p className="text-2xl pt-8 w-full md:w-4/6 hyphens-auto">
            At METANADO, our clients' success is our top priority. Here's what they have to say about our digital marketing expertise:

          </p>
        </motion.section>

        {/* sections */}

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="text-[#141414]  flex justify-center px-8  md:justify-start md:pl-20"
        >
          <div className=" mt-20 text-lg md:text-3xl font-semibold w-full md:w-2/3">
            &ldquo; {testimonialBrand[0].para} &rdquo;
            <div className="flex flex-col md:flex-row px-2 mt-10">
              <img
                className="w-1/12   rounded-full  md:w-[8%] mb-4 md:mb-0 md:mr-4"
                alt="Chris Chen"
                src={testimonialBrand[0].image}
              />
              <div className="px-2 text-lg font-normal">
                <h3>{testimonialBrand[0].name}</h3>
                <span>{testimonialBrand[0].post}</span>
              </div>
            </div>
          </div>
        </motion.section>

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="text-[#141414]  flex justify-center px-8  md:justify-start md:pl-20"
        >
          <div className="mt-20 text-lg md:text-3xl font-semibold w-full md:w-2/3">
            &ldquo; {testimonialBrand[1].para} &rdquo;
            <div className="flex flex-col md:flex-row px-2 mt-10">
              <img
                className="w-1/12   rounded-full  md:w-[8%] mb-4 md:mb-0 md:mr-4"
                alt="Chris Chen"
                src={testimonialBrand[1].image}
              />
              <div className="px-2 text-lg font-normal">
                <h3>{testimonialBrand[1].name}</h3>
                <span>{testimonialBrand[1].post}</span>
              </div>
            </div>
          </div>
        </motion.section>
        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="text-[#141414]  flex justify-center px-8  md:justify-start md:pl-20"
        >
          <div className="mt-20 text-lg md:text-3xl font-semibold w-full md:w-2/3">
            &ldquo; {testimonialBrand[2].para} &rdquo;
            <div className="flex flex-col md:flex-row px-2 mt-10">
              <img
                className="w-1/12   rounded-full  md:w-[8%] mb-4 md:mb-0 md:mr-4"
                alt="Chris Chen"
                src={testimonialBrand[2].image}
              />
              <div className="px-2 text-lg font-normal">
                <h3>{testimonialBrand[2].name}</h3>
                <span>{testimonialBrand[2].post}</span>
              </div>
            </div>
          </div>
        </motion.section>
        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="text-[#141414]  flex justify-center px-8  md:justify-start md:pl-20"
        >
          <div className="mt-20 text-lg md:text-3xl font-semibold w-full md:w-2/3">
            &ldquo; {testimonialBrand[3].para} &rdquo;
            <div className="flex flex-col md:flex-row px-2 mt-10">
              <img
                className="w-1/12 rounded-full  md:w-[8%]  mb-4 md:mb-0 md:mr-4"
                alt="Chris Chen"
                src={testimonialBrand[3].image}
              />
              <div className="px-2 text-lg font-normal">
                <h3>{testimonialBrand[3].name}</h3>
                <span>{testimonialBrand[3].post}</span>
              </div>
            </div>
          </div>
        </motion.section>

        {/* component */}

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="mt-8  bg-white text-[#141414] justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10"
        >
          <h2 className="text-4xl font-bold w-full md:w-[70%]">Why Ramotion?</h2>
          <p className="text-2xl  pt-8 w-full md:w-4/6 hyphens-auto">
            Ramotion is a preferred choice for branding design due to our
            specialized expertise in creating dynamic and impactful brand
            identities, especially for technology companies. We are excel in
            developing comprehensive branding solutions, encompassing brand
            design, strategy, and identity creation.
          </p>
        </motion.section>

        {/* separate component */}

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="mt-8  bg-white text-[#141414] justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10"
        >
          <h2 className="text-4xl font-bold w-full md:w-[70%]">
            Articles about branding
          </h2>
        </motion.section>

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="mt-8 text-[#141414] bg-white flex flex-col md:flex-row md:justify-start md:pt-16 md:pl-20 pt-10 px-10"
        >
          <div className="w-full md:w-4/12 ">
            <h3 className="text-black text-2xl mb-4 font-bold hover:cursor-pointer ">
              Local Branding
            </h3>

            <div className="items text-[#1414148f]  text-base ">
              Local branding offers numerous benefits for the companies. Dive
              into its definition, strategies, and examples to see how it can
              help you achieve success.
            </div>
            <p> ⏲ 12 min read</p>
          </div>
          <div className="w-full md:w-4/12   md:px-6 ">
            <h3 className="text-black mb-4 text-2xl font-bold hover:cursor-pointer ">
              Global Branding
            </h3>

            <div className="    items text-[#1414148f]  text-base ">
              Global branding is a dream for many companies. However, is it time
              for you? Consider its basics, pros, and cons to see if it fits
              your brand right now.
            </div>
            <p> ⏲ 20 min read</p>
          </div>
          <div className="w-full md:w-4/12 md:px-6">
            <h3 className="text-black mb-4 text-2xl font-bold hover:cursor-pointer ">
              Gorporate Branding
            </h3>

            <div className="items text-[#1414148f]  text-base">
              Corporate branding is a vital marketing concept that helps
              companies secure a strong position in the market. Dive into its
              essentials to make it work for you
            </div>
            <p> ⏲ 14 min read</p>
          </div>
        </motion.section>

        {/* FAQ */}
        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="flex  bg-white text-[#141414]"
        >
          <Accordion data={brandfaqs} />
        </motion.section>
      </div>

      <section>
        <Footer />
      </section>
    </div>
  );
}

