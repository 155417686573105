import React from "react";
import {
  FaBehance,
  FaDribbble,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import bs_logo from "../assets/images/main-logo.png";
import bs_logo1 from "../assets/images/payment-links.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer className="w-auto bg-primary">
        <div className="flex flex-col justify-center items-center mx-6">
          <img
            src={bs_logo}
            className="p-3 w-[50%] md:w-[15%] py-6"
            alt="logo"
          />
          <div className="text-white flex gap-3 ">
            <Link to="https://web.facebook.com/metanado?_rdc=1&_rdr">
              <h1 className="hover:text-blue-600 duration-300 transform hover:scale-100">
                <FaFacebook size={25} />
              </h1>
            </Link>

            <Link to="https://www.youtube.com/channel/UCD85u7RTpFj2OrUUdJcZ1qA">
              <h1 className="hover:text-red-500 duration-300 transform hover:scale-100">
                <FaYoutube size={25} />
              </h1>
            </Link>
            <Link to="https://www.instagram.com/metanado/?igsh=MXhvMXVmMnVobDlwMw%3D%3D">
              <h1 className="hover:text-purple-900 duration-300 transform hover:scale-100">
                <FaInstagram size={25} />
              </h1>
            </Link>
          </div>
          <div className="col-12">
            <ul className="footer-links text-white">
              <li>
                <a href="/">
                  <span className="menu-h-effect">Home</span>
                </a>
              </li>
              <li>
                <a href="logo-and-branding-identity">
                  <span className="menu-h-effect">Logo &amp; Branding</span>
                </a>
              </li>
              <li>
                <a href="web-design-services">
                  <span className="menu-h-effect">Web Design</span>
                </a>
              </li>
              <li>
                <a href="explainer-animation-videos">
                  <span className="menu-h-effect">Video Animation</span>
                </a>
              </li>
              <li>
                <a href="marketing">
                  <span className="menu-h-effect">Digital Marketing</span>
                </a>
              </li>
              <li>
                <a href="portfolio">
                  <span className="menu-h-effect">Portfolio</span>
                </a>
              </li>
              <li>
                <a href="about-us">
                  <span className="menu-h-effect">About</span>
                </a>
              </li>
              <li>
                <a href="contact-us">
                  <span className="menu-h-effect">Contact</span>
                </a>
              </li>
            </ul>
          </div>

          <div className="flex gap-3 my-6">
            <img src={bs_logo1} alt="logo" />
          </div>
        </div>

        <div className="bg-zinc-800 text-white text-sm">
          <div className="md:mx-32 md:flex justify-between g items-center p-4 text-center">
            <div>© Metanado 2024. All rights reserved</div>

            <div className="flex justify-center items-center gap-3">
              <ul className="footer-bottom-links">
                <li>
                  <Link to="/TermsAndServices">
                    <span className="menu-h-effect">Terms of Services</span>
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy">
                    <span className="menu-h-effect">Privacy Policy</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

const NavLink = ({ to, label, onClick }: any) => (
  <Link
    to={to}
    onClick={onClick}
    className={`block p-2 hover:text-green-700 transition-all duration-300 `}
  >
    <h1>{label}</h1>
  </Link>
);

export default Footer;
