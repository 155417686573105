import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import bg from "../assets/images/svg/settings.svg";
import bg1 from "../assets/images/svg/shaking-hands.svg";
import bg2 from "../assets/images/svg/investment.svg";
import bg3 from "../assets/images/svg/fast-delivery.svg";
import one from "../assets/images/svg/increase.svg";
import two from "../assets/images/svg/earn.svg";
import three from "../assets/images/svg/web.svg";
import four from "../assets/images/svg/rating-stars.svg";
import arrow from "../assets/images/arrow_1.svg";
import { FaArrowRightLong } from "react-icons/fa6";
import ReactPlayer from "react-player";
// import { Pagination ,EffectCoverflow, Autoplay } from "swiper";
import { Plan, logosData, testimonial } from "../strings/Array";
import { menu } from "../strings/Array";
import creative from "../assets/images/svg/search-engine.svg";
import creative1 from "../assets/images/svg/checking.svg";
import creative2 from "../assets/images/svg/box.svg";
import arrowup from "../assets/images/arrow-up.png";
import arrowdown from "../assets/images/arrow-down.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Design from "../file/Design";
import YourPlan from "../file/YourPlan";
import Project from "../file/Project";
import { FaRegComments } from "react-icons/fa6";
import Chat from "../file/Chat";
import WorkHome from "../file/WorkHome";
import TestimonialCard from "./TestimonialCard";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { CONTACT } from "../strings/CONTACT";

const Home = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const navigate = useNavigate();

  const [user, setUser] = useState({ buisinessname: "" });
  const [activeServices, setActiveServices] = useState(menu[0].service);

  function handleChange(e: any) {
    const name = e.target.name;
    const value = e.target.value;

    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  }

  const images = [
    require("../assets/images/logo1.png"),
    require("../assets/images/logo2.png"),
    require("../assets/images/logo3.png"),
    require("../assets/images/logo4.png"),
    require("../assets/images/logo5.png"),
    require("../assets/images/logo6.png"),
  ];

  const imagesPerSlide = 6; // Number of images to display in each slide
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Advance to the next set of images
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % (images.length - imagesPerSlide + 1)
      );
    }, 5000); // Adjust the interval duration as needed (5 seconds in this example)

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [currentIndex, images.length, imagesPerSlide]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 2,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000,
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 5,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000,
  };

  return (
    <div className="w-full">
      <div className="fixed top-0 z-10">
        <Header />
      </div>

      <div className="home-banner home-video-banner">
        <div className="banner-line">
          <div className="container position-rel-container">
            <div className="exta-div"></div>
          </div>
        </div>
        <div className="animate-box banner-bottom-left-1 onload-animate fadeIn animation-delay-5ms"></div>
        <div className="animate-box banner-bottom-left-2 onload-animate fadeIn animation-delay-10ms"></div>
        <div className="animate-box banner-bottom-left-3 onload-animate fadeIn animation-delay-15ms"></div>
        <div className="animate-box banner-bottom-left-4 onload-animate fadeIn animation-delay-20ms"></div>
        <div className="animate-box banner-bottom-left-5 onload-animate fadeIn animation-delay-25ms"></div>
        <div className="animate-box banner-bottom-right-1 onload-animate fadeIn animation-delay-5ms"></div>
        <div className="animate-box banner-bottom-right-2 onload-animate fadeIn animation-delay-10ms"></div>
        <div className="animate-box banner-bottom-right-3 onload-animate fadeIn animation-delay-15ms"></div>
        <div className="animate-box banner-bottom-right-4 onload-animate fadeIn animation-delay-20ms"></div>
        <div className="home-banner-video">
          <ReactPlayer
            url={require("../assets/videos/bg.mp4")}
            playing
            loop
            muted
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
            className="w-full h-screen"
          />
        </div>
        <div className="black-overlay"></div>
        <div className="container absolute-center">
          <div className="row">
            <div className="col-12">
              <div className="text-white text-center w-auto flex flex-col justify-center items-center">
                <h1 className="md:text-3xl lg:text-3xl xl:text-6xl text-2xl font-bold w-auto xl:mx-64 md:mx-16 mx-3">
                  Welcome To Metanado <br />
                  Your Digital Oasis Of Creativity!
                </h1>
                <p className="my-3 lg:text-2xl md:text-xl text-lg xl:mx-56 md:mx-32 mx-6">
                  Where Every Brand is Crafted with Distinction. Discover the
                  Unique Branding Your Business Deserves for Digital Eminence.
                </p>
              </div>
              <div className="md:flex gap-3 xl:w-[60%] w-auto mx-auto justify-center items-center xl:px-10 lg:px-32 md:px-32 px-6">
                <div className="home-banner-form">
                  <form
                    action="javascript:;"
                    className="lg:flex justify-center items-center"
                  >
                    <input
                      type="text"
                      name="cname"
                      className="home-banner-input lg:mb-0 mb-3"
                      placeholder="ENTER YOUR BUSINESS NAME HERE"
                      required
                      onChange={handleChange}
                    />{" "}
                    <button
                      type="submit"
                      className="home-banner-submit live-cht lg:w-[50%] w-[100%] flex justify-center items-center md:mb-2"
                    >
                      {" "}
                      <span>LET'S START</span>{" "}
                    </button>{" "}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container absolute-bottom-link">
          <div className="banner-goto-link">
            {" "}
            <a id="banner-scroll-down" rel="nofollow">
              <p className="flex justify-center items-center">
                <img src={require("../assets/images/emoji.png")} />
                Our Awesome Work Is Below
              </p>
              <div className="banner-scroll-down">
                {" "}
                <span className="arrow-down"></span>{" "}
              </div>
            </a>{" "}
          </div>
        </div>
        {/* <div className="container absolute-bottom">
          <div className="row">
              <div className=""> */}
        <div className="image-carousel-container mx-auto large-screen-img">
          <Slider {...sliderSettings}>
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Image ${index + 1}`}
                className="carousel-image w-[10%]"
              />
            ))}
          </Slider>
          {/* </div>
             </div>
             </div> */}
        </div>
      </div>

      <div className="bg-[#F2F2F2]">
        <div className="container w-auto mx-auto md:px-6 2xl:px-48 xl:px-12 lg:px-6 px-6 py-10 ">
          <div className="xl:flex gap-6 w-[100%]">
            <div className="flex flex-col justify-start md:items-start items-center md:text-start text-center pt-10 xl:w-[25%]">
              <div className="flex w-auto md:justify-start justify-center items-center">
                <img src={arrow} alt="Arrow" className="w-[15%]" />
                {/* <div className="overla"></div> */}
                <h1 className="mx-1">How we do</h1>
                <img
                  src={arrow}
                  alt="Arrow"
                  className="w-[15%] transform rotate-180"
                />
              </div>
              <h2 className="lg:text-3xl text-2xl font-bold">
                Digitally Focused Quality Design Services
              </h2>
              <p className="text-xl text-zinc-600">
                Metanado helps you create an everlasting impact on digital, we
                make sure your presence is felt at every digital platform.
              </p>
              <div className="link-area mt-6">
                <a
                  className="hover-effect cursor-pointer"
                  onClick={() => navigate("about-us")}
                >
                  ABOUT US<span className="flash-glow-effect"></span>
                </a>{" "}
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-6 pt-10 xl:w-[75%]">
              <div className="flex justify-center items-center">
                <div className="flex gap-3">
                  <img src={bg} alt="BG" className="w-[20%] h-[20%]" />
                  <div className="flex flex-col">
                    <h1 className="text-xl font-bold">Quality Services</h1>
                    <p className="text-lg">
                      We never compromise on the uniqueness of our services. We
                      are known to stand apart from the rest, and our quality is
                      the reason for our supremacy in the digital industry.
                    </p>
                    <button
                      className="flex justify-start items-center font-bold text-[#643db4] gap-2 mt-2"
                      onClick={() => navigate("about-us")}
                    >
                      discover <FaArrowRightLong />
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex justify-center items-start">
                <div className="flex gap-3">
                  <img src={bg1} alt="BG" className="w-[20%] h-[20%]" />
                  <div className="flex flex-col">
                    <h1 className="text-xl font-bold">Team Collaboration</h1>
                    <p className="text-lg">
                      Digital branding is not a one-person job; instead. It
                      requires the collaboration of multiple talented creative
                      individuals. Thankfully, we have plenty in our agency.
                    </p>
                    <button
                      className="flex justify-start items-center font-bold text-[#643db4] gap-2 mt-2"
                      onClick={() => navigate("about-us")}
                    >
                      discover <FaArrowRightLong />
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex justify-center items-center">
                <div className="flex gap-3">
                  <img src={bg2} alt="BG" className="w-[20%] h-[20%]" />
                  <div className="flex flex-col">
                    <h1 className="text-xl font-bold">ROI Focused</h1>
                    <p className="text-lg">
                      Our creative approach towards digital services guarantees
                      ROI beyond comprehension to boost the digital sale funnel.
                    </p>
                    <button
                      className="flex justify-start items-center font-bold text-[#643db4] gap-2 mt-2"
                      onClick={() => navigate("about-us")}
                    >
                      discover <FaArrowRightLong />
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex justify-center items-center">
                <div className="flex gap-3">
                  <img src={bg3} alt="BG" className="w-[20%] h-[20%]" />
                  <div className="flex flex-col">
                    <h1 className="text-xl font-bold">Quick Delivery</h1>
                    <p className="text-lg">
                      Our professional environment is enriched with hardworking
                      individuals, enabling us to nail every project in very
                      short times.
                    </p>
                    <button
                      className="flex justify-start items-center font-bold text-[#643db4] gap-2 mt-2"
                      onClick={() => navigate("about-us")}
                    >
                      discover <FaArrowRightLong />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center py-10 w-[100%] overflow-hidden px-3 bg-[#F2F2F2]">
        <div className="flex justify-center items-center mx-auto">
          <img src={arrow} alt="Arrow" className="w-[5%]" />
          <h1 className="mx-1">OUR AWESOME WORK</h1>
          <img
            src={arrow}
            alt="Arrow"
            className="w-[5%] transform rotate-180"
          />
        </div>
        <h2 className="font-bold text-3xl text-center my-2 mx-3">
          Our Custom Logo Designs Leave A Mark
        </h2>
        <p className="md:text-2xl text-lg text-center w-auto md:w-[80%] lg:w-[40%] text-zinc-600">
          The creative graphic designers at Design Cater are well-versed in
          giving every logo design a unique perspective.
        </p>
        {/* <Work /> */}
        <WorkHome />
      </div>

      <div
        style={{
          backgroundImage: `url(${require("../assets/home/1.jpg")})`,
        }}
        className="w-auto bg-no-repeat bg-cover"
      >
        <div className="container w-auto mx-auto 2xl px-48 xl:px-12 md:px-12 px-6 md:flex flex-col-reverse py-20">
          <div>
            <h1 className="font-bold text-3xl md:text-start text-center text-white">
              Offering Affordable Website & Logo Designs for Global Audience
            </h1>
            <p className="my-6 text-lg text-white">
              Candigitals has a variety of services to offer to its customers at
              affordable prices. We deliver bespoke design and branding
              solutions at pocket-friendly rates.
            </p>
            <div className="md:flex justify-start items-center w-auto mx-auto gap-3 text-white pt-7">
              <div className="link-area">
                <a
                  className="hover-effect cursor-pointer"
                  onClick={() => navigate("/contact-us")}
                >
                  GET STARTED<span className="flash-glow-effect"></span>
                </a>{" "}
              </div>
              <span className="hover:text-logo text-lg">
                Call Us: {CONTACT.PH_NO}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="small-screen-image xl:container xl:mx-auto xl:px-36 lg:px-20 md:px-4 px-2">
        <YourPlan />
      </div>

      <Design />

      {!isMobile && <Project />}

      <div className="bg-[#F1F1F1] md:mt-0 mt-10 mb-10 w-auto">
        <div className="container flex flex-col w-auto mx-auto  justify-center items-center text-center py-10 px-6">
          <div className="flex justify-center items-center 2xl:px-40 xl:px-12 lg:px-20 md:px-10 px-6 gap-3">
            <img src={arrow} alt="Arrow" className="w-[5%]" />
            <h1 className="text-sm font-bold mx-1">SERVICES WE PROVIDE</h1>
            <img
              src={arrow}
              alt="Arrow"
              className="w-[5%] transform rotate-180 "
            />
          </div>
          <h2 className="text-3xl font-bold">Grow, Stun, Conquer & Lead</h2>
          <p className="my-3 text-2xl lg:w-[50%] w-auto mx-auto">
            These are the main motives behind our every digital service. We will
            fight for your supermacy in the digital world through our creative
            design services.
          </p>
        </div>
        <div className="container w-auto mx-auto flex justify-center items-center border-2 p-3 xl:px-40 lg:px-20 md:px-10 px-6">
          {menu.map((menu, index) => {
            return (
              <div
                onClick={() => setActiveServices(menu.service)}
                className={`flex flex-col justify-center items-center gap-3 font-bold md:p-3 p-1 w-auto md:text-xl text-xs text-center ${
                  activeServices === menu.service ? "bg-white text-black" : ""
                }`}
              >
                <img
                  src={menu.image1}
                  alt="Image"
                  className="w-[30%] h-[30%]"
                />
                <h1 className="text-center">{menu.service}</h1>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col container w-auto mx-auto">
          {menu.map((menu, id) => {
            return (
              <div
                key={id}
                style={{
                  display: activeServices === menu.service ? "block" : "none",
                }}
              >
                <div className="md:flex xl:mx-40 lg:mx-20 md:mx-10 mx-6 my-10 justify-between gap-12">
                  <div className="md:w-[50%] w-auto flex flex-col  ">
                    <div className="flex gap-3">
                      <img src={arrow} alt="Arrow" className="w-[10%]" />
                      <h1 className="text-sm font-bold">{menu.title}</h1>
                    </div>
                    <h1 className="text-3xl font-extrabold">{menu.heading}</h1>
                    <h2 className="text-2xl font-light text-gray-600">
                      {menu.desc}
                    </h2>
                    <p className="text-gray-600 my-3">{menu.para}</p>
                  </div>
                  <div className="md:w-[50%] w-auto flex justify-center items-center mx-auto">
                    <img src={menu.image} alt="Image" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="bg-primary w-auto text-white my-10 border">
        <div className="container w-auto mx-auto flex flex-col  md:flex-row p-12 gap-6 2xl:px-40 xl:px-12 lg:px-20 md:px-10 px-6 justify-center items-center">
          <div className="w-auto lg:w-[40%] flex flex-col justify-center items-center text-white ">
            <div className="flex lg:justify-start justify-center items-center gap-3 text-center">
              <img src={arrow} alt="Arrow" className="w-[12%] white-image" />
              <h1>STATS</h1>
              <img
                src={arrow}
                alt="Arrow"
                className="w-[12%] white-image transform rotate-180 "
              />
            </div>
            <h2 className="md:text-3xl text-xl font-bold md:text-start text-center flex md:justify-start justify-center items-center">
              Introduce Your Brand To Digital Media!
            </h2>
            <p className="text-lg lg:text-start text-center">
              Your brand will steal the limelight from top tier names in the
              marketspace with our creative digital services.
            </p>
          </div>

          <div className="col-lg-8 flex justify-center items-center">
            <div className="row mlr-7-mob md:flex gap-16">
              <div className="col-md-3 col-6  w-auto">
                <div className="single-stat flex flex-col justify-center items-center">
                  {" "}
                  <img src={one} loading="lazy" alt="" />
                  <p className="stat-head">90%</p>
                  <p className="stat-det">Increase In ROI</p>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="single-stat flex flex-col justify-center items-center">
                  {" "}
                  <img src={two} loading="lazy" alt="" />
                  <p className="stat-head">1570+</p>
                  <p className="stat-det">Projects Done</p>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="single-stat flex flex-col justify-center items-center">
                  {" "}
                  <img src={three} loading="lazy" alt="" />
                  <p className="stat-head">200+</p>
                  <p className="stat-det">Daily Website Visits</p>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="single-stat flex flex-col justify-center items-center">
                  {" "}
                  <img src={four} loading="lazy" alt="" />
                  <p className="stat-head">1000+</p>
                  <p className="stat-det">Satisfied Customers</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section4">
        <div className="container w-auto mx-auto">
          <div className="md:flex items-cente 2xl:px-36 xl:px-20 lg:px-12 md:px-10 px-6 w-[100%]">
            <div className="col-sm-12 col-md-6 md:w-[90%] w-auto px-3">
              <div className="sec4_lef">
                <div className="px-3">
                  <h1 className="md:text-2xl text-xl font-semibold">
                    Mobile Apps For Better Business Growth
                  </h1>
                  <p>
                    Grow and expand your business with our Mobile App Solutions.
                    Our Mobile Application Services are backed by years of
                    experience, creative minds, and a bond of trust with our
                    clientele.
                  </p>
                  <div className="link-area pt-6">
                    {" "}
                    <a
                      href="javascript:void(0)"
                      className="simple-a"
                      rel="nofollow"
                    >
                      <i className="far fa-comments">
                        <FaRegComments size={25} />
                      </i>
                      Want to discuss?
                    </a>{" "}
                    <a
                      className="hover-effect cursor-pointer"
                      onClick={() => navigate("/contact-us")}
                    >
                      Let's Start<span className="flash-glow-effect"></span>
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 mt-6 md:w-[55%] justify-end items-end">
              <div className="sec4_right md:before:w-[100%] before:w-[100%] before:h-[100%] md:before:h-[100%]">
                <Slider {...settings}>
                  <div className="item item1">
                    <img
                      loading="lazy"
                      src={require("../assets/images/sec4_right_item1.webp")}
                      alt="Loading Image..."
                      className="md:h-[50%] w-[75%] h-[75%] lg:pb-10 pb-8 md:w-[55%] md:ml-2 lg:ml-3 xl:ml-4 ml-4"
                    />
                  </div>
                  <div className="item item2">
                    <img
                      loading="lazy"
                      src={require("../assets/images/sec4_right_item2.webp")}
                      alt="Loading Image..."
                      className="md:h-[50%] w-[75%] h-[75%] lg:pb-10 pb-8 md:w-[55%] md:ml-2 lg:ml-3 xl:ml-4 ml-4"
                    />
                  </div>
                  <div className="item item3">
                    <img
                      loading="lazy"
                      src={require("../assets/images/sec4_right_item3.webp")}
                      alt="Loading Image..."
                      className="md:h-[50%] w-[75%] h-[75%] lg:pb-10 pb-8 md:w-[55%] md:ml-2 lg:ml-3 xl:ml-4 ml-4"
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#F1F1F1] mb-10">
        <div className="container mx-auto flex flex-col justify-center items-center py-10 w-[100%] px-6 overflow-hidden">
          <div className="flex justify-center items-center">
            <img src={arrow} alt="Arrow" className="w-[5%]" />
            <h1 className="mx-1">OUR CREATIVE PROCESS</h1>
            <img
              src={arrow}
              alt="Arrow"
              className="w-[5%] transform rotate-180"
            />
          </div>
          <h2 className="font-bold text-3xl text-center my-2 mx-3">
            At Metanado We Make Magic Happen
          </h2>
          <p className="md:text-2xl text-lg text-start md:text-center w-auto md:[90%] lg:w-[40%] text-zinc-600">
            Our digital agency is optimized for producing exceptional
            end-results. We conquer each phase of the project and ensure that we
            deliver the best quality work possible.
          </p>
          <div className="col-12 py-6">
            <div className="work-process-img">
              {" "}
              <img
                src={require("../assets/images/logo.jpg")}
                loading="lazy"
                alt="Creative Logo Design Process"
              />{" "}
              <a
                href="https://www.youtube.com/watch?v=QXHuRxjcek8"
                data-fancybox
              >
                <div className="work-video-popup">
                  {" "}
                  <img
                    src={require("../assets/images/video-play-btn-black.png")}
                  />{" "}
                  <span className="before"></span>{" "}
                  <span className="after"></span>{" "}
                </div>
              </a>{" "}
            </div>
          </div>
          <div className="md:flex 2xl:px-40 xl:px-12 lg:mx-20 mx-6 py-10">
            <div className="flex flex-col justify-center items-center">
              <img src={creative} alt="Creative" className="w-[30%]" />
              <h1
                className="font-extrabold text-2xl"
                style={{ whiteSpace: "nowrap" }}
              >
                Initial Phase
              </h1>
              <p className="text-sm text-center">
                We listen to our clients to get the initial idea of the project.
                Then our creative teams join their head and polish it into a
                shining diamond.
              </p>
            </div>

            <img
              src={arrowup}
              alt="Arrow-UP"
              className="large-screen-image w-[20%] h-[10%]"
            />

            <div className="flex flex-col justify-center items-center md:my-0 my-3">
              <img src={creative1} alt="Creative" className="w-[30%]" />
              <h1 className="font-extrabold text-2xl">Execution</h1>
              <p className="text-sm text-center">
                We are equipped with skilled people and state-of-the-art
                rendering machines. Coming up with brilliant products is our
                habit.
              </p>
            </div>

            <img
              src={arrowdown}
              alt="Arrow-down"
              className="large-screen-image w-[20%] h-[10%]"
            />

            <div className="flex flex-col justify-center items-center">
              <img src={creative2} alt="Creative" className="w-[30%]" />
              <h1 className="font-extrabold text-2xl">Delivery</h1>
              <p className="text-sm text-center">
                On-time delivery is our best service. Our healthy work
                environment ensures that you get your project handed over to you
                ASAP.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-primary w-auto text-white my-10">
        <div className="container w-auto mx-auto md:flex justify-between items-center font-bold py-6 xl:px-40 lg:px-20 md:px-10 px-6">
          <h1 className="md:text-3xl text-xl md:text-start text-center">
            Pocket-Friendly Design. Just A Click Away!
          </h1>
          <div className="flex justify-center items-center md:mt-0 mt-3">
            <button
              className="md:text-xl p-3 border-2 border-white px-6 hover:bg-white hover:text-logo"
              onClick={() => navigate("/pricing")}
            >
              POCKET PLANS
            </button>
          </div>
        </div>
      </div>

      <div className="bg-[#F1F1F1] my-10">
        <div className="flex flex-col justify-center items-center py-10 w-[100%] px-6 overflow-hidden">
          <div className="flex justify-center items-center">
            <img src={arrow} alt="Arrow" className="w-[5%]" />
            <h1 className="mx-1">TESTIMONIAL</h1>
            <img
              src={arrow}
              alt="Arrow"
              className="w-[5%] transform rotate-180"
            />
          </div>
          <h2 className="font-bold text-3xl text-center my-2 mx-3">
            Our Services Are Cherished By All!
          </h2>
          <p className="md:text-2xl text-lg text-start md:text-center w-auto md:w-[90%] lg:w-[40%] text-zinc-600">
            We have served hundreds of brands, and look at what they have to say
            for us:
          </p>
          <div className="xl:container xl:mx-auto">
            <div className="flex flex-wrap mx-20 justify-center">
              {testimonial.map((e, index) => (
                <div key={index} className="w-full mx-4 py-8 md:w-1/3">
                  <TestimonialCard key={index} testimonial={e} />
                </div>
              ))}
            </div>
          </div>
          <div
            className="link-area my-6"
            onClick={() => navigate("/our-portfolio")}
          >
            <a className="hover-effect cursor-pointer">
              VISIT OUR PORTFOLIO<span className="flash-glow-effect"></span>
            </a>{" "}
          </div>

          <div className="md:flex justify-center items-center">
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center pt-10">
                <img src={arrow} alt="Arrow" className="w-[5%]" />
                <h1 className="mx-1">AWARDS</h1>
                <img
                  src={arrow}
                  alt="Arrow"
                  className="w-[5%] transform rotate-180"
                />
              </div>
              <h2 className="font-bold text-3xl text-center my-2 mx-3">
                Achievements & Recognition
              </h2>
              <p className="md:text-2xl text-lg text-start md:text-center w-auto md:w-[70%] text-zinc-600">
                During our amazing digital journey, we have earned massive
                respect from the fellow digital fraternity!
              </p>
            </div>
            {/* <img src={testi1} alt="Image" className='w-auto mx-auto' /> */}
          </div>
        </div>
      </div>
      <Chat />

      <Footer />
    </div>
  );
};

export default Home;
