import { useRef, useEffect } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import DesignCard from "../../component/DesignCard/DesignCard";
import { designfaqs } from "../../strings/Array";
import { DesignData } from "../../strings/Array";
import Accordion from "../../component/FAQ";
import { motion, useAnimation, useInView } from "framer-motion";
import WebsiteCard from "../../component/WebsiteCard";
import { testimonial } from "../../strings/Array";
import company1 from "../../assets/company/1582115748-logoappannie.svg";
import company2 from "../../assets/company/1582115751-logoavast.svg";
import company3 from "../../assets/company/1582115753-logocafex.svg";
import company4 from "../../assets/company/1582115760-logodatum.svg";
import company5 from "../../assets/company/1582115767-logofinal.svg";
import company6 from "../../assets/company/1582115770-logoforward.svg";
import company7 from "../../assets/company/1582115780-logohumanapi.svg";
import company8 from "../../assets/company/1582115786-logojune.svg";
import company9 from "../../assets/company/1582115797-logomozilla.svg";
import company10 from "../../assets/company/1582115802-logooppo.svg";
import company11 from "../../assets/company/1582115804-logooracle.svg";
import company12 from "../../assets/company/1582115813-logosalesforce.svg";
import company13 from "../../assets/company/1582115816-logosouthern-company.svg";
import company14 from "../../assets/company/1582115821-logotile.svg";
import company15 from "../../assets/company/1582115824-logotruebill.svg";
import company16 from "../../assets/company/1582115825-logoturo.svg";
import "./Designing.css";

function Designing() {
  const ref = useRef<HTMLDivElement>(null); // Initialize ref with null

  // Pass ref.current to useInView
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  const fadeInAnimationVarinats = {
    initial: {
      opacity: 0,
      y: 150,
    },
    animate: (index: any) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.25 * index,
        duration: 1,
      },
    }),
  };

  return (
    <div className="w-full bg-[#141414]">
      <div className="fixed top-0 z-10">
        <Header />
      </div>
      <div className="lg:container lg:mx-auto">
        <motion.div
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="text-white flex  justify-center flex-col  md:justify-start md:pt-56 md:pl-20 pt-28 pl-8"
        >
          <h2 className="font-extrabold text-6xl ">Logo Design</h2>
          <p className="pt-20 md:w-[75%] w-full text-2xl md:flex flex-col justify-center items-center">
            Your brand's logo is like the face of your business. It's what catches people's attention and sticks in their minds. Our skilled designers create logos that connect with your target market and tell the story of your brand in a special way.
          </p>
        </motion.div>

        <motion.div
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="flex flex-wrap justify-center pt-16"
        >
          {DesignData.map((card: any, index: any) => (
            <div
              key={index}
              className="md:w-[70%] w-full flex justify-center  lg:w-1/3"
            >
              <DesignCard {...card} />
            </div>
          ))}
        </motion.div>

        {/* sepate compon */}
        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="mt-8 bg-white text-[#141414] justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10"
        >
          <h2 className="text-4xl font-bold md:w-[68%] w-full">Different Logo Design Options at METANADO </h2>
          <p className="text-2xl  pt-8 md:w-2/3 w-full hyphens-auto text-[#141414] ">
            Wordmarks: METANADO specializes in creating wordmarks that visually transform your brand name, making it instantly recognizable and memorable through striking typographic design.
            Lettermarks: we specialize in creating polished and professional logos by incorporating your business's initials, which are ideal for cultivating a refined and elegant brand identity.
            Brandmarks: The brandmarks of METANADO use distinctive symbols or icons to embody your business, establishing a powerful visual connection without depending on text.
            Combination Marks: METANADO's combination marks combine text and symbols to craft versatile logo that clearly conveys your brand's identity.
            Emblems: At METANADO, our emblems combine text with symbols or icons, creating a classic and formal representation of your brand.
            <ul className="text-2xl pt-8 w-full md:w-4/6 hyphens-auto list-disc ml-5">
              <li><h2 className="text-4xl font-bold w-full md:w-[70%]">
                Wordmarks:
              </h2> METANADO specializes in creating wordmarks that visually transform your brand name, making it instantly recognizable and memorable through striking typographic design.</li>
              <br /> 
              <li><h2 className="text-4xl font-bold w-full md:w-[70%]">
                Lettermarks:
              </h2> we specialize in creating polished and professional logos by incorporating your business's initials, which are ideal for cultivating a refined and elegant brand identity.</li>
                <br /> 

                <li> <h2 className="text-4xl font-bold w-full md:w-[70%]">
                  Brandmarks:
                </h2>

                  The brandmarks of METANADO use distinctive symbols or icons to embody your business, establishing a powerful visual connection without depending on text.</li>
                <br /> 
                <li><h2 className="text-4xl font-bold w-full md:w-[70%]">
                  Combination Marks:
                </h2>  METANADO's combination marks combine text and symbols to craft versatile logo that clearly conveys your brand's identity.
                  Emblems: At METANADO, our emblems combine text with symbols or icons, creating a classic and formal representation of your brand.</li>
            </ul>
          </p>
        </motion.section>

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="mt-8 bg-white text-[#141414]  justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10"
        >
          <h2 className="text-4xl font-bold">
            Full Branding Packages
          </h2>
          <p className="text-2xl pt-8 w-full md:w-2/3 hyphens-auto">
            Are you a new business or a well-established one? We offer a full range of branding materials to enhance your visibility. Our services include creating logos, letterheads, and business cards, as well as designing branded apparel, vehicle wraps, and signage. We've got all your digital and physical branding needs covered to ensure your brand leaves a lasting impression.
            We make sure to grasp your business, target audience, and objectives through our teamwork, producing designs that are not only visually appealing but also produce tangible outcomes.

          </p>

        </motion.section>

        {/* separate component */}

        {/* <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="mt-8 text-[#141414] bg-white flex flex-col md:flex-row md:justify-start md:pt-32 md:pl-20 pt-10 px-10"
        >
          <div className="w-full md:w-1/2">
            <h3 className="text-black text-2xl mb-4 font-bold">
              Marketing & Social Assets
            </h3>

            <div className="items text-2xl">- Discovery</div>
            <div className="items text-2xl">- UI/UX audit </div>
            <div className="items text-2xl">- User interface structure</div>
          </div>
          <div className="w-full md:w-1/2">
            <h3 className="text-black mb-4 text-2xl font-bold">
              User interface design
            </h3>

            <div className="items text-2xl">
              - Visual interface & interactions
            </div>
            <div className="items text-2xl">
              - High-fidelity clickable prototypes
            </div>
            <div className="items text-2xl">
              - UI Kit based on atomic design principles
            </div>
          </div>
        </motion.section> */}

        {/* component */}
        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="mt-8  bg-white text-[#141414]  justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10"
        >
          <h2 className="text-4xl font-bold">Marketing & Social Assets </h2>
          <h2 className="text-4xl font-bold">Social Media Assets</h2>
          <p className="text-2xl  pt-8 w-full md:w-2/3 text-justify hyphens-auto">
            At METANADO, we're really good at making awesome content for your social media. We make sure your brand's message is always strong, interesting, and stands out from the competition.
          </p>
          <h2 className="text-4xl font-bold">Marketing Materials</h2>
          <p className="text-2xl  pt-8 w-full md:w-2/3 text-justify hyphens-auto">
            We've got all your marketing material needs covered at METANADO. From posters and flyers to signage and business cards, we create professional email signatures, letterheads, and advertisements to give your business the promotion it deserves. Let us help boost your brand's visibility today!
          </p>
        </motion.section>

        {/* componnent */}

        {/* <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="md:pt-32 md:pl-20 pt-10 px-10 flex flex-col md:flex-row justify-start md:justify-center"
        >
          <div className="w-full md:w-1/3 md:mx-4">
            <WebsiteCard
              title="Branding"
              description="Collaborate with experts to craft a memorable brand that nurtures loyalty and boosts recognition."
            />
          </div>
          <div className="w-full md:w-1/3 md:mx-4">
            <WebsiteCard
              title="Web design"
              description="Craft user-focused website design that captivate audience, improve user experience, and drive results.

              "
            />
          </div>
          <div className="w-full md:w-1/3 md:mx-4">
            <WebsiteCard
              title="App design"
              description="Improve your app's performance with our strategic design approach that prioritize usability."
            />
          </div>
        </motion.section> */}

        {/* <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="md:pt-32 md:pl-20 pt-10 px-10 flex flex-col md:flex-row justify-start md:justify-center"
        >
          <div className="w-full md:w-1/3 md:mx-4">
            <WebsiteCard
              title=" Web app development"
              description="Accelerate your digital success with our innovative web app development that delivers results."
            />
          </div>
          <div className="w-full md:w-1/3 md:mx-4">
            <WebsiteCard
              title="Design system "
              description="Transform your workflow by hiring our agency experts to build scalable design systems."
            />
          </div>
          <div className="w-full md:w-1/3 md:mx-4">
            <WebsiteCard
              title="Startup UI/UX design"
              description="Engage your users with our expertise in creating seamless and captivating UI/UX design."
            />
          </div>
        </motion.section> */}

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="mt-8  bg-white text-[#141414] justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10 "
        >
          <h2 className="text-4xl font-bold md:w-[70%] w-full">
            Get Results with METANADO
          </h2>

          <p className="text-2xl  pt-8 w-full md:w-2/3 text-justify hyphens-auto">
            We at METANADO specialize in crafting eye-catching brochures and flyers that make a strong impact. Our talented team produces visually stunning materials that effectively convey your brand’s message and captivate your intended audience. Whether you require a standout brochure or a attention-grabbing flyer, we guarantee that your promotional materials will stand out and connect with your customers.
          </p>
          <ul className="text-2xl pt-8 w-full md:w-4/6 hyphens-auto list-disc ml-5">
            <li>Customized Design </li>
            <li>High-Quality Print</li>
            <li>Versatile Formats</li>
            <li>Print Readiness</li>
          </ul>
        </motion.section>

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="mt-8  bg-white text-[#141414] justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10 "
        >
          <h2 className="text-4xl font-bold md:w-[70%] w-full">
            Our Branding Expertise
          </h2>

          <p className="text-2xl  pt-8 w-full md:w-2/3 text-justify hyphens-auto">
            At METANADO, we wrap up our branding process by providing you with a detailed brand guide. This guide is essential for developing a promotional website and other design components. It features brand illustrations that effectively showcase your new company image, guaranteeing a consistent and powerful presence on all platforms. .
          </p>
          <br />
          <h2 className="text-4xl font-bold md:w-[70%] w-full">
            Brand identity
          </h2>
          <ul className="text-2xl pt-8 w-full md:w-4/6 hyphens-auto list-disc ml-5">
            <li>Visual Positioning</li>
            <li>Visual Identity </li>
            <li>Iconography & Illustration Guidelines</li>
            <li>Brand Application and Implementation</li>
          </ul>
          <br />

          <h2 className="text-4xl font-bold md:w-[70%] w-full">
            Communication strategy
          </h2>
          <ul className="text-2xl pt-8 w-full md:w-4/6 hyphens-auto list-disc ml-5">
            <li>Verbal Identity Development</li>
            <li>Tagline Creation </li>
            <li>Communication Style Guide</li>
          </ul>
          <br />

          <h2 className="text-4xl font-bold md:w-[70%] w-full">
            Brand strategy
          </h2>
          <ul className="text-2xl pt-8 w-full md:w-4/6 hyphens-auto list-disc ml-5">
            <li>Brand Equity Assessment</li>
            <li>Target Audience Analysis</li>
            <li>Competitor Review</li>
            <li>Strategic Planning</li>
          </ul>
        </motion.section>
        {/* component */}

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="mt-8  bg-white text-[#141414]  justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10"
        >
          <h2 className="text-4xl font-bold">Why METANADO?</h2>
          <p className="text-2xl pt-8 w-full md:w-2/3 hyphens-auto">
            METANADO is the top pick for branding design because we have a unique skill set in creating impactful and unforgettable brand identities, especially for innovative companies. We are experts in providing comprehensive branding solutions, from design to strategy, making sure your brand shines in a competitive market
            <br />

          </p>
        </motion.section>

        {/* component */}

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="mt-8  bg-white text-[#141414]  justify-center flex-col  md:justify-start md:pt-32 md:pl-20 pt-10 px-10"
        >
          <h2 className="text-4xl font-bold">Branding Clients</h2>
          <p className="text-2xl  pt-8 w-full md:w-2/3  hyphens-auto">
            We've partnered with a variety of well-known brands and cutting-edge startups backed by top venture capital firms like Sequoia Capital, Benchmark, and Lightspeed Venture Partners.
          </p>
        </motion.section>

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="  w-full  bg-gray-100 my-7 md:w-[85%] md:mx-20 md:my-12   "
        >
          <div className="flex flex-wrap justify-center">
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company1}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company2}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company3}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company4}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company5}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company6}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company7}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company8}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company9}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company10}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img
                src={company11}
                alt="cafex logo "
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img src={company12} alt="cafex logo" />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img src={company13} alt="cafex logo" />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img src={company14} alt="cafex logo" />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img src={company15} alt="cafex logo" />
            </div>
            <div className="w-full  p-2  sm:w-1/2 md:w-1/4">
              <img src={company16} alt="cafex logo" />
            </div>
          </div>
        </motion.section>

        {/* sections */}

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="text-[#141414]  flex justify-center px-8  md:justify-start md:pl-20"
        >
          <div className=" mt-20 text-lg md:text-3xl font-semibold w-full md:w-2/3">
            &ldquo; {testimonial[0].para} &rdquo;
            <div className="flex flex-col md:flex-row px-2 mt-10">
              <img
                className="w-1/12   rounded-full  md:w-[8%] mb-4 md:mb-0 md:mr-4"
                alt="Chris Chen"
                src={testimonial[0].image}
              />
              <div className="px-2 text-lg font-normal">
                <h3>{testimonial[0].name}</h3>
                <span>{testimonial[0].post}</span>
              </div>
            </div>
          </div>
        </motion.section>

        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="text-[#141414]  flex justify-center px-8  md:justify-start md:pl-20"
        >
          <div className="mt-20 text-lg md:text-3xl font-semibold w-full md:w-2/3">
            &ldquo; {testimonial[1].para} &rdquo;
            <div className="flex flex-col md:flex-row px-2 mt-10">
              <img
                className="w-1/12   rounded-full  md:w-[8%] mb-4 md:mb-0 md:mr-4"
                alt="Chris Chen"
                src={testimonial[1].image}
              />
              <div className="px-2 text-lg font-normal">
                <h3>{testimonial[1].name}</h3>
                <span>{testimonial[1].post}</span>
              </div>
            </div>
          </div>
        </motion.section>
        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="text-[#141414]  flex justify-center px-8  md:justify-start md:pl-20"
        >
          <div className="mt-20 text-lg md:text-3xl font-semibold w-full md:w-2/3">
            &ldquo; {testimonial[2].para} &rdquo;
            <div className="flex flex-col md:flex-row px-2 mt-10">
              <img
                className="w-1/12   rounded-full  md:w-[8%] mb-4 md:mb-0 md:mr-4"
                alt="Chris Chen"
                src={testimonial[2].image}
              />
              <div className="px-2 text-lg font-normal">
                <h3>{testimonial[2].name}</h3>
                <span>{testimonial[2].post}</span>
              </div>
            </div>
          </div>
        </motion.section>
        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="text-[#141414]  flex justify-center px-8  md:justify-start md:pl-20"
        >
          <div className="mt-20 text-lg md:text-3xl font-semibold w-full md:w-2/3">
            &ldquo; {testimonial[3].para} &rdquo;
            <div className="flex flex-col md:flex-row px-2 mt-10">
              <img
                className="w-1/12 rounded-full  md:w-[8%]  mb-4 md:mb-0 md:mr-4"
                alt="Chris Chen"
                src={testimonial[3].image}
              />
              <div className="px-2 text-lg font-normal">
                <h3>{testimonial[3].name}</h3>
                <span>{testimonial[3].post}</span>
              </div>
            </div>
          </div>
        </motion.section>

        {/* FAQ */}
        <motion.section
          variants={fadeInAnimationVarinats}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className="flex  bg-white text-[#141414]"
        >
          <Accordion data={designfaqs} />
        </motion.section>
      </div>

      <section>
        <Footer />
      </section>
    </div>
  );
}

export default Designing;
