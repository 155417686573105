
function DesignCard({ imageUrl, title, description }: any) {
  return (
    <div className="flex flex-col px-2 md:px-6 text-white justify-center shadow-lg rounded-lg mb-8 hover:text-[#2f77eb] hover:cursor-pointer">
      <div className="image-container mb-6">
        <img src={imageUrl} className="rounded-lg w-full h-auto" alt={title} />
      </div>
      <h1 className="font-bold text-3xl">{title}</h1>
      <p className="text-[#646479] text-lg">{description}</p>
    </div>
  );
}

export default DesignCard;

