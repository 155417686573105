import React, { useEffect, useState } from "react";
import { logosData } from "../strings/Array";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Project = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleHover = (index: any) => {
    setActiveIndex(index);
  };
  const navigate = useNavigate();

  return (
    <div className=" flex flex-col justify-center items-center">
      <div className="container flex flex-col justify-center items-center">
        <div className="row xl:mx-40 lg:mx-20 md:mx-10 mx-6 flex flex-col justify-center items-center">
          <div className="col-sm-12">
            <div className="hd-txt">
              <h2>Project Your Brand’s Story Through a Distinctive Logo</h2>
              <h4>
                Custom logo designs that take your branding to the next level.
              </h4>
              <p className="" data-wow-delay="350ms">
                An extraordinary logo plan that separates the brand from the
                contenders is the need of each and every business. This is where
                Candigitals steps in. We are capable at planning unmistakable
                logo plans for brands that give them an upper hand and assists
                them with situating their image in the crowd's brain.
              </p>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="uniq-lg-ul hidden md:block">
              <ul className="overflow-x-hidden w-[100%]">
                {logosData.map((logo, index) => (
                  <li
                    key={index}
                    onMouseEnter={() => handleHover(index)}
                    className={`uniq-logo-div-li uniq-logo-div-li-${
                      index + 1
                    } ${activeIndex === index ? "active" : ""}`}
                  >
                    <div className="uniq-lg-details">
                      <h4>{logo.title}</h4>
                      <div className="logo-inner-u-txt">
                        <h3>{logo.title}</h3>
                        <p>{logo.description}</p>
                        <ul>
                          {logo.images.map((image, imgIndex) => (
                            <li key={imgIndex}>
                              <img src={image} alt={`Logo ${imgIndex + 1}`} />
                            </li>
                          ))}
                        </ul>
                        <div className="bnr-btn">
                          <a
                            onClick={() => navigate("/contact-us")}
                            className="get-strtd popup-btn"
                          >
                            Get Started{" "}
                            <i
                              className="fa fa-power-off"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <a
                            onClick={() => navigate("/contact-us")}
                            className="live-cht chats"
                          >
                            Live Chat{" "}
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            >
                              <FaChevronRight />
                            </i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="link-area mt-6">
            <a
              className="hover-effect"
              onClick={() => navigate("our-portfolio")}
            >
              VISIT OUR PORTFOLIO<span className="flash-glow-effect"></span>
            </a>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
