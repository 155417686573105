import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { AiOutlineMenu, AiFillCaretDown } from "react-icons/ai";
import { IoCloseSharp } from "react-icons/io5";
import { FaAngleRight, FaHome } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import logo from "../assets/images/main-logo.png";
import { FaAngleDown } from "react-icons/fa6";
import { CONTACT } from "../strings/CONTACT";

function Header({ backgroundColor }: any) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery({ maxWidth: "1200px" });

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const closeMenuOnOutsideHover = (event: MouseEvent) => {
    // Check if the mouse is not hovering over the mega menu or its children
    if (menuOpen && !event.target) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mouseover", closeMenuOnOutsideHover);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mouseover", closeMenuOnOutsideHover);
    };
  }, [menuOpen]);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const MegaMenu = ({ onMouseEnter, onMouseLeave }: any) => (
    <div
      className={`absolute top-full right-40 left-40 bg-white flex border shadow-md z-[1000] ${
        menuOpen ? "visible" : "hidden"
      }`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="w-20 bg-white absolute h-8 -top-8 left-[485px]"></div>
      <div className="w-8 h-8 bg-logo transform rotate-45 absolute left-[490px] -top-1 translate-x-0 transition-transform group-hover:translate-x-[12rem] duration-500 ease-in-out"></div>
      <div className="container w-auto mx-auto flex flex-col justify-center items-center z-[1000] bg-white">
        <div className="w-full h-[0.15rem] absolute top-0 bg-logo z-20"></div>
        <div className="row flex py-10">
          <div className="col-lg-3 plr-30 w-[25%]">
            <div className="drop-down-heading">
              <h3>Metanado</h3>
              <p>
                Make your brand speak crystal clear and loud with our ROI
                focused design, development and marketing services.
              </p>
            </div>
            <div className="drop-down-ul">
              <ul>
                <li className="flex gap-3">
                  <a href="/pricing">
                    <FaAngleRight size={18} />
                    Check Pricing
                  </a>
                </li>
                <li className="flex gap-3">
                  <a href="/our-portfolio">
                    <FaAngleRight size={18} />
                    View Portfolio
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-[75%] float-left pr-30">
            <div className="flex gap-6">
              <div className="col-lg-4 drop-hover flex-1">
                <div className="drop-down-heading">
                  <h3 style={{ whiteSpace: "nowrap" }}>Logo Design</h3>
                  <h3 style={{ whiteSpace: "nowrap" }}>& Branding</h3>
                </div>
                <div className="drop-img">
                  {" "}
                  <a onClick={() => navigate("/design")}>
                    {" "}
                    <img
                      src={require("../assets/header/Design.jpg")}
                      loading="lazy"
                      alt="Logo And Branding Design"
                    />
                    <div className="drop-img-hover ">
                      <p>Discover</p>
                    </div>
                  </a>{" "}
                </div>
                <h4 className="font-bold">
                  Make a Strong First Impression to Leave a Lasting Impact!
                </h4>
                <p className="drop-simple-p">
                  At METANADO, we're experts in crafting eye-catching visual
                  identities that make a statement...
                </p>{" "}
                <a
                  onClick={() => navigate("/design")}
                  className="drop-view-link"
                >
                  {" "}
                  <i className="fa">
                    <FaAngleRight />
                  </i>{" "}
                  View More{" "}
                </a>
              </div>
              <div className="col-lg-4 drop-hover flex-1">
                <div className="drop-down-heading">
                  <h3>Website</h3>
                  <h3 style={{color:"transparent"}}>{"  jskfsl "}</h3>
                </div>
                <div className="drop-img">
                  {" "}
                  <a onClick={() => navigate("/website")}>
                    {" "}
                    <img
                      src={require("../assets/header/Web Developmement.jpg")}
                      loading="lazy"
                      alt="Website Design"
                    />
                    <div className="drop-img-hover">
                      <p>Discover</p>
                    </div>
                  </a>{" "}
                </div>
                <h4 className="font-bold">
                  Conquer the online fraternity with our web development
                  services
                </h4>
                <p className="drop-simple-p">
                  Our web development services include robust and beautiful
                  carved websites which beat the competition with ease.
                </p>{" "}
                <a
                  onClick={() => navigate("/website")}
                  className="drop-view-link"
                >
                  {" "}
                  <i className="fa">
                    <FaAngleRight />
                  </i>{" "}
                  View More{" "}
                </a>
              </div>
              <div className="col-lg-4 drop-hover flex-1">
                <div className="drop-down-heading">
                  <h3>Digital </h3>
                  <h3>Marketing</h3>
                </div>
                <div className="drop-img">
                  {" "}
                  <a onClick={() => navigate("/digital-marketing")}>
                    {" "}
                    <img
                      src={require("../assets/header/branding.jpg")}
                      loading="lazy"
                      alt="Video Animation"
                    />
                    <div className="drop-img-hover">
                      <p>Discover</p>
                    </div>
                  </a>{" "}
                </div>
                <h4 className="font-bold">Paid Marketing</h4>
                <p className="drop-simple-p">
                  Boost your growth with METANADO's specialized paid marketing
                  plans. We design captivating ad campaigns on various channels
                  to boost traffic and increase conversions quickly.
                </p>{" "}
                <a
                  onClick={() => navigate("/digital-marketing")}
                  className="drop-view-link"
                >
                  {" "}
                  <i className="fa">
                    <FaAngleRight />
                  </i>{" "}
                  View More{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  let hoverTimeout: NodeJS.Timeout | null = null;

  const handleServicesHover = () => {
    clearTimeout(hoverTimeout!);
    setMenuOpen(true);
    console.log("Hovered on services");
  };

  const handleServicesLeave = () => {
    console.log("Left services");
    hoverTimeout = setTimeout(() => {
      setMenuOpen(false);
    }, 100);
  };

  return (
    <div>
      <header
        className={`h-15  shadow-md flex justify-center fixed w-[100%] z-[999] ${
          scrolling
            ? "bg-white text-black font-semibold"
            : "text-black bg-white"
        }`}
      >
        <div className=" xl:container xl:mx-auto  md:mx-32 mx-3 flex justify-between items-center w-auto">
          <Link to="/" className="flex items-center p-6">
            <img
              src={logo}
              alt="Logo"
              className="md:w-[25%] md:h-[15%] w-[32%] h-[22%]"
            />
          </Link>
          {isSmallScreen ? (
            <div
              className={`z-[999] flex items-center pr-4 bg-white text-black ${
                scrolling
                  ? "bg-white text-black font-semibold"
                  : "text-black bg-white"
              }`}
            >
              {menuOpen ? (
                <div
                  onClick={toggleMenu}
                  className={`cursor-pointer ${
                    scrolling
                      ? "bg-white text-black font-semibold"
                      : "text-black bg-white"
                  }`}
                >
                  <IoCloseSharp size={25} />
                </div>
              ) : (
                <div
                  onClick={toggleMenu}
                  className={`cursor-pointer ${
                    scrolling
                      ? "bg-white text-black font-semibold"
                      : "text-black bg-white"
                  }`}
                >
                  <AiOutlineMenu size={25} />
                </div>
              )}
            </div>
          ) : (
            <div className="flex-row text-black">
              <div
                className={`flex items-center text-sm font-semibold space-x-6 ${
                  scrolling
                    ? "bg-white text-black font-semibold"
                    : "text-black bg-white"
                }`}
              >
                <Link to="/">
                  <span className="menu-hover-effect">
                    <FaHome />
                  </span>
                </Link>
                <Link
                  to="/services"
                  onMouseEnter={handleServicesHover}
                  onMouseLeave={handleServicesLeave}
                >
                  {/* <li><a href="/"><span className="menu-hover-effect"><i className="fas fa-home"></i></span></a></li> */}
                  <a className="drop-down text-black">
                    <span
                      className="menu-hover-effect font-bold text-md flex gap-2"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Our Process{" "}
                      <FaAngleDown className="mt-1 hover:text-logo" />
                    </span>
                  </a>
                </Link>
                {menuOpen && (
                  <MegaMenu
                    onMouseEnter={handleServicesHover}
                    onMouseLeave={handleServicesLeave}
                  />
                )}

                <Link to="/our-portfolio">
                  <span className="menu-hover-effect font-bold text-md">
                    PORTFOLIO
                  </span>
                </Link>
                {/* <NavLink to="" label="PRICING" /> */}
                <Link to="/pricing">
                  <span className="menu-hover-effect font-bold text-md">
                    PRICING
                  </span>
                </Link>
                {/* <NavLink to="" label="ABOUT" /> */}
                <Link to="/about-us">
                  <span className="menu-hover-effect font-bold text-md">
                    ABOUT
                  </span>
                </Link>
                {/* <NavLink to="" label="CONTACT" /> */}
                <Link to="/contact-us">
                  <span className="menu-hover-effect font-bold text-md">
                    CONTACT
                  </span>
                </Link>
                <Link to="/contact-us">
                  <div className="flex justify-center items-center gap-2 hover:text-[#5f13f5]">
                    <h1 className="text-[#5f13f5]">
                      <FiPhoneCall />
                    </h1>
                    <span
                      className="menu-hover-effect font-bold text-md"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {CONTACT.PH_NO}
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </header>
      {isSmallScreen && (
        <div
          className={`z-50 w-[100%] flex flex-col justify-start pt-16 items-start text-start overflow-y-auto fixed ${
            scrolling ? "bg-white text-black" : "text-black bg-white"
          }  ${menuOpen ? "visible" : "hidden"} transition-all duration-300`}
        >
          <div className="pl-4 pr-4">
            <div className="flex-col text-primary my-2">
              <NavLink
                to="/"
                label="Home"
                onClick={() => {
                  toggleMenu();
                }}
              />
              <NavLink
                to="/branding"
                label="LOGO & BRANDING"
                onClick={() => {
                  toggleMenu();
                }}
              />
              <NavLink
                to="/website"
                label="WEB DESIGN"
                onClick={() => {
                  toggleMenu();
                }}
              />
              <NavLink
                to="/design"
                label="VIDEO ANIMATION"
                onClick={() => {
                  toggleMenu();
                }}
              />
              <NavLink
                to="/digital-marketing-service"
                label="DIGITAL MARKETING"
                onClick={() => {
                  toggleMenu();
                }}
              />
              <NavLink
                to="/our-portfolio"
                label="PORTFOLIO"
                onClick={() => {
                  toggleMenu();
                }}
              />
              <NavLink
                to="/pricing"
                label="PRICING"
                onClick={() => {
                  toggleMenu();
                }}
              />
              <NavLink
                to="/about-us"
                label="ABOUT"
                onClick={() => {
                  toggleMenu();
                }}
              />
              <NavLink
                to="/contact-us"
                label="CONTACT"
                onClick={() => {
                  toggleMenu();
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const NavLink = ({ to, label, onClick }: any) => (
  <Link
    to={to}
    onClick={onClick}
    className={`block p-2 hover:text-green-700 transition-all duration-300 `}
  >
    <h1>{label}</h1>
  </Link>
);

export default Header;
