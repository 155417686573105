import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ContactForm from "../file/ContactForm";
import { FaCheckCircle } from "react-icons/fa";
import { marketingData } from "../strings/Array";
import Chat from "../file/Chat";
import { useNavigate } from "react-router-dom";
import { CONTACT } from "../strings/CONTACT";

const Marketing = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleHover = (index: any) => {
    setActiveIndex(index);
  };

  const navigate = useNavigate();

  return (
    <div className="w-auto">
      <Header />
      <div className="main-banner logo-bnr-sec web-bnr-sec serv-bnr bg-primary">
        <div className="container mx-auto w-auto">
          <div className="xl:mx-40 lg:mx-20 md:mx-10 px-6 pt-28 md:flex justify-center items-center md:text-start text-center">
            <div className="w-auto">
              <div className="bnr-text">
                <h5>Get Recognized in a Blink of an Eye</h5>
                <h1>
                  <span>Get 50% </span> More Traffic <br />
                  and Double <span className="bnr-spn-clr">
                    the Revenue
                  </span>{" "}
                </h1>
                <h4>
                  Our SEO specialist rank your website on Google and build up
                  more traffic to your website
                </h4>
                <ul>
                  <li>
                    <i className="fa" aria-hidden="true">
                      <FaCheckCircle />
                    </i>{" "}
                    Experienced SEO team
                  </li>
                  <li>
                    <i className="fa" aria-hidden="true">
                      <FaCheckCircle />
                    </i>{" "}
                    Proven track record of website optimization
                  </li>
                  <li>
                    <i className="fa" aria-hidden="true">
                      <FaCheckCircle />
                    </i>{" "}
                    Top SEO strategies for brands in the USA
                  </li>
                  <li>
                    <i className="fa" aria-hidden="true">
                      <FaCheckCircle />
                    </i>{" "}
                    Dedicated Customer Support
                  </li>
                </ul>
                <div className="bnr-btn">
                  <a href="javascript:;.html" className="get-strtd popup-btn">
                    Get Started{" "}
                    <i className="fa fa-power-off" aria-hidden="true"></i>
                  </a>
                  <a href="javascript:;.html" className="live-cht chats">
                    Live Chat{" "}
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </a>
                </div>
                <h6>
                  Implementation process is easy and completly free. Contact us
                  for further info
                </h6>
              </div>
            </div>
            <div className="md:w-[70%] w-auto px-6">
              <div className="bnr-img-st">
                <img
                  className="seo-bnr-1"
                  src={require("../assets/images/images-seo-bnr-1-img.png")}
                  alt="Image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="logo-inner-sec">
        <div className="container w-auto mx-auto xl:px-40 lg:px-20 md:px-10 px-6">
          <div className="md:flex md:gap-32">
            <div className="md:w-[40%] w-auto px-6">
              <div className="logo-inner-img prnt-inner-img">
                <img
                  src={require("../assets/images/images-seo-inner-img-1.png")}
                />
              </div>
            </div>
            <div className="md:w-[60%] col-sm-offset-1 col-sm-6 md:pt-0 pt-10">
              <div className="logo-inner-txt hd-txt">
                <h2>
                  Beat Your Competitors With <br />
                  Our Proven SEO Strategies
                </h2>
                <p>
                  Are you starting a new business? We have a lot of experience
                  with new businesses just like yours, and we’d be pleased to
                  answer any questions you have about building your new website
                  and marketing yourself on the internet through organic
                  marketing strategies.
                  <br />
                  We are providing the best SEO services in the USA to help you
                  reach customers and prospects across the entire web.
                </p>
              </div>
              <div className="bnr-btn serv-pg-btn">
                <a href="javascript:;.html" className="get-strtd popup-btn">
                  Get Started{" "}
                  <i className="fa fa-power-off" aria-hidden="true"></i>
                </a>
                <a href="javascript:;.html" className="live-cht chats">
                  Live Chat{" "}
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </a>

                <a href={`tel:${CONTACT.PH_NO}`} className="tel-btn-st">
                  Call Us: {CONTACT.PH_NO}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto w-auto pricing-sec flex flex-col xl:px-40 lg:px-20 md:px-10 px-6">
        <div className="pri-text">
          <h4>Start Now</h4>
          <h2>Your Free Plan Suggesion</h2>
        </div>
        <div className="w-auto ">
          <div className="grid lg:grid-cols-3 grid-cols-1 price-slider w-[100%] gap-6">
            <div className="">
              <div className="price-main ">
                <div className="price-main-box">
                  <div className="pri-ht-main">
                    <h3>StartUP SEO</h3>
                  </div>
                  <div className="price-rupee-main">
                    <h3>$1099/M</h3>
                  </div>
                </div>
                <div className="pric-list">
                  <ul>
                    <li>
                      <b>Initial Review &amp; Analysis</b>
                    </li>
                    <li>Website Analysis</li>
                    <li>Business Analysis</li>
                    <li>Consumer Analysis</li>
                    <li>Competitor Analysis</li>
                    <li>Google Penalty Check</li>
                    <li>15 Selected Keywords Targeting</li>
                    <li>10 Pages Keyword Targeted</li>
                    <li>
                      <b>Webpage Optimization</b>
                    </li>
                    <li>Meta Tags Creation</li>
                    <li>Keyword Optimization</li>
                    <li>Image Optimization</li>
                    <li>Inclusion of anchors</li>
                    <li>
                      <b>Tracking &amp; Analysis</b>
                    </li>
                    <li>Google Analytics Installation</li>
                    <li>Google Webmaster Installation</li>
                    <li>Call To Action Plan</li>
                    <li>Creation of Sitemaps</li>
                    <li>
                      <b>Reporting</b>
                    </li>
                    <li>Monthly Reporting </li>
                    <li>Email Support </li>
                    <li>Phone Support</li>
                    <li>
                      <b>Off Page Optimization</b>
                    </li>
                    <li>Social Bookmarking</li>
                    <li>Slide Share Marketing </li>
                    <li>Link Building</li>
                    <li>Directory Submission</li>
                    <li>Forums/FAQ’s </li>
                    <li>Link Building </li>
                    <li>Directory Submission </li>
                    <li>Local Business Listings</li>
                  </ul>
                </div>
                <div className="price-btn">
                  <a href="javascript:;.html" className="pri-btn popup-btn">
                    Get Started
                    <img src={require("../assets/images/btn-arrow-1.png")} />
                  </a>
                </div>
              </div>
            </div>
            <div className="">
              <div className="pri-text price-txt-2"></div>
              <div className="price-main">
                <div className="price-main-box">
                  <div className="pri-ht-main">
                    <h3>Elite SEO</h3>
                  </div>
                  <div className="price-rupee-main">
                    <h3>$1799/M</h3>
                  </div>
                </div>
                <div className="pric-list">
                  <ul>
                    <li>
                      <b>Initial Review &amp; Analysis</b>
                    </li>
                    <li>Website Analysis</li>
                    <li>Business Analysis</li>
                    <li>Consumer Analysis</li>
                    <li>Competitor Analysis</li>
                    <li>Google Penalty Check</li>
                    <li>20 Selected Keywords Targeting </li>
                    <li>15 Pages Keyword Targeted</li>
                    <li>
                      <b>Webpage Optimization </b>
                    </li>
                    <li>Meta Tags Creation</li>
                    <li>Keyword Optimization </li>
                    <li>Image Optimization </li>
                    <li>Inclusion of anchors</li>
                    <li>
                      <b>Tracking &amp; Analysis </b>
                    </li>
                    <li>Google Places Inclusions</li>
                    <li>Google Analytics Installation</li>
                    <li>Google Webmaster Installation </li>
                    <li>Creation of Sitemaps</li>
                    <li>
                      <b>Reporting </b>
                    </li>
                    <li>Monthly Reporting </li>
                    <li>Email Support </li>
                    <li>Phone Support</li>
                    <li>
                      <b>Off Page Optimization</b>
                    </li>
                    <li>Social Bookmarking</li>
                    <li>Slide Share Marketing </li>
                    <li>Forums/FAQ’s </li>
                    <li>Link Building </li>
                    <li>Directory Submission </li>
                    <li>Local Business Listings</li>
                  </ul>
                </div>
                <div className="price-btn">
                  <a href="javascript:;.html" className="pri-btn popup-btn">
                    Get Started
                    <img src={require("../assets/images/btn-arrow-1.png")} />
                  </a>
                </div>
              </div>
            </div>
            <div className="">
              <div className="pri-text price-txt-2"></div>
              <div className="price-main">
                <div className="price-main-box">
                  <div className="pri-ht-main">
                    <h3>Professional SEO</h3>
                  </div>
                  <div className="price-rupee-main">
                    <h3>$2999/M</h3>
                  </div>
                </div>
                <div className="pric-list">
                  <ul>
                    <li>
                      <b>Initial Review &amp; Analysis</b>
                    </li>
                    <li>Website Analysis</li>
                    <li>Business Analysis</li>
                    <li>Consumer Analysis</li>
                    <li>Competitor Analysis</li>
                    <li>Google Penalty Check</li>
                    <li>Guaranteed Google Ranking</li>
                    <li>70 Selected Keywords Targeting </li>
                    <li>50 Pages Keyword Targeted</li>
                    <li>
                      <b>Webpage Optimization </b>
                    </li>
                    <li>Meta Tags Creation</li>
                    <li>Keyword Optimization </li>
                    <li>Image Optimization </li>
                    <li>Inclusion of anchors Tags </li>
                    <li>Inclusion of anchors Indexing Modifications</li>
                    <li>
                      <b>Tracking &amp; Analysis </b>
                    </li>
                    <li>Google Places Inclusions</li>
                    <li>Google Analytics Installation</li>
                    <li>Google Webmaster Installation </li>
                    <li>Call To Action Plan </li>
                    <li>Creation of Sitemaps</li>
                    <li>
                      <b>Reporting </b>
                    </li>
                    <li>Monthly Reporting </li>
                    <li>Recommendation </li>
                    <li>Email Support </li>
                    <li>Phone Support</li>
                    <li>
                      <b>Off Page Optimization </b>
                    </li>
                    <li>Social Bookmarking</li>
                    <li>Slide Share Marketing </li>
                    <li>Forums/FAQ’s </li>
                    <li>Link Building </li>
                    <li>Directory Submission </li>
                    <li>Local Business Listings</li>
                    <li>Blog Content Creation</li>
                    <li>Local SEO</li>
                  </ul>
                </div>
                <div className="price-btn">
                  <a href="javascript:;.html" className="pri-btn popup-btn">
                    Get Started
                    <img src={require("../assets/images/btn-arrow-1.png")} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div>
                </div>
            </div> */}
      <div className="container w-auto mx-auto flex flex-col xl:px-40 lg:px-20 md:px-10 px-6">
        <div className="pri-text">
          <h4>Start Now</h4>
          <h2>Your Free Plan Suggesion</h2>
        </div>
        <div className="price-slider-st">
          <div className="grid lg:grid-cols-3 grid-cols-1 w-[100%] price-slider gap-6">
            <div className="">
              <div className="price-main">
                <div className="price-main-box">
                  <div className="pri-ht-main">
                    <h3>Social Media Basic</h3>
                  </div>
                  <div className="price-rupee-main">
                    <h3>$649/M</h3>
                  </div>
                </div>
                <div className="pric-list">
                  <ul>
                    <li>
                      <b>Page Management (2 platforms)</b>
                    </li>
                    <li>10 Social media posts</li>
                    <li>1 Animation</li>
                    <li>Audience Engagement</li>
                    <li>Community Management</li>
                    <li>Call To Action Plan</li>
                    <li>
                      <b>Reporting</b>
                    </li>
                    <li>Monthly Report</li>
                    <li>Email Support </li>
                    <li>Phone Support</li>
                  </ul>
                </div>
                <div className="price-btn">
                  <a href="javascript:;.html" className="pri-btn popup-btn">
                    Get Started
                    <img src={require("../assets/images/btn-arrow-1.png")} />
                  </a>
                </div>
              </div>
            </div>
            <div className="">
              <div className="pri-text price-txt-2"></div>
              <div className="price-main">
                <div className="price-main-box">
                  <div className="pri-ht-main">
                    <h3>Social Media Professional</h3>
                  </div>
                  <div className="price-rupee-main">
                    <h3>$949/M</h3>
                  </div>
                </div>
                <div className="pric-list">
                  <ul>
                    <li>
                      <b>Page Management (3 platforms)</b>
                    </li>
                    <li>12 Social media posts</li>
                    <li>1 Animation</li>
                    <li>Audience Engagement</li>
                    <li>Community Management</li>
                    <li>Reputation Management</li>
                    <li>Targeted Pages Likes</li>
                    <li>Paid Budget ($200)</li>
                    <li>
                      <b>Reporting</b>
                    </li>
                    <li>Monthly Reporting </li>
                    <li>Email Support </li>
                    <li>Phone Support</li>
                  </ul>
                </div>
                <div className="price-btn">
                  <a href="javascript:;.html" className="pri-btn popup-btn">
                    Get Started
                    <img src={require("../assets/images/btn-arrow-1.png")} />
                  </a>
                </div>
              </div>
            </div>
            <div className="">
              <div className="pri-text price-txt-2"></div>
              <div className="price-main">
                <div className="price-main-box">
                  <div className="pri-ht-main">
                    <h3>Social Media Advance</h3>
                  </div>
                  <div className="price-rupee-main">
                    <h3>$1749/M</h3>
                  </div>
                </div>
                <div className="pric-list">
                  <ul>
                    <li>
                      <b>Page Management (4 platforms)</b>
                    </li>
                    <li>15 Social media posts</li>
                    <li>2 Animations</li>
                    <li>Audience Engagement</li>
                    <li>Community Management</li>
                    <li>Reputation Management</li>
                    <li>Targeted Pages Likes</li>
                    <li>Social Bookmarking</li>
                    <li>YouTube Ads</li>
                    <li>Paid Budget ($500)</li>
                    <li>
                      <b>Reporting </b>
                    </li>
                    <li>Monthly Reporting </li>
                    <li>Recommendation </li>
                    <li>Email Support </li>
                    <li>Phone Support</li>
                  </ul>
                </div>
                <div className="price-btn">
                  <a href="javascript:;.html" className="pri-btn popup-btn">
                    Get Started
                    <img src={require("../assets/images/btn-arrow-1.png")} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="unique-logo-sec large-screen-img flex flex-col justify-center items-center">
        <div className="container flex flex-col justify-center items-center">
          <div className="row xl:mx-40 lg:mx-20 md:mx-10 mx-6 flex flex-col justify-center items-center">
            <div className="col-sm-12 ">
              <div className="hd-txt">
                <h2>Get Higher Rankings for Your Site</h2>
                <h4 className="text-xl">
                  We help your business site rank higher on Search Engine
                  results.
                </h4>
                <p className="wow fadeInUp" data-wow-delay="350ms">
                  With a team of experienced SEO executives, we have a proven
                  track record of helping businesses skyrocket their online
                  presence and sales through our top-notch SEO services. Our
                  team has extensive knowledge of the latest Google Algorithms
                  and using this knowledge, we meticulously curate SEO
                  strategies to help brands improve web traffic and sales.{" "}
                </p>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="uniq-lg-ul">
                <ul className="overflow-x-hidden w-[100%]">
                  {marketingData.map((marketing, index) => (
                    <li
                      key={index}
                      onMouseEnter={() => handleHover(index)}
                      className={`uniq-logo-div-li uniq-logo-div-li-${
                        index + 1
                      } ${activeIndex === index ? "active" : ""}`}
                    >
                      <div className="uniq-lg-details">
                        <h4>{marketing.title}</h4>
                        <div className="logo-inner-u-txt">
                          <h3>{marketing.title}</h3>
                          <p>{marketing.description}</p>
                          <ul>
                            {marketing.images.map((image, imgIndex) => (
                              <li key={imgIndex}>
                                <img src={image} alt={`Logo ${imgIndex + 1}`} />
                              </li>
                            ))}
                          </ul>
                          <div className="bnr-btn">
                            <a
                              href="javascript:;.html"
                              className="get-strtd popup-btn"
                            >
                              Get Started{" "}
                              <i
                                className="fa fa-power-off"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <a
                              href="javascript:;.html"
                              className="live-cht chats"
                            >
                              Live Chat{" "}
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="link-area mt-6">
              <a
                className="hover-effect"
                onClick={() => navigate("our-portfolio")}
              >
                VISIT OUR PORTFOLIO<span className="flash-glow-effect"></span>
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <ContactForm />
      </div>
      <Chat />
      <Footer />
    </div>
  );
};

export default Marketing;
