// import { usePathname } from 'next/navigation';
import React, { useEffect, useRef, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import emailjs from "emailjs-com";
import { MdSend } from "react-icons/md";
import {
  RiChatCheckFill,
  RiChatSmile3Fill,
  RiWhatsappFill,
} from "react-icons/ri";

const Chat = () => {
  const [classNameForWhatsappFloating, setClassNameForWhatsappFloating] =
    useState("opacity-0 -right-32");
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  const [isButtonScaled, setButtonScaled] = useState(false);

  const textareaRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const isHomePage = window.location.pathname === "/";
      const isScrolledPastThreshold =
        window.scrollY > window.screen.availHeight;

      if ((isHomePage && isScrolledPastThreshold) || !isHomePage) {
        setClassNameForWhatsappFloating("opacity-1 right-0");
      } else {
        setClassNameForWhatsappFloating("opacity-0 -right-32");
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isFormVisible, setFormVisible] = useState(false);

  const handleClick = () => {
    setFormVisible(!isFormVisible);
    setButtonScaled(true);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event: any) => {
    setMessage(event.target.value);
  };

  const handleTextareaFocus = () => {
    setIsTextareaFocused(true);
  };

  const handleTextareaBlur = () => {
    // Comment out or remove this line to keep the label active even when blurred
    // setIsTextareaFocused(!!message.trim()); // Set to true if there is text in the textarea
  };

  const handleTextareaChange = (event: any) => {
    setMessage(event.target.value);
    // Set focus to textarea when there is text
    if (event.target.value.trim() !== "") {
      setIsTextareaFocused(true);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    // Get form data (you may need to add state variables for these inputs)
    const name = e.target.elements.name.value;
    const email = e.target.elements.email.value;
    const message = e.target.elements.message.value;
    // const clas = e.target.elements.clas.value;
    // const message = e.target.elements.message.value;

    // Use emailjs to send the email
    emailjs
      .send(
        "service_xxc33sv", // Replace with your EmailJS service ID
        "template_doju94d", // Replace with your EmailJS template ID
        {
          from_name: name,
          message: `Name: ${name}, Email: ${email}, Message: ${message}`,
          reply_to: email,
        },
        "qx2NQMbJ5_OOwABuV" // Replace with your EmailJS user ID
      )
      .then(() => {
        // Handle success
        console.log("Email sent successfully!");
        // You can add any other actions you want to perform on success
      })
      .catch((error) => {
        // Handle error
        console.error("Email failed to send. Error:", error);
        // You can add any other actions you want to perform on error
      });
  };

  return (
    <>
      {/* <div className={`fixed top-1/2 -translate-y-1/2 right-0 p-4  rounded-l-full border-secondary border-solid border-l-8 cursor-pointer hover:bg-secondary duration-500 bg-primary text-white font-semibold z-50  flex flex-col items-end gap-5  ${classNameForWhatsappFloating}`}>
                <div className=' font-bolder bg-primary text-white flex flex-col items-center justify-center rounded-lg py-3'><span>Enroll</span><span>Now</span></div>
                Get a Quote
            </div> */}

      <div
        className={`fixed bottom-8 right-8 z-50 cursor-pointer flex flex-col items-end gap-5 duration-500  ${classNameForWhatsappFloating}`}
      >
        {/* <div className=' font-bolder bg-primary text-white flex flex-col items-center justify-center rounded-lg py-3'><span>Enroll</span><span>Now</span></div> */}
        {isFormVisible ? (
          <IoCloseSharp
            size={40}
            color="#fff"
            onClick={handleClick}
            className="transform transition-all duration-300 active:scale-50"
          />
        ) : (
          <RiChatSmile3Fill
            size={40}
            color="#fff"
            onClick={handleClick}
            className="transform transition-all duration-300 active:scale-50"
          />
        )}
        {isFormVisible && (
          <div className="absolute bottom-16 rounded-md transition-all duration-300">
            <div className="rounded-md bg-white text-center md:w-[360px] w-auto h-full">
              <div className="md:p-6 p-3 bg-logo text-white">
                <h1>
                  Please fill out the form below and we will get back to you as
                  soon as possible.
                </h1>
              </div>
              <form
                onSubmit={handleSubmit}
                className="md:p-6 p-3 bg-white rounded-md border-l-8 border-l-logo mx-6 shadow-md"
              >
                <div className="col-md-12 col-xs-12 margin-bottom-20 field-mergedright">
                  <label className="relative">
                    {" "}
                    <input
                      type="text"
                      autoComplete="off"
                      className="h-12 md:text-xl bg-white border-2 rounded-lg border-black border-opacity-50 outline-none focus:border-blue-500 focus:text-black transition px-2 duration-200"
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <span className="text-base text-logo text-opacity-80 absolute left-0 top-0 mx-3 px-2 transition duration-200 input-text">
                      *Name
                    </span>
                  </label>
                </div>
                <div className="col-md-12 col-xs-12 margin-bottom-20 md:py-6 py-1 field-mergedright">
                  <label className="relative">
                    {" "}
                    <input
                      type="text"
                      autoComplete="off"
                      className="h-12 md:text-xl bg-white border-2 rounded-lg border-black border-opacity-50 outline-none focus:border-blue-500 focus:text-black transition px-2 duration-200"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <span className="text-base text-logo text-opacity-80 absolute left-0 top-0 mx-3 px-2 transition duration-200 input-text">
                      *Email
                    </span>
                  </label>
                </div>
                <div className="col-md-12 col-xs-12 margin-bottom-20 md:pb-3 pb-1 field-mergedright">
                  <label className={`relative ${message ? "input-text" : ""}`}>
                    {" "}
                    <textarea
                      rows={3}
                      id="message"
                      onChange={(e) => setMessage(e.target.value)}
                      autoComplete="off"
                      className="md:text-xl bg-white border-2 rounded-lg border-black border-opacity-50 outline-none focus:border-blue-500 focus:text-black transition md:px-2 px-3 duration-200"
                      name="message"
                      required
                    />
                    <span className="text-base text-logo text-opacity-80 absolute left-0 md:-top-16 -top-12 mx-3 px-2 transition duration-200 input-text">
                      *Message
                    </span>
                  </label>
                </div>

                <div className="bg-logo rounded-md text-white font-bold">
                  <button
                    type="submit"
                    className="md:py-3 py-2 flex justify-center items-center mx-auto gap-2"
                  >
                    <MdSend size={25} />
                    Submit
                  </button>
                </div>
              </form>
              <div className="bg-[#f8f8f8] flex justify-center items-center p-3">
                <h1 className="hover:rounded-3xl hover:bg-[#f1f1f1] p-3 text-sm flex justify-center items-center">
                  <img
                    src={require("../assets/images/tawk-sitelogo.png")}
                    alt=""
                    className="w-4 h-4 "
                  />
                  Add free <span className="font-bold px-1"> live chat </span>{" "}
                  to your site
                </h1>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* background of whatsapp should be white */}
      <div
        className={`fixed bottom-5 right-5 z-40 bg-logo h-16 w-16  rounded-full duration-500 ${classNameForWhatsappFloating}`}
      ></div>
    </>
  );
};

export default Chat;
